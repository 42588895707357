import React from 'react';
import { Route, Navigate } from 'react-router-dom';

export const isTokenExpired = () => {
  const token = localStorage.getItem('token');
  if (!token) return true;

  const tokenPayload = JSON.parse(atob(token.split('.')[1]));
  const expiry = tokenPayload.exp * 1000;
  const now = new Date().getTime();
  
  return now > expiry; 
};

const ProtectedRoute = ({ element }) => {
   
    const token = Boolean(localStorage.getItem('token')) && !isTokenExpired();
    if (!token) {
      return <Navigate to="/login" replace />;
    }
  
    return element;
  };

export default ProtectedRoute;
