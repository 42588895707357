import React, { useState, useEffect ,useRef} from 'react';
import { Table, Button, Modal, Form, Input, List, message,Col, Typography, Upload, Select, Checkbox ,Tooltip ,Space,Row,Popconfirm} from 'antd';
import { UploadOutlined, PlusOutlined, MinusCircleOutlined , EyeOutlined, DownloadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { getVariableValuesForPreview, createVariable, downloadValuesAsCsv, deleteVariable, updateVariable, getVariableByUserId ,checkVarNameUnique} from '../../Service/ApiServices';
import { SearchBar } from "../../components";
const { Title } = Typography;
const { Option } = Select;

const VariableManager = () => {
  const [variables, setVariables] = useState([]);
  const [cloneVariables, setCloneVariables] = useState([]);
  const [newVariable, setNewVariable] = useState({ name: '', description: '', isGlobal: false, values: [] });
  const [editVariableId, setEditVariableId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [previewData, setPreviewData] = useState([]);  
  const [loading, setLoading] = useState(false);
  const [entryMethod, setEntryMethod] = useState('manual');
  const [csvColumns, setCsvColumns] = useState([]);
  const [userId, setUserId] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [csvFileList, setCsvFileList] = useState([]);
  const [manualValues, setManualValues] = useState(['']);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [isNameUnique, setIsNameUnique] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const myRef = useRef(null);

  useEffect(() => {
    fetchVariables();
  }, []);

  const fetchVariables = async () => {
    try {
      setLoading(true);
      const user = localStorage.getItem('userDetails');
      let userdata = JSON.parse(user);
      let role = userdata.roles[0].name;
      if(role == 'Admin')
        setIsAdmin(true)
      setUserId(userdata.id);
      const data = await getVariableByUserId(userdata.id);
      setVariables(data);
      setCloneVariables(data);
    } catch (error) {
      message.error('Error fetching variables');
    } finally {
      setLoading(false);
    }
  };
 const handleSearch=(data) =>{
    setVariables(data)
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewVariable({ ...newVariable, [name]: value });
  };

  const handleGlobalCheckboxChange = (e) => {
    setNewVariable({ ...newVariable, isGlobal: e.target.checked });
  };

  // Function to Preview Values
  const handlePreviewValues = async (id) => {
    const data = await getVariableValuesForPreview(id);
    const previewData = data.map((value, index) => ({
        key: index,
        value,
      }));
    setPreviewData(previewData);
    setIsPreviewVisible(true);
  };

  const handleFormSubmit = async () => {
    if (!newVariable.name.trim()) {
      message.error('Name is required');
      return;
    }
    if (!newVariable.description.trim()) {
      message.error('Description is required');
      return;
    }
    if (newVariable.isGlobal === false) {
        newVariable.userId = userId;
    }

    let values = [];
    if (entryMethod === 'manual') {
      values = manualValues.filter(Boolean); // Filter out empty entries
    } else if (entryMethod === 'file' && selectedColumn !== null) {
      const columnIndex = csvColumns.indexOf(selectedColumn); // Get the index of the selected column
      values = csvData.map(row => row[columnIndex]).filter(Boolean); // Extract and filter valid entries
    }

    const payload = { ...newVariable, values };

    try {
      if (editVariableId) {
        await updateVariable(editVariableId, payload);
        message.success('Variable updated successfully');
      } else {
        await createVariable(payload);
        message.success('Variable added successfully');
      }
      fetchVariables();
      resetForm();
      setIsModalVisible(false);
    } catch (error) {
      message.error('Error saving variable');
    }
  };

  const handleDeleteVariable = async (id) => {
    try {
      await deleteVariable(id);
      message.success('Variable deleted successfully');
      fetchVariables();
    } catch (error) {
      message.error('Error deleting variable');
    }
  };
  const handleDownload = async (id,name) => {
    try {
        setLoading(true);
      await downloadValuesAsCsv(id,name);
      setLoading(false);
    } catch (error) {
        setLoading(false);
      console.error('Failed to download CSV:', error);
    }
  };
  const handleEditClick = (variable) => {
    console.log(variable)
    setNewVariable({
      name: variable.name,
      description: variable.description,
      isGlobal: variable.isGlobal,
      values: variable.values
    });
    setEditVariableId(variable.id);
    setIsModalVisible(true);
  };

  const handleOpenModal = () => {
    resetForm();
    setIsModalVisible(true);
  };

  const resetForm = () => {
     setNewVariable({ name: '', description: '', isGlobal: false, values: [] });
    setEditVariableId(null);
    setManualValues(['']);
    setCsvColumns([]);
    setCsvData([]);
    setCsvFileList([]);
    setSelectedColumn(null);
    setEntryMethod('manual'); 
  };

  const handleEntryMethodChange = (value) => {
    setEntryMethod(value);
    setManualValues(['']);
    setCsvColumns([]);
    setCsvData([]);
    setCsvFileList([]);
    setSelectedColumn(null);
  };

  const handleUpload = (file) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const fileContent = e.target.result;

      Papa.parse(fileContent, {
        complete: (result) => {
          const rows = result.data;
          const columns = rows[0]; // Assuming first row is headers
          setCsvColumns(columns);
          setCsvData(rows.slice(1)); // Exclude the header row
        },
        header: false,
        skipEmptyLines: true,
      });
    };

    reader.onerror = function () {
      message.error('Failed to read file.');
    };

    reader.readAsText(file);
  };

  const beforeUpload = (file) => {
    handleUpload(file);
    setCsvFileList([file]); // Limit to one file
    return false; // Prevent automatic upload
  };

  const handleRemoveFile = () => {
    setCsvFileList([]);
    setCsvColumns([]);
    setCsvData([]);
    setSelectedColumn(null); // Reset selected column on file removal
  };

  // Manual value handlers
  const addManualValue = () => {
    if (manualValues.length >= 100) {
      message.error('Maximum of 100 values allowed.');
      return;
    }
    setManualValues([...manualValues, '']);
  };

  const removeManualValue = (index) => {
    const updatedValues = [...manualValues];
    updatedValues.splice(index, 1);
    setManualValues(updatedValues);
  };

  const handleManualValueChange = (index, value) => {
    const updatedValues = [...manualValues];
    updatedValues[index] = value;
    setManualValues(updatedValues);
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id',defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Is Global',
      dataIndex: 'global',
      key: 'global',
      render: (global) => (global ? 'Yes' : 'No'),
    },
    {
        title: 'Actions',
        key: 'actions',
        render: (_, record) => (
          <Space size="middle">
            <Tooltip title="Preview Data">
              <Button type="link" icon={<EyeOutlined />} onClick={() => handlePreviewValues(record.id)} />
            </Tooltip>
            <Tooltip title="Download">
              <Button type="link" icon={<DownloadOutlined />} onClick={() => handleDownload(record.id, record.name)} />
            </Tooltip>
            <Tooltip title="Edit">
              <Button type="link" icon={<EditOutlined />} onClick={() => handleEditClick(record)} />
            </Tooltip>
            <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure you want to delete this variable? Any running queries using this variable will be disrupted."
              onConfirm={() => { handleDeleteVariable(record.id) }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" danger icon={<DeleteOutlined />} />

            </Popconfirm>
              {/* <Button type="link" danger icon={<DeleteOutlined />} onClick={() => handleDeleteVariable(record.id)} /> */}
            </Tooltip>
          </Space>
        ),
      },
  ];

  // Column for Preview Modal
  const previewColumns = [
    {
      dataIndex: 'value',
      key: 'value',
    },
  ];

  // Data for Preview Modal
//   const previewData = newVariable.values.slice(0, 100).map((value, index) => ({
//     key: index,
//     value,
//   }));
const handleNameBlur = async (event) => {
  const name = event.target.value;
  if (!name) {
    setErrorMessage('Please enter a Name!');
    setIsNameUnique(true); 
    return;
  }

    const isUnique   = await checkVarNameUnique(name);
    if (isUnique) {
        setErrorMessage('Variable name already exists!');
        setIsNameUnique(false); 
      } else {
        setErrorMessage(''); 
        setIsNameUnique(true); 
      }
  
};
  return (
    <div>
      <div className="repo-header">
        <Title level={2}>Variable Manager</Title>
      </div>
   
      <Row>
      <Col  span={8} >
      <Button type="primary" onClick={handleOpenModal} >
          Add New Variable
      </Button>
     
      </Col>
      <Col  span={8} offset={8}>
      <div style={{ float:'right' }}>
      <SearchBar data={cloneVariables}  handler={handleSearch} ref={myRef} ></SearchBar>
      </div>
      </Col>

      </Row>
      <Table
        columns={columns}
        dataSource={variables}
        rowKey="id"
        loading={loading}
        pagination={{ pageSize: 10 }}
        style={{ marginTop: '10px' }} 
      />

      {/* Modal for Add/Edit Variable */}
      <Modal
        title={editVariableId ? 'Edit Variable' : 'Add New Variable'}
        visible={isModalVisible}
        cancelButtonProps={{ type: 'primary' }} 
        onCancel={() => {setIsModalVisible(false);setIsNameUnique(true);setErrorMessage('')}}
        onOk={handleFormSubmit}
      >
        <Form layout="vertical">
          <Form.Item label="Variable Name"  validateStatus={errorMessage ? 'error' : ''}
                                  help={errorMessage} required>
            <Input
               name="name" 
              onBlur={handleNameBlur}
              value={newVariable.name}
              onChange={(e) => setNewVariable({ ...newVariable, name: e.target.value })}
              placeholder="Enter variable name"
            />
          </Form.Item>
          <Form.Item label="Description" required>
            <Input
              name="description"
              value={newVariable.description}
              onChange={(e) => setNewVariable({ ...newVariable, description: e.target.value })}
              placeholder="Enter description"
            />
          </Form.Item>
          {!editVariableId && (
            <>
          <Form.Item label="Global Variable" 
          style={{ display: isAdmin ? '' : 'none' }}
          required>
            <Checkbox checked={newVariable.isGlobal} onChange={handleGlobalCheckboxChange}>
              Is this a global variable?
            </Checkbox>
          </Form.Item>

          {/* Value Entry Methods - Only for Adding a New Variable */}
         
              <Form.Item label="Entry Method">
                <Select value={entryMethod} onChange={handleEntryMethodChange}>
                  <Option value="manual">Manual Entry</Option>
                  <Option value="file">CSV File</Option>
                </Select>
              </Form.Item>

              {entryMethod === 'manual' && (
                <Form.Item label="Values">
                  <List
                    dataSource={manualValues}
                    renderItem={(item, index) => (
                      <List.Item>
                        <Input
                          value={item}
                          onChange={(e) => handleManualValueChange(index, e.target.value)}
                          placeholder={`Enter value ${index + 1}`}
                          style={{ width: '90%' }}
                        />
                        {manualValues.length > 1 && (
                          <Button
                            type="link"
                            icon={<MinusCircleOutlined />}
                            onClick={() => removeManualValue(index)}
                            danger
                          />
                        )}
                      </List.Item>
                    )}
                  />
                  {manualValues.length < 100 && (
                    <Button type="dashed" onClick={addManualValue} style={{ marginTop: 10 }}>
                      <PlusOutlined /> Add Value
                    </Button>
                  )}
                </Form.Item>
              )}

              {entryMethod === 'file' && (
                <>
                  <Form.Item label="CSV File">
                    <Upload
                      beforeUpload={beforeUpload}
                      fileList={csvFileList}
                      onRemove={handleRemoveFile}
                      accept=".csv"
                    >
                      <Button icon={<UploadOutlined />}>Select CSV File</Button>
                    </Upload>
                  </Form.Item>

                  {csvColumns.length > 0 && (
                    <Form.Item label="Select Column">
                      <Select
                        value={selectedColumn}
                        onChange={setSelectedColumn}
                        placeholder="Select column to extract values"
                      >
                        {csvColumns.map((column, index) => (
                          <Option key={index} value={column}>
                            {column}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </>
              )}
            </>
          )}

          {/* Preview Values Button for Edit Mode */}
          {/* {editVariableId && (
            <Button type="primary" onClick={handlePreviewValues} style={{ marginTop: 10 }}>
              Preview Values
            </Button>
          )} */}
        </Form>
      </Modal>

      {/* Preview Modal */}
      <Modal
        title="Values"
        visible={isPreviewVisible}
        onCancel={() => {
            setIsPreviewVisible(false);
            setPreviewData([]);
          }}
        footer={null}
      >
        <Table
        
          columns={previewColumns}
          dataSource={previewData}
          pagination={{ pageSize: 5, showSizeChanger: false }}
        />
      </Modal>
    </div>
  );
};

export default VariableManager;
