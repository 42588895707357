import React, { useState, useEffect, useRef } from 'react';
import { Steps, Button, Form, Input, Select, message, Table,Radio, Typography, Spin, Space, Tooltip, Row, Col } from 'antd';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { getRepositoriesByUserId, queryRawData, getProcessedData, getVariableByUserId, updateCurrentStep, getQueryProgressById, checkTitleUnique, queryFHIRServer } from '../../Service/ApiServices';
import './Stepper.css';
import { QuestionCircleOutlined,CheckCircleOutlined ,CheckCircleTwoTone} from '@ant-design/icons';
import Cron from 'react-cron-generator'
import './cronBuilder.css'
import ColumnMapping from './ColumnMapping';

const tableNameRegex = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const { Step } = Steps;
const { Option } = Select;
const { Title } = Typography;

const appendCurrentDateTimeToTitle = (title) => {
    const now = new Date();
    const formattedDateTime = now.toISOString().replace(/[-:.TZ]/g, '').slice(0, 14);
    const newUsername = `${title}_${formattedDateTime}`;
    return newUsername;
}
const Stepper = () => {
    const [current, setCurrent] = useState(0);
    const [repositories, setRepositories] = useState([]);
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({});
    const [userId, setUserId] = useState('');
    const [queryProgressId, setQueryProgressId] = useState('');
    const [columns, setColumns] = useState([]);
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const [tableName, setTableName] = useState('');
    const [loading, setLoading] = useState(false);
    const [isTitleUnique, setIsTitleUnique] = useState(true);
    const [variables, setVariables] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [currentProjectId, setCurrentProjectId] = useState('')
    const [queryType, setQueryType] = useState('oneTimeQuery')
    const [isLiveQueryType, setIsLiveQueryType] = useState(false)
    const [selectedDataType, setSelectedDataType] = useState(formData.dataType || '');
    const navigate = useNavigate();
    const { id } = useParams();
    const formRef = useRef();

    useEffect(() => {
        if (id) {
            fetchProgressById(id);
        } else {
            fetchRepositories();
        }
    }, [id]);

    const fetchRepositories = async () => {
        setLoading(true);
        try {
            const user = localStorage.getItem('userDetails');
            let userdata = JSON.parse(user);
            const selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
            setCurrentProjectId(selectedProject.id)
            const varData = await getVariableByUserId(userdata.id);
            setVariables(varData);
            const data = await getRepositoriesByUserId(userdata.id);
            setRepositories(data);
            setUserId(userdata.id);
        } catch (error) {
            message.error('Failed to fetch repositories');
        } finally {
            setLoading(false);
        }
    };

    const fetchProgressById = async (id) => {
        setLoading(true);
        try {
            const progress = await getQueryProgressById(id);
            setCurrent(progress.currentStep || 0);
            setQueryProgressId(Number(id));
            setTableName(progress.tableName || '');
            setFormData({
                repository: progress.repositoryId,
                query: progress.query,
                dataType: progress.dataType
            });
            setColumns(progress.columnMapping ? JSON.parse(progress.columnMapping) : []);

            if (progress.currentStep === 1 || progress.currentStep === 2) {
                if (progress.dataType == 'FHIR') {
                    const result = await queryFHIRServer({
                        repository: progress.repositoryId,
                        query: progress.query,
                        dataType: progress.dataType,
                        userId: progress.userId,
                        projectId: progress.projectId,
                        queryProgressId: Number(id)
                    });

                    if (result.records.entry) {
                        setData(result.records.entry);
                        setColumns(generateColumns(result.records.entry));
                    } else {
                        message.error('No records found');
                    }
                }
                else {
                    const result = await queryRawData({
                        repository: progress.repositoryId,
                        query: progress.query,
                        dataType: progress.dataType,
                        userId: progress.userId,
                        projectId: progress.projectId,
                        queryProgressId: Number(id)
                    });

                    if (result.records) {
                        setData(result.records);
                        setColumns(generateColumns(result.records));
                    } else {
                        message.error('No records found');
                    }
                }

            }
        } catch (error) {
            message.error('Failed to fetch progress data');
        } finally {
            setLoading(false);
        }
    };

    const generateColumns = (data) => {
        if (data.length === 0) return [];
        return Object.keys(data[0]).map((key) => ({
            title: key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()),
            dataIndex: key,
            key,
            render: (text) => {
                if (typeof text === 'object' && text !== null) {
                    const jsonString = JSON.stringify(text);
                    return (
                        <div style={{

                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>
                            {jsonString}
                        </div>
                    );
                }
                return text;
            },
            ellipsis: true
        }));
    };

    const next = () => {
        setCurrent(prevCurrent => prevCurrent + 1);
    };

    const handleProceedClick = () => {
        setLoading(true);
        updateCurrentStep(queryProgressId, 2)
            .finally(() => {
                setLoading(false);
                next();
            });
    };

    const handleDoneClick = () => {
        setLoading(true);
        updateCurrentStep(queryProgressId, 4)
            .finally(() => {
                setLoading(false);
                navigate('/query');
            });
    };
    const extractVariablesFromQuery = (query) => {
        const regex = /<([^>]+)>/g;
        const variables = [];
        let match;
        while ((match = regex.exec(query)) !== null) {
            variables.push(match[1]);
        }
        return variables;
    };
    const validateQuery = (query) => {
        const extractedVariables = extractVariablesFromQuery(query);
        const missingVariables = [];
        let updatedQuery = query;

        extractedVariables.forEach(varName => {
            const variable = variables.find(v => v.name === varName);

            if (!variable) {
                missingVariables.push(varName);
            }
        });

        if (missingVariables.length > 0) {
            message.error(`Invalid variables : ${missingVariables.join(', ')}`);
            return null;
        } else {
            return updatedQuery;

        }
    };
    const handleFormSubmit = async (values) => {
        setLoading(true);
        let tabName = appendCurrentDateTimeToTitle(values.title)
        setTableName(tabName);
        const selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
        let jobData = null;
        try {
           
            if (values.dataType == 'FHIR') {
                if(queryType == "liveQuery")
                {
                jobData = values;
                jobData.projectName = selectedProject.projectName;
                jobData.tableName = tabName;
                }
                jobData = JSON.stringify(jobData);
                const dataToSubmit = { ...values, userId, projectId: currentProjectId,queryType,jobData  };
                const result = await queryFHIRServer(dataToSubmit);
                if (result.records.entry) {
                    setData(result.records.entry);
                    setColumns(generateColumns(result.records.entry));
                    setFormData(values);
                    setQueryProgressId(result.progressId);
                    message.success('Data fetched successfully');
                    next();
                } else {
                    message.error('No records found');
                }
            }
            else {
                let validatedQuery = validateQuery(values.query)
                if (!validatedQuery) {
                    return;
                }
                if(queryType == "liveQuery")
                {
                jobData = values;
                jobData.projectName = selectedProject.projectName;
                jobData.tableName = tabName;
                }
                jobData = JSON.stringify(jobData);
                const dataToSubmit = { ...values, userId, projectId: currentProjectId,queryType,jobData };
                const result = await queryRawData(dataToSubmit);
                if (result.records) {
                    setData(result.records);
                    setColumns(generateColumns(result.records));
                    setFormData(values);
                    setQueryProgressId(result.progressId);
                    message.success('Data fetched successfully');
                    next();
                } else {
                    message.error('No records found');
                }
            }
            setLoading(false);

        } catch (error) {
            message.error('Error fetching data: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleMappingSubmit = async (key) => {
        setLoading(true);
        try {
            const data = await getProcessedData(key);
            if (data) {
                setData(data);
                setColumns(generateColumns(data));
                message.success('Data processed successfully');
                next();
            } else {
                message.error('No processed data found');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleQueryTypeChange=(e)=> {
        let val = e.target.value;
        let type = "oneTimeQuery";
        let booleanJob = isLiveQueryType;
        if (val == 1) {
          booleanJob = false;
          type = "oneTimeQuery";
          setIsLiveQueryType(booleanJob)
          setQueryType(type)
        } else {
          booleanJob = true;
          type = "liveQuery";
          setIsLiveQueryType(booleanJob)
          setQueryType(type)
        }
    
      }
 
    const handleDataTypeChange = (value) => {
        setSelectedDataType(value);
    };
    const handleCancelClick = () => {
        setShouldNavigate(true);
    };
    const handleTitleBlur = async (event) => {
        const title = event.target.value;
        if (!title) {
            setErrorMessage('Please enter a Title!');
            setIsTitleUnique(true);
            return;
        }
        if (!tableNameRegex.test(title)) {
            setErrorMessage('Title can only contain letters, digits, or underscores, and must start with a letter or underscore');
            setIsTitleUnique(true);
            return;
        }

        const isUnique = await checkTitleUnique(title);
        if (isUnique) {
            setErrorMessage('This title already exists!');
            setIsTitleUnique(false);
        } else {
            setErrorMessage('');
            setIsTitleUnique(true);
        }

    };
    if (shouldNavigate) {
        return <Navigate to="/query" />;
    }


    return (
        <div style={{ padding: '24px' }}>
            <div className="stepper-header">
                <Title level={2}>Add Data</Title>
                <Button type="primary" onClick={handleCancelClick} style={{ marginLeft: 'auto' }}>
                    Back
                </Button>
            </div>
            <Steps current={current}>
                <Step 
                    title="Select Repository and Query" 
                    icon={current > 0 ? <CheckCircleTwoTone  style={{ fontSize: '28px' }} twoToneColor="#66b443" /> : null}
                />
                <Step 
                    title="Preview Data" 
                    icon={current > 1 ? <CheckCircleTwoTone  style={{ fontSize: '28px' }} twoToneColor="#66b443" /> : null}
                />
                <Step 
                    title="Column Mapping" 
                    icon={current > 2 ? <CheckCircleTwoTone  style={{ fontSize: '28px' }} twoToneColor="#66b443" /> : null}
                />
                <Step 
                    title="Processed Data Preview" 
                    icon={current === 3 ? <CheckCircleTwoTone style={{ fontSize: '28px' }} twoToneColor="#66b443" /> : null} 
                />
            </Steps>
            <div style={{ marginTop: '24px' }}>
                <Spin size="large" spinning={loading}>
                    {current === 0 && (
                        <div className="step-content">
                            {/* <div className="form-container"> */}
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Form style={{ padding: '0', maxWidth: '800px', width: '100%', marginRight:'15%', marginTop:'30px' }} {...layout} ref={formRef} onFinish={handleFormSubmit} initialValues={formData}>
                                        <Form.Item
                                            name="title"
                                            label="Title"
                                            validateStatus={errorMessage ? 'error' : ''}
                                            help={errorMessage}
                                            rules={[{ required: true, message: 'Please enter a Title!' }]}
                                        >
                                            <div style={{ position: 'relative', width: '100%' }}>
                                                <Input
                                                    style={{ width: '100%', paddingRight: '40px' }}
                                                    placeholder="Enter your title here"
                                                    onBlur={handleTitleBlur}
                                                    maxLength={40}
                                                    minLength={10}
                                                />
                                                <Tooltip title={
                                                    <div>
                                                        <p>Title can only contain letters, digits, and underscores</p>
                                                        <p>Must start with a letter or underscore</p>
                                                        <p>Length must be 10-40 characters</p>
                                                    </div>
                                                }>
                                                    <Typography.Link href="#" style={{ position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)' }}>
                                                        <QuestionCircleOutlined />
                                                    </Typography.Link>
                                                </Tooltip>
                                            </div>
                                        </Form.Item>

                                        <Form.Item
                                            name="repository"
                                            label="Repository"
                                            rules={[{ required: true, message: 'Please select a repository!' }]}
                                        >
                                            <Select placeholder="Select a repository">
                                                {repositories.map(repo => (
                                                    <Option key={repo.id} value={repo.id}>
                                                        {repo.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            name="dataType"
                                            label="Data Type"
                                            rules={[{ required: true, message: 'Please select a Data Type!' }]}
                                        >
                                            <Select placeholder="Select a Data Type" onChange={handleDataTypeChange}>
                                                <Option value="HL7_V2">HL7_V2</Option>
                                                <Option value="CCDA">CCDA</Option>
                                                <Option value="FHIR">FHIR</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="queryType" label="Query Type" >
                                        <Radio.Group
                                            onChange={handleQueryTypeChange}
                                            defaultValue={1}
                                        >
                                            <Radio value={1}>One-Time Query</Radio>
                                            <Radio value={2}>Live Updates</Radio>
                                        </Radio.Group>
                                        </Form.Item>
                                        <Form.Item
                                            name="query"
                                            label="Query"
                                            rules={[{ required: true, message: 'Please enter a query!' }]}
                                        >
                                            <Input.TextArea rows={4}
                                                placeholder="Enter your query, e.g., SELECT * FROM table WHERE id IN <variable> (or) /baseR4/fhir/Encounter"
                                            />
                                        </Form.Item>
                                        
                                        <Form.Item
                                            name="cronExpression"
                                            label="Cron Expression"
                                            style={{ display: isLiveQueryType ? " " : "none" }}
                                        >
                                            <Cron
                                                showResultText={true}
                                                showResultCron={true}
                                            />
                                        </Form.Item>
                                        {isLiveQueryType && selectedDataType !== 'FHIR' && (
                                            <Form.Item
                                                name="field"
                                                label="Incremental Field"
                                                rules={[{ required: true, message: 'Please input a field!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        )}
                                        {isLiveQueryType && selectedDataType == 'FHIR' && (
                                            <Form.Item
                                                name="retQuery"
                                                label="Retention Query"
                                                rules={[{ required: true, message: 'Please input query!' }]}
                                            >
                                                <Input.TextArea rows={2} placeholder="Enter your FHIR query, e.g., /baseR4/fhir/Encounter?_lastUpdated=><lastUpdated>"/>
                                            </Form.Item>
                                        )}
                                        <Form.Item {...tailLayout}>
                                            <Button type="primary" htmlType="submit">
                                                Next
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>

                            </div>
                        // </div>
                    )}
                    {current === 1 && (
                        <div>
                            <Table dataSource={data} columns={columns} rowKey={(record) => record.id || Math.random()} />
                            <Button type="primary" onClick={handleProceedClick}>
                                Proceed to Mapping
                            </Button>
                        </div>
                    )}
                    {current === 2 && data.length !== 0 && (
                        <div>
                            <ColumnMapping data={data} formData={formData} queryProgressId={queryProgressId} tableName={tableName} onMappingSubmit={handleMappingSubmit} />
                        </div>
                    )}
                    {current === 3 && (
                        <div>
                            <Table dataSource={data} columns={columns} rowKey={(record) => record.id || Math.random()} />
                            <Button type="primary" onClick={handleDoneClick}>
                                Done
                            </Button>
                        </div>
                    )}
                </Spin>
            </div>
        </div>
    );
};

export default Stepper;
