import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Checkbox, Button, Row, Col, Table, Typography, Modal, Alert, Select, Tag, Input, message, Steps, Form, Card, Spin, Space, Tooltip } from 'antd';
import { getCompletedQueryProgressByUserId,getCompletedDatCleansingProgressByUserId,getColumnNames,getRecordsByGroupId,getGroupByProject, getVariableByUserId, getReportProgressById,combineData, getAllReports, getProcessedData, saveReportProgress, checkReportTitleUnique, updateReportCurrentStep } from '../../Service/ApiServices';
import PowerBIReport from './PowerBIReport';
import { SearchBar } from "../../components";
import { QuestionCircleOutlined ,CheckCircleTwoTone} from '@ant-design/icons';
const { Title, Paragraph } = Typography;
const { TextArea } = Input;
const { Step } = Steps;
const { Option } = Select;
const tableNameRegex = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
const appendCurrentDateTimeToTitle = (title) => {
  const now = new Date();
  const formattedDateTime = now.toISOString().replace(/[-:.TZ]/g, '').slice(0, 14);
  const newUsername = `${title}_${formattedDateTime}`;
  return newUsername;
}

class TableMapping extends Component {

  myRef = React.createRef();
  constructor(props) {
    super(props)

  this.state = {
    currentStep: 0,
    tableData: [],
    cloneTableData: [],
    allData:[],
    selectedTables: [],
    selectedTableKeys: [],
    tableColumns: {},
    selectedColumns: {},
    commonColumns: [],
    reportsData: [],
    keyColumn: '',
    isModalVisible: false,
    newTableName: '',
    jsonInput: '',
    generatedSQL: '',
    additionalWhere: '',
    showReport: false,
    reportId: '',
    userId: '',
    spin: true,
    finalTableName: '',
    isModalVisible: false,
    previewData: [],
    title: '',
    reportProgressId: '',
    isTitleUnique: true,
    isTitleValid: true,
    shouldNavigate: false,
    errorMessage: '',
    generatedSQLs: [],
    uniqueColumns: [],
    variables: [],
    groups:[],
    currentProject:{},
    selectedGroup:null
  };
}
  async componentDidMount() {
    try {
    
      const { id } = this.props;
      const user = localStorage.getItem('userDetails');
      let userdata = JSON.parse(user);
      const varData = await getVariableByUserId(userdata.id);
      const selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
      const queryProgress = await getCompletedQueryProgressByUserId(userdata.id,selectedProject.id);
      const dataCleansingProgress = await getCompletedDatCleansingProgressByUserId(userdata.id,selectedProject.id);
      const renamedData = dataCleansingProgress.map(item => ({
        ...item, 
        tableName: item.targetTableName,
        source: "clean"
    }));
    const sourceAddedData = queryProgress.map(item => ({
      ...item, 
      source: "query"
  }));
      const data = [...sourceAddedData, ...renamedData];
      const queryGroupsData = await getGroupByProject("query_Progress",selectedProject.id);
      const cleanGroupsData = await getGroupByProject("data_cleansing_Progress",selectedProject.id);
      const groupsData = [...queryGroupsData, ...cleanGroupsData];
      this.setState({ userId: userdata.id, tableData: data, cloneTableData: data,allData:data,groups:groupsData, variables: varData,currentProject:selectedProject, spin: false });
      if(id)
        {
          this.fetchProgressById(id);
        }
     
    } catch (error) {
      console.log(error)
      message.error('Failed to fetch table data');
    }
  }
   handleGroupChange = async (value) => {
    const { allData} = this.state;
    this.setState({ spin: true });
    if(value)
      {
        const records =await getRecordsByGroupId(value);
        const recordIds = records.map(record => record.recordId);
        const filteredData = allData.filter(record => recordIds.includes(record.id));
     
        this.setState({ spin: false,tableData: filteredData, cloneTableData: filteredData,selectedGroup:value });
      }
    else
    {
        this.setState({ spin: false,tableData: allData, cloneTableData: allData,selectedGroup:null  });
    }
          
  };
  fetchProgressById = async (id) => {
        this.setState({ spin: true });
        try {
            const progress = await getReportProgressById(id);
            let title = progress.title;
            if(progress.currentStep == 1)
              {
                let tables = progress.selectedTables;
               
                const arr = tables.split(',')
                this.setState({selectedTables:arr,newTableName:appendCurrentDateTimeToTitle(title),currentStep: progress.currentStep || 0,title:title,reportProgressId: parseInt(id, 10),spin:false})
                this.generateColumnList(arr);
              }
            else if(progress.currentStep == 2)
              {
                const response = await getAllReports();
                const reports = response.value;
                this.setState({ currentStep: progress.currentStep || 0,title:title,reportProgressId: parseInt(id, 10), reportsData: reports,spin:false });
              }
      
        } catch (error) {
            message.error('Failed to fetch report progress');
        } finally {
            this.setState({ spin: false });
        }
    };

    handleTableSelection = (e, name) => {
      const { checked } = e.target;
      const [tableName, id, source] = name.split('-');
    
      this.setState((prevState) => {
      
        const selectedTables = checked
          ? [...prevState.selectedTables, tableName]
          : prevState.selectedTables.filter((table) => table !== tableName);
    
        const selectedTableKeys = checked
          ? [...prevState.selectedTableKeys, name]
          : prevState.selectedTableKeys.filter((key) => key !== name);
    
        return { selectedTables, selectedTableKeys, keyColumn: '', commonColumns: [], generatedSQL: '' };
      });
    };
  handleSearch = (data) => {
    this.setState({ tableData: data });
  }
  handleCombineData = async () => {
    const { selectedTables, tableData,currentProject, title, userId } = this.state;
    if (selectedTables.length === 0) {
      message.warning('Please select at least one table');
      this.setState({ spin: false })
      return;
    }

    if (!title.trim()) {
      this.setState({ isTitleValid: false, errorMessage: 'Please enter a Title!', spin: false })
      message.error('Please enter a Title!');
    }
    else {
      // const tableNames = selectedTables.map(key => key.split('-')[0]);
      let data = {
        title: title,
        query: 'to be updated',
        currentStep: 1,
        userId: userId,
        projectId:currentProject.id,
        selectedTables:selectedTables.toString()
      }
      let id = await saveReportProgress(data);
      this.setState({ reportProgressId: id, newTableName: appendCurrentDateTimeToTitle(title) })
      this.generateColumnList(selectedTables)
   
    }
  };

  generateColumnList = async (selectedTables) =>{
    const {tableData} = this.state;
    try {
      const tableColumns = {};
      const columnFetchPromises = selectedTables.map(async (table) => {
        const tableItem = tableData.find((item) => item.tableName === table);
        if (tableItem && tableItem.columnMapping) {
          const columns = JSON.parse(tableItem.columnMapping);
          tableColumns[table] = columns;
        } else if (tableItem) {
          const columns = await getColumnNames(table);
          tableColumns[table] = columns;
        }
      });
      await Promise.all(columnFetchPromises);
      const commonColumns = this.getCommonColumns(tableColumns);
      console.log(commonColumns)
      if (selectedTables.length > 1 && commonColumns.length === 0) {
        message.error('No common columns found between selected tables');
        this.setState({ tableColumns, commonColumns: [], spin: false });
      } else {
        this.setState({ tableColumns, commonColumns, currentStep: this.state.currentStep + 1, spin: false });
      }
      console.log(tableColumns)
      
    } catch (error) {
      message.error('Error fetching table columns');
    }
  }
  getCommonColumns = (tableColumns) => {
    const tables = Object.keys(tableColumns);
    if (tables.length === 0) return [];

    const firstTableColumns = tableColumns[tables[0]].map((col) => col.name);
    return tables.slice(1).reduce((commonCols, tableName) => {
      const tableCols = tableColumns[tableName].map((col) => col.name);
      return commonCols.filter((col) => tableCols.includes(col));
    }, firstTableColumns);
  };

  handleColumnSelection = (e, tableName, columnName) => {
    const { checked } = e.target;
    this.setState((prevState) => {
      const selectedColumns = { ...prevState.selectedColumns };
      if (!selectedColumns[tableName]) {
        selectedColumns[tableName] = [];
      }
      if (checked) {
        selectedColumns[tableName] = [...selectedColumns[tableName], columnName];
      } else {
        selectedColumns[tableName] = selectedColumns[tableName].filter((col) => col !== columnName);
      }

      return { selectedColumns };
    }, this.generateSQL);
  };

  handleKeyColumnSelection = (e, columnName) => {
    this.setState({ keyColumn: columnName }, this.generateSQL);
  };
  generateSQL = () => {
    const { selectedTables, selectedColumns, additionalWhere } = this.state;

    const generatedSQLs = selectedTables.map((table) => {
      const columnsToSelect = selectedColumns[table] || [];
      if (columnsToSelect.length === 0) {
        return '';
      }

      let sql = `SELECT ${columnsToSelect.join(', ')} FROM ${table}`;

      if (additionalWhere[table]) {
        sql += ` ${additionalWhere[table]}`;
      }

      return sql;
    });

    this.setState({ generatedSQLs }, this.updateUniqueColumns);
  };
  updateUniqueColumns = () => {
    const { selectedColumns } = this.state;
    const uniqueColumns = [];

    Object.keys(selectedColumns).forEach((table) => {
      selectedColumns[table].forEach((column) => {
        if (!uniqueColumns.includes(column)) {
          uniqueColumns.push(column);
        }
      });
    });

    this.setState({ uniqueColumns });
  };
  extractVariablesFromQuery = (query) => {
    const regex = /<([^>]+)>/g;
    const variables = [];
    let match;
    while ((match = regex.exec(query)) !== null) {
      variables.push(match[1]);
    }
    return variables;
  };
  validateQuery = (query) => {
    const { variables } = this.state;
    const extractedVariables = this.extractVariablesFromQuery(query);
    const missingVariables = [];
    let updatedQuery = query;

    extractedVariables.forEach(varName => {
      const variable = variables.find(v => v.name === varName);

      if (!variable) {
        missingVariables.push(varName);
      }
    });

    if (missingVariables.length > 0) {
      message.error(`Invalid variables : ${missingVariables.join(', ')}`);
      return null;
    } else {
      return updatedQuery;

    }
  };
  handleAdditionalWhereChange = (tableName, e) => {
    const { value } = e.target;
    this.setState({
      additionalWhere: {
        ...this.state.additionalWhere,
        [tableName]: value,
      },
    }, this.generateSQL);

  };

  handleMappingSubmit = async () => {
    const { newTableName, selectedTables, selectedColumns, keyColumn, generatedSQLs, userId, reportProgressId } = this.state;
    const uniqueColumns = new Set();
    let validatedQuery = this.validateQuery(generatedSQLs)
    if (!validatedQuery) {
      return;
    }
    const mappings = Object.keys(selectedColumns).flatMap((table) =>
      selectedColumns[table]
        .filter((column) => {
          if (uniqueColumns.has(column)) {
            return false;
          } else {
            uniqueColumns.add(column);
            return true;
          }
        })
        .map((column) => ({
          name: column,
          type: 'TEXT',
          source: column,
        }))
    );
    const config = {
      tableName: newTableName,
      columns: mappings,
      query: generatedSQLs,
      userId: userId,
      reportProgressId: reportProgressId
    };

    try {
      console.log(config)
      const result = await combineData(config);
      message.success('Mapping submitted successfully');
      this.setState({ currentStep: this.state.currentStep + 1, finalTableName: newTableName, spin: false })
    } catch (error) {
      message.error('Error submitting mapping');
    }
  };

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleOk = () => {
    const { jsonInput } = this.state;
    try {
      const parsedMappings = JSON.parse(jsonInput);
      const tableMappings = {};
      parsedMappings.forEach((mapping) => {
        const { source, name, type } = mapping;
        tableMappings[source] = { name, type };
      });

      this.setState({ tableMappings, isModalVisible: false });
    } catch (err) {
      message.error('Invalid JSON input');
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleJsonInputChange = (e) => {
    this.setState({ jsonInput: e.target.value });
  };

  handleNewTableNameChange = (e) => {
    this.setState({ newTableName: e.target.value });
  };

  handlePreviewData = async (key) => {
    try {
      const [tableName, id, source] = key.split('-');
      const data = await getProcessedData(tableName);
      this.setState({ previewData: data, isModalVisible: true })
    } catch (error) {
      console.error('Error fetching preview data:', error);
    }
  };
  nextStep = async () => {
    const { currentStep } = this.state;
    this.setState({ spin: true });
    if (currentStep == 0) {
      this.handleCombineData();
    }
    else if (currentStep == 1) {
      this.handleMappingSubmit();
      const response = await getAllReports();
      const reports = response.value;
      this.setState({  reportsData: reports });
    }
    

  };

  prevStep = () => {
    const { currentStep } = this.state;
    this.setState({ currentStep: currentStep - 1 });
  };
  handleDone = () => {
    const { currentStep, reportProgressId } = this.state;

    updateReportCurrentStep(reportProgressId, 3)
      .finally(() => {
        this.setState({ currentStep: currentStep - 1, shouldNavigate: true });

      });
  };
  onReportChange = (val) => {

    this.setState({ reportId: val, showReport: true });
  };
  onTitleChange = (event) => {
    this.setState({ title: event.target.value });
  };
  onTitleBlur = async () => {
    const { title } = this.state;
    try {
      if (!title.trim()) {
        this.setState({ isTitleValid: false, errorMessage: 'Please enter a Title!' })
        message.error('Please enter a Title!');
        return;
      }
      else if (!tableNameRegex.test(title)) {
        this.setState({ isTitleUnique: true, isTitleValid: false, errorMessage: 'Title can only contain letters, digits, or underscores, and must start with a letter or underscore' })
        return;
      }
      else {
        this.setState({ isTitleValid: true })
        const res = await checkReportTitleUnique(title);
        if (res) {
          this.setState({
            isTitleUnique: false,
            errorMessage: 'Title already exists!'
          });
        } else {
          this.setState({
            isTitleUnique: true,
            errorMessage: ''
          });
        }
      }

    } catch (error) {
      console.error('Error checking title:', error);
      this.setState({
        isTitleUnique: false,
        errorMessage: 'Error checking title. Please try again later.'
      });
    }
  };

  render() {
    const {
      currentStep,
      tableData,
      cloneTableData,
      selectedTables,
      tableColumns,
      selectedColumns,
      commonColumns,
      keyColumn,
      isModalVisible,
      jsonInput,
      newTableName,
      generatedSQL,
      additionalWhere,
      reportsData,
      showReport,
      reportId,
      spin, isTitleUnique, errorMessage,
      finalTableName,
      previewData,
      isTitleValid,
      shouldNavigate,
      reportProgressId,
      title,
      uniqueColumns,
      groups,
      selectedTableKeys
    } = this.state;

    const steps = [
      {
        title: 'Select Tables',
        content: (
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Space>
              <Select
                placeholder="Select a Group"
                style={{ width: 200}}
                onChange={this.handleGroupChange}
                allowClear
              >
                {/* <Option value={null}>All</Option> */}
                {groups.map(group => (
                  <Option key={group.id} value={group.id}>
                    {group.name}
                  </Option>
                ))}
              </Select>
                <Input
                  placeholder='Input Title'
                  style={{ width: '300px', marginRight: '16px' }}
                  value={title}
                  onChange={this.onTitleChange}
                  onBlur={this.onTitleBlur}
                  maxLength={40} minLength={10}
                />
                <Tooltip title={<div><p>Title can only contain letters, digits, underscores</p> <p>Must start with a letter or underscore </p><p> Lenght 10-40</p></div>}><Typography.Link href="#"><QuestionCircleOutlined /></Typography.Link></Tooltip></Space>
              {(!isTitleValid || !isTitleUnique) && (
                <Typography.Text style={{ marginLeft: '10px' }} type="danger">
                  {errorMessage}
                </Typography.Text>
              )}
            </div>
            <Row>
            <Col span={8} offset={16}>
                <div style={{ marginTop: '10px' ,marginBottom:'10px', float:'right'}}>
                <SearchBar
                  data={cloneTableData}
                  handler={this.handleSearch}
                  ref={this.myRef}
                ></SearchBar>
              </div>
            </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table
                  rowKey={(record) => `${record.tableName}-${record.id}-${record.source}`} 
                  dataSource={tableData}
                  columns={[
                    // {
                    //   title: 'ID',
                    //   dataIndex: 'id',
                    //   key: 'id', defaultSortOrder: 'descend',
                    //   sorter: (a, b) => a.id - b.id
                    // },
                    {
                      title: 'Table Name',
                      dataIndex: 'tableName',
                      key: 'tableName',
                    },
                    {
                      title: 'Data Type',
                      dataIndex: 'dataType',
                      key: 'dataType',
                    },
                    {
                      title: 'Select',
                      key: 'select',
                      render: (text, record) => {
                        const uniqueKey = `${record.tableName}-${record.id}-${record.source}`;
                        return (
                          <Checkbox
                            checked={selectedTableKeys.includes(uniqueKey)}
                            onChange={(e) => this.handleTableSelection(e, uniqueKey)}
                          />
                        );
                      },
                    },
                    {
                      title: 'Actions',
                      key: 'actions',
                      render: (text, record) => {
                        const uniqueKey = `${record.tableName}-${record.id}-${record.source}`;
                        return (
                          <Button onClick={() => this.handlePreviewData(uniqueKey)}>
                            Preview Data
                          </Button>
                        );
                      },
                    },
                  ]}
                />
              </Col>
            </Row>

          </div>
        ),
      },
      {
        title: 'Select Columns',
        content: (
          <div style={{ padding: '20px' }}>
            {Object.keys(tableColumns).length > 0 ? (
              <>
                {Object.entries(tableColumns).map(([tableName, columns]) => (
                  <Card
                    key={tableName}
                    title={<Title level={4}>{tableName}</Title>}
                    style={{ marginBottom: '20px' }}
                    bordered
                  >
                    {columns.map((col) => (
                      <Checkbox
                        key={col.name}
                        checked={(selectedColumns[tableName] || []).includes(col.name)}
                        onChange={(e) => this.handleColumnSelection(e, tableName, col.name)}

                      >
                        {col.name}
                      </Checkbox>
                    ))}
                    <TextArea
                      placeholder={`Enter WHERE clause for ${tableName}`}
                      value={additionalWhere[tableName] || ''}
                      onChange={(e) => this.handleAdditionalWhereChange(tableName, e)}
                      rows={2}
                      style={{ marginTop: '15px' }}
                    />
                  </Card>
                ))}
                <Card
                  title={<Title level={4}>Created Table : {newTableName}</Title>}
                  style={{ marginBottom: '20px' }}
                  bordered
                  readOnly
                >
                  {uniqueColumns.length > 0 ? (
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                      {uniqueColumns.map((col, index) => (
                        <Tag key={index} color="geekblue" style={{ fontSize: '14px', padding: '5px 10px', borderRadius: '4px' }}>
                          {col}
                        </Tag>
                      ))}
                    </div>
                  ) : (
                    <Alert
                      message="No Columns"
                      description="No columns selected from the tables."
                      type="info"
                      showIcon
                    />
                  )}
                </Card>
              </>
            ) : (
              <Alert
                message="No Columns Available"
                description="Please combine data first to view available columns."
                type="info"
                showIcon
                style={{ marginTop: '10px' }}
              />
            )}
          </div>
        ),
      },
      {
        title: 'Choose Template',
        content: (
          <div>
            <Select
              placeholder="Select a report"
              style={{ width: 300 }}
              onChange={this.onReportChange}
            >
              {reportsData.map(report => (
                <Option key={report.id} value={report.id}>
                  {report.name}
                </Option>
              ))}
            </Select>
            {showReport && <PowerBIReport finalTableName={finalTableName} reportId={reportId} progressId={reportProgressId} />}
          </div>
        ),
      },
    ];
    if (shouldNavigate) {
      return <Navigate to="/report" />;
    }
    return (
      <div>
        <Spin size="large" spinning={spin}>
          <Title level={3}>Report</Title>
          <Steps current={currentStep} style={{ marginBottom: '40px' }}>
            {steps.map((step, index) => (
              <Step key={index} title={step.title}  icon={currentStep > index ? <CheckCircleTwoTone  style={{ fontSize: '28px' }} twoToneColor="#66b443" /> : null}/>
            ))}
          </Steps>
          <div className="steps-content">{steps[currentStep].content}</div>
          <div className="steps-action">
            {currentStep < steps.length - 1 && (
              <Button type="primary" onClick={this.nextStep}>
                Next
              </Button>
            )}
            {/* {currentStep > 0 && (
                    <Button style={{ margin: '0 8px' }} onClick={this.prevStep}>
                        Previous
                    </Button>
                )} */}
            {(currentStep === steps.length - 1 && showReport) && (
              <Button type="primary" style={{ margin: '0 8px' }} onClick={this.handleDone}>
                Done
              </Button>
            )}
          </div>
          <Modal
            title="Data Preview"
            visible={isModalVisible}
            onCancel={() => this.handleCancel()}
            footer={[
              <Button key="close" type="primary" onClick={this.handleCancel}>
                Close
              </Button>,
            ]}
            width={1000}
          >
            <Table
              dataSource={previewData}
              columns={Object.keys(previewData[0] || {}).map(key => ({
                title: key,
                dataIndex: key,
                key: key,
              }))}
              rowKey="id"
              pagination={false}
              className="modal-table"

            />
          </Modal>
        </Spin>
      </div>

    );
  }
}

export default TableMapping;

