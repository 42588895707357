import React, { Component } from 'react';
import { Form, Select, Input, Button, DatePicker,Col, Row,message } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { insertFilteredData } from '../../Service/ApiServices';

const { Option } = Select;

class QueryBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      tableName:'',
      selectedConditions: [{ column: '', comparator: '=', value: '' }]
    };
  }
  componentDidMount() {
    const { data,tableName } = this.props;
    if (data.length > 0) {
      const jsonData = data[0];
      const columns = Object.keys(jsonData); 
      this.setState({columns ,tableName});
    }
  }
  handleColumnChange = (index, value) => {
    const { selectedConditions } = this.state;
    selectedConditions[index].column = value;
    this.setState({ selectedConditions });
  }

  handleComparatorChange = (index, value) => {
    const { selectedConditions } = this.state;
    selectedConditions[index].comparator = value;
    this.setState({ selectedConditions });
  }

  handleValueChange = (index, event) => {
    const { selectedConditions } = this.state;
    selectedConditions[index].value = event.target.value;
    this.setState({ selectedConditions });
  }

  handleAddCondition = () => {
    this.setState(prevState => ({
      selectedConditions: [...prevState.selectedConditions, { column: '', comparator: '=', value: '' }]
    }));
  }

  handleRemoveCondition = (index) => {
    this.setState(prevState => {
      const selectedConditions = [...prevState.selectedConditions];
      selectedConditions.splice(index, 1);
      return { selectedConditions };
    });
  }

  formQuery = () => {
    const { selectedConditions ,tableName} = this.state;
    if(selectedConditions.length > 0)
        {
            const whereClauses = selectedConditions.map(condition => {
                return `${condition.column} ${condition.comparator} '${condition.value}'`;
              }).join(' AND ');
              return `SELECT * FROM  ${tableName} WHERE ${whereClauses}`;
        }
    else
    {
        return `SELECT * FROM  ${tableName}`;
    }
   
  }

  handleSubmit = async () => {
    const { tableName } = this.state;
    const query = this.formQuery();
    let filterTable = tableName+"_filtered";
    try {
      await insertFilteredData(query,filterTable);

      message.success('Data Filtering successfully');
      this.props.onFilterSubmit(tableName);
    } catch (error) {
      message.error('Error submitting filter: ' + error.message);
      console.error('Error submitting filter:', error);
    }
  };
  render() {
    const { columns, selectedConditions } = this.state;
    return (
        <>
        <h1>Query Editor</h1>
      <Form onFinish={this.handleSubmit}>
        {/* <Form.Item label="Select Columns">
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Select columns"
            value={selectedConditions.map(condition => condition.column)}
            onChange={this.handleColumnChange}
          >
            {columns.map(column => (
              <Option key={column} value={column}>{column}</Option>
            ))}
          </Select>
        </Form.Item> */}
        {selectedConditions.map((condition, index) => (
          <Row key={index} gutter={16}>
            <Col span={6}>
              <Form.Item label="Field">
                <Select
                  value={condition.column}
                  onChange={(value) => this.handleColumnChange(index, value)}
                >
                  {columns.map(column => (
                    <Option key={column} value={column}>{column}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Comparator">
                <Select
                  value={condition.comparator}
                  onChange={(value) => this.handleComparatorChange(index, value)}
                >
                  <Option value="=">{'='}</Option>
                  <Option value=">">{'>'}</Option>
                  <Option value="<">{'<'}</Option>
                  <Option value=">=">{'>='}</Option>
                  <Option value="<=">{'<='}</Option>
                  <Option value="!=">{'!='}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Value">
                <Input
                  value={condition.value}
                  onChange={(event) => this.handleValueChange(index, event)}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Button
                type="dashed"
                onClick={() => this.handleRemoveCondition(index)}
                icon={<MinusCircleOutlined />}
              >
                Remove
              </Button>
            </Col>
          </Row>
        ))}
        <Form.Item>
          <Button
            type="dashed"
            onClick={this.handleAddCondition}
            icon={<PlusOutlined />}
          >
            Add Condition
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Fetch Data
          </Button>
        </Form.Item>
      </Form>
      </>
    );
  }
}

export default QueryBuilder;
