import React, { Component } from 'react';
import { Select, Form, Input, Button, Table, message } from 'antd';
import { queryRawData, getProcessedData } from './Service/ApiServices';
import ColumnMapping from './ColumnMapping';
import PowerBIReport from './PowerBIReport';
import QueryBuilder from './QueryBuilder';

const { Option } = Select;

class DataLoader extends Component {
  state = {
    databases: ['bfjm', 'student'],
    selectedDatabase: null,
    loading: false,
    data: [],
    showMappingForm: false,
    formData: {},
    showData:false,
    drillColumns: [],
    isRawData: false,
    showReport:false,
    showEditor:false,
    tableName:`bmi_test_data`
  };

  generateColumns = (data) => {
    if (data.length === 0) return [];
    return Object.keys(data[0]).map((key) => ({
      title: key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()),
      dataIndex: key,
      key,
      ellipsis:true
    }));
  };

  handleDatabaseChange = (value) => {
    this.setState({ selectedDatabase: value });
  };

  handleProceedClick = () => {
    this.setState({ showData:false, showMappingForm: true });
  };
  handleEditorClick = () => {
    this.setState({showData:false, showEditor: true });
  };
  handleReportClick = () => {
    this.setState({showEditor:false, showReport: true });
  };

  handleFormSubmit = async (values) => {
    this.setState({ loading: true });
    try {
      const result = await queryRawData(values);
      const drillColumns = this.generateColumns(result);
      this.setState({ data: result, formData: values, drillColumns: drillColumns, isRawData: true,showData:true });
      message.success('Data fetched successfully');
    } catch (error) {
      message.error('Error fetching data: ' + error.message);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleMappingSubmit = async (key) => {
    try {
      const data = await getProcessedData(key);
      const drillColumns = this.generateColumns(data);
      this.setState({ data: data, showMappingForm: false, drillColumns: drillColumns, isRawData: false,showData:true });
      message.success('Data processed successfully');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  handleFilterSubmit = async (key) => {
    this.setState({tableName:key+"_filtered",showEditor:false, showReport: true });
  };

  render() {
    const { databases, selectedDatabase, data, loading, showMappingForm, formData,showEditor, drillColumns, isRawData ,showReport,tableName,showData} = this.state;
    const tableNameNew ='report_table'
    return (
      <div>
     {/* <PowerBIReport username={tableNameNew}/> */}
        <Form layout="vertical" onFinish={this.handleFormSubmit}>
          <Form.Item label="Select Repository" name="database" rules={[{ required: true, message: 'Please select a database' }]}>
            <Select placeholder="Select a database" onChange={this.handleDatabaseChange}>
              {databases.map((db) => (
                <Option key={db} value={db}>
                  {db}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {selectedDatabase && (
            <Form.Item label="Query" name="query" rules={[{ required: true, message: 'Please enter query' }]}>
              <Input placeholder="Enter query" />
            </Form.Item>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
        {data.length > 0 && showData && (
          <>
            <Table dataSource={data} columns={drillColumns} />
            {isRawData ? (
              <Button type="primary" onClick={this.handleProceedClick}>
                Proceed to Mapping
              </Button>
            ):
            (
              <Button type="primary" onClick={this.handleEditorClick}>
                Filter Data
              </Button>
            )}
            
          </>
        )}
        {showMappingForm && <ColumnMapping data={data} formData={formData} tableName={tableName} onMappingSubmit={this.handleMappingSubmit} />}
        {showEditor &&<QueryBuilder data={data} tableName={tableName} onFilterSubmit={this.handleFilterSubmit}/>}
        {showReport && <PowerBIReport username={tableName}/>}  
      </div>
    );
  }
}

export default DataLoader;
