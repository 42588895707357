import { Input } from 'antd';
import React from 'react';
import {
    SearchOutlined
  } from '@ant-design/icons';
import './Search.css';
import * as _ from 'lodash';

const Search = (props) => {

    const {ref, data, handler, } = props;

   const handleChange = (event) => {
     let totalData;
     totalData = _.clone(data);
     
     
     let searchString = event.target.value.trim().toLowerCase();

     if (searchString.length > 0) {

        totalData = totalData.filter(function search(row) {
             return Object.keys(row).some((key) => {
                 if (typeof row[key] === "string") {
                     return row[key].toLowerCase().indexOf(searchString) > -1;
                 } else if (row[key] && typeof row[key] === "object") {
                     return search(row[key]);
                 }
                 else if(typeof row[key] === "number"){
                    return row[key].toString().indexOf(searchString) > -1
                }

                 return false;
             });
         });


    }
    handler(totalData);
   
}
//  reset(event){
//     let searchString=event.target.value;
//     searchString="";

// }
    

    return (
        <Input className="searchBox" placeholder="Search" id="search-box" suffix={<SearchOutlined style={{
           
          }}/>} 
        onChange={handleChange}  allowClear />
    );
}

export default Search;