import axios from 'axios';
import authHeader from './auth-header';

let SERVICE_URL= process.env.REACT_APP_API_URL;
const dataapi = axios.create({
  baseURL: SERVICE_URL+'/api'
});

export const transferData = async (config) => {
  try {
    const response = await dataapi.post('/loadData/transfer', config,{
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const combineData = async (config) => {
  try {
    const response = await dataapi.post('/loadData/combineData', config, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const queryRawData = async (config) => {
  try {
    const response = await dataapi.post('/loadData/queryRawData', config, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const queryFHIRServer = async (config) => {
  try {
    const response = await dataapi.post('/loadData/queryFHIRServer', config, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const transferFHIRData = async (config) => {
  try {
    const response = await dataapi.post('/loadData/transferFHIRData', config, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getProcessedData = async (tableName) => {
  try {
    const response = await dataapi.get(`/loadData/getProcessedData?tableName=${tableName}`, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const renameColumns = async (data) => {
  try {
  const response = await dataapi.post(`/loadData/rename-column`,data, {
    headers: authHeader()
  });
  return response.data;
} catch (error) {
  throw error;
}
};
export const insertFilteredData = async (query, tableName) => {
  try {
    const response = await dataapi.post(`/general/loadData/insertFilteredData?query=${query}&tableName=${tableName}`, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateCurrentStep = async (id,stepNo) => {
  try {
    const response = await dataapi.post(`/query-progress/${id}/current-step?currentStep=${stepNo}`, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateReportCurrentStep = async (id,stepNo) => {
  try {
    const response = await dataapi.post(`/report-progress/${id}/current-step?currentStep=${stepNo}`, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getAllReports = async () => {
  try {
    const response = await dataapi.post(`/powerBi/getAllReports`, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEmbedToken = async (tableName, reportId) => {
  try {
    const response = await dataapi.post(`/powerBi/getembedinfo?tableName=${tableName}&reportId=${reportId}`, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const createDataset = async (tableName,reportId,progressId) => {
  try {
    const response = await dataapi.post(`/powerBi/createDataset?tableName=${tableName}&reportId=${reportId}&progressId=${progressId}`, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateReportParameter = (parameterName, parameterValue) => {
  return dataapi.post('/powerBi/updateParameter', {
    parameterName: parameterName,
    parameterValue: parameterValue
  }, {
    headers: authHeader()
  });
};

export const getAllRepositories = async () => {
  const response = await dataapi.get('/repositories', {
    headers: authHeader()
  });
  return response.data;
};
export const getRepositoriesByUserId = async (id) => {
  const response = await dataapi.get(`/repositories/getRepositoryByUserId/${id}`, {
    headers: authHeader()
  });
  return response.data;
};
export const addRepository = async (repository) => {
  const response = await dataapi.post('/repositories/addRepository', repository, {
    headers: authHeader()
  });
  return response.data;
};

export const updateRepository = async (id, repository) => {
  const response = await dataapi.put(`/repositories/${id}`, repository, {
    headers: authHeader()
  });
  return response.data;
};

export const deleteRepository = async (id) => {
  const response = await dataapi.delete(`/repositories/${id}`, {
    headers: authHeader()
  });
  return response.data;
};
export const checkRepoNameUnique = async (title) => {
  try {
    const response = await dataapi.get(`/repositories/check-if-exists?name=${title}`, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error checking name uniqueness:', error);
    return false;
  }
};

export const getReportsByUserId = async (id) => {
  const response = await axios.get(`/api/reports/list/${id}`, {
    headers: authHeader()
  });
  return response.data;
};
export const getQueryProgressByUserId = async (userId,projectId) => {
  const response = await dataapi.get(`/query-progress/by-user-and-project?userId=${userId}&projectId=${projectId}`, {
    headers: authHeader()
  });
  return response.data;
};
export const getCompletedQueryProgressByUserId = async (userId,projectId) => {
  const response = await dataapi.get(`/query-progress/completed/by-user-and-project?userId=${userId}&projectId=${projectId}`, {
    headers: authHeader()
  });
  return response.data;
};
export const getQueryProgressById = async (id) => {
  const response = await dataapi.get(`/query-progress/${id}`, {
    headers: authHeader()
  });
  return response.data;
};

export const getReportProgressByUserId = async (userId,projectId) => {
  const response = await dataapi.get(`/report-progress/by-user-and-project?userId=${userId}&projectId=${projectId}`, {
    headers: authHeader()
  });
  return response.data;
};
export const getReportProgressById = async (id) => {
  const response = await dataapi.get(`/report-progress/${id}`, {
    headers: authHeader()
  });
  return response.data;
};
export const checkTitleUnique = async (title) => {
  try {
    const response = await dataapi.get(`/query-progress/check-if-exists?title=${title}`, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error checking title uniqueness:', error);
    return false;
  }
};
export const checkReportTitleUnique = async (title) => {
  try {
    const response = await dataapi.get(`/report-progress/check-if-exists?title=${title}`, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error checking title uniqueness:', error);
    return false;
  }
};
export const saveReportProgress = async (data) => {
  const response = await dataapi.post(`/report-progress`,data, {
    headers: authHeader()
  });
  return response.data;
};
export const downloadCsv = async (tableName) => {
  try {
    const response = await dataapi({
      url: `/loadData/download-csv`,
      method: 'GET',
      params: { tableName },
      responseType: 'blob', 
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${tableName}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error('Error downloading CSV:', error);
    throw error;
  }
};
export const downloadValuesAsCsv = async (variableId,name) => {
  try {
    const response = await dataapi({
      url: `/variables/download-csv`,
      method: 'GET',
      params: { variableId },
      responseType: 'blob', 
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}-values.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error('Error downloading CSV:', error);
    throw error;
  }
};
export const exportReportAsPdf = async (reportId) => {
  try {
    const response = await dataapi({
      url: `/powerBi/export-report-pdf?reportId=${reportId}`,
      method: 'GET',
      responseType: 'blob', 
    });

    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'report.pdf');
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error('Error exporting report as PDF:', error);
  }
};

export const getAllVariables = async () => {
  try {
    const response = await dataapi.get('/variables', {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching all variables:', error);
    throw error;
  }
};

export const getVariableById = async (id) => {
  try {
    const response = await dataapi.get(`/variables/${id}`, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching variable with ID ${id}:`, error);
    throw error;
  }
};
export const getVariableValuesForPreview = async (id) => {
  try {
    const response = await dataapi.get(`/variables/${id}/preview`, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching values with ID ${id}:`, error);
    throw error;
  }
};
export const getVariableByUserId = async (userId) => {
  try {
    const response = await dataapi.get(`/variables/byUser/${userId}`, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching variable with ID ${userId}:`, error);
    throw error;
  }
};
export const checkVarNameUnique = async (title) => {
  try {
    const response = await dataapi.get(`/variables/check-if-exists?name=${title}`, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error checking name uniqueness:', error);
    return false;
  }
};
export const createVariable = async (variable) => {
  try {
    const response = await dataapi.post('/variables', variable, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error creating variable:', error);
    throw error;
  }
};

export const updateVariable = async (id, variable) => {
  try {
    const response = await dataapi.put(`/variables/${id}`, variable, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating variable with ID ${id}:`, error);
    throw error;
  }
};

export const deleteVariable = async (id) => {
  try {
    const response = await dataapi.delete(`/variables/${id}`, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    console.error(`Error deleting variable with ID ${id}:`, error);
    throw error;
  }
};

export const getDataCleansingProgressByUserId = async (userId,projectId) => {
  const response = await dataapi.get(`/data-cleansing-progress/by-user-and-project?userId=${userId}&projectId=${projectId}`, {
    headers: authHeader()
  });
  return response.data;
};

export const getDataCleansingProgressById = async (id) => {
  const response = await dataapi.get(`/data-cleansing-progress/${id}`, {
    headers: authHeader()
  });
  return response.data;
};
export const checkCleansingTitleUnique = async (title) => {
  try {
    const response = await dataapi.get(`/data-cleansing-progress/check-if-exists?title=${title}`, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error checking title uniqueness:', error);
    return false;
  }
};
export const cleanData = async (data) => {
  try {
    const response = await dataapi.post(`/data-cleansing-progress/cleanse`,data, {
      headers: authHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error cleansing data:', error);
    return false;
  }
};

export const getProjectsByUserId = async (userId) => {
  try {
    const response = await dataapi.get(`/projects/user/${userId}`);
    return response.data; 
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw new Error('Failed to fetch projects'); 
  }
};

export const addProject = async (projectData) => {
  try {
    const response = await dataapi.post('/projects', projectData);
    return response.data; 
  } catch (error) {
    console.error('Error adding project:', error);
    throw new Error('Failed to add project'); 
  }
};

export const checkProjectNameUnique = async (projectName, userId) => {
  try {
    const response = await dataapi.get(`/projects/exists?projectName=${projectName}&userId=${userId}`);
    return response.data; 
  } catch (error) {
    console.error('Error checking project name uniqueness:', error);
    return false;
  }
};

export const deleteProject = async (projectId) => {
  try {
    await dataapi.delete(`/projects/${projectId}`);
    return true; 
  } catch (error) {
    console.error('Error deleting project:', error);
    throw new Error('Failed to delete project'); 
  }
};



export const getAllGroups = async () => {
  try {
    const response = await dataapi.get('/projects/group');
    return response.data; 
  } catch (error) {
    console.error('Error fetching groups:', error);
    throw new Error('Failed to fetch groups'); 
  }
};
export const getGroupById = async (groupId) => {
  try {
    const response = await dataapi.get(`/projects/group/${groupId}`);
    return response.data; 
  } catch (error) {
    console.error('Error fetching group:', error);
    throw new Error('Failed to fetch group'); 
  }
};
export const getGroupByProject = async (name,projectId) => {
  try {
    const response = await dataapi.get(`/projects/group/byProject?tableName=${name}&projectId=${projectId}` );
    return response.data; 
  } catch (error) {
    console.error('Error fetching group:', error);
    throw new Error('Failed to fetch group'); 
  }
};
export const createGroup = async (groupData,projectId,tableName) => {
  try {
    const response = await dataapi.post(`/projects/group?name=${groupData}&projectId=${projectId}&tableName=${tableName}` );
    return response.data; 
  } catch (error) {
    console.error('Error creating group:', error);
    throw new Error('Failed to create group'); 
  }
};
export const updateGroup = async (groupId, groupData) => {
  try {
    const response = await dataapi.put(`/projects/group/${groupId}`, groupData);
    return response.data; 
  } catch (error) {
    console.error('Error updating group:', error);
    throw new Error('Failed to update group'); 
  }
};
export const deleteGroup = async (groupId) => {
  try {
    const response = await dataapi.delete(`/projects/group/${groupId}`);
    return response.data; 
  } catch (error) {
    console.error('Error deleting group:', error);
    throw new Error('Failed to delete group'); 
  }
};
export const getRecordsByGroupId = async (groupId) => {
  try {
    const response = await dataapi.get(`/projects/group/${groupId}/records`);
    return response.data; 
  } catch (error) {
    console.error('Error fetching records for group:', error);
    throw new Error('Failed to fetch records for group'); 
  }
};
export const addRecordToGroup = async (groupId, recordData) => {
  try {
    const response = await dataapi.post(`/projects/group/${groupId}/records`, recordData);
    return response.data; 
  } catch (error) {
    console.error('Error adding record to group:', error);
    throw new Error('Failed to add record to group'); 
  }
};
export const addRecordsToGroupbatch = async (groupId, recordData) => {
  try {
    const response = await dataapi.post(`/projects/group/${groupId}/records/batch`, recordData);
    return response.data; 
  } catch (error) {
    console.error('Error adding record to group:', error);
    throw new Error('Failed to add record to group'); 
  }
};
export const removeRecordsFromGroup = async (groupId, records) => {
  try {
    const response = await dataapi.post(`/projects/group/${groupId}/records/delete`,records);
    return response.data; 
  } catch (error) {
    console.error('Error removing record from group:', error);
    throw new Error('Failed to remove record from group'); 
  }
};
export const checkGroupNameUnique = async (name) => {
  try {
    const response = await dataapi.get(`/projects/group/exists?groupName=${name}`);
    return response.data; 
  } catch (error) {
    console.error('Error checking group name uniqueness:', error);
    return false;
  }
};
export const getCompletedDatCleansingProgressByUserId = async (userId,projectId) => {
  const response = await dataapi.get(`/data-cleansing-progress/completed/by-user-and-project?userId=${userId}&projectId=${projectId}`, {
    headers: authHeader()
  });
  return response.data;
};
export const getColumnNames = async (name) => {
  try {
    const response = await dataapi.get(`/report-progress/columnNames?tableName=${name}`);
    return response.data; 
  } catch (error) {
    console.error('Error getting column names:', error);
    return false;
  }
};


export const getJobsByProject = async (name) => {
  try {
    const response = await dataapi.get(`/quartz/jobs/${name}`);
    return response.data; 
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw new Error('Failed to fetch jobs'); 
  }
};
export const executeJobAction = async (jobName,groupName,action) => {
  try {
    const response = await dataapi.post(`/quartz/${action}?jobName=${jobName}&groupName=${groupName}`);
    return response.data; 
  } catch (error) {
    console.error(`Error ${action} job`, error);
    throw new Error(`Failed to ${action} job`); 
  }
};