import React, { useEffect, useState,useRef  } from 'react';
import { Table, Button, Modal, Tag, Space,Select,Divider,Row,Col, Form,message,Input,Typography ,Progress,Tooltip } from 'antd';
import { EyeOutlined, FileOutlined, EditOutlined, SyncOutlined, PlayCircleOutlined, BarChartOutlined } from '@ant-design/icons';
import { getReportProgressByUserId, getProcessedData ,getReportProgressById,downloadCsv,exportReportAsPdf,getRecordsByGroupId,checkGroupNameUnique,getGroupByProject,createGroup,addRecordsToGroupbatch,removeRecordsFromGroup,deleteGroup} from '../../Service/ApiServices';
import { useNavigate } from 'react-router-dom';
import ViewReport from './ViewReport';
import { SearchBar } from "../../components";

const { Option } = Select;
const { Title } = Typography;
const twoColors = {
    '0%': '#108ee9',
    '100%': '#87d068',
  };
const ReportProgressList = () => {
  const [data, setData] = useState([]);
  const [cloneData, setCloneData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [previewData, setPreviewData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [groupModalVisible, setGroupModalVisible] = useState(false);
  const [groups, setGroups] = useState([]);  
  const [newGroupName, setNewGroupName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);
  const [deleteGroupModalVisible, setDeleteGroupModalVisible] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const navigate = useNavigate();
  const myRef = useRef(null);

  useEffect(() => {
    fetchData();
    fetchGroups();
  }, []);

  const fetchData = async () => {
    try {
     const user = JSON.parse(localStorage.getItem('userDetails'));
      setUserId(user.id);
      const selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
      setCurrentProject(selectedProject.id)
      getReportProgressByUserId(user.id,selectedProject.id).then(res => {
        setData(res);
        setCloneData(res);
        setAllData(res)
        setLoading(false);
      });
    } catch (error) {
      console.error('Error fetching report progress data:', error);
      setLoading(false);
    }
  };
  const fetchGroups = async () => {
    // Fetch the list of existing groups from the backend API
    try {
      const selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
      const groupsData = await getGroupByProject("report_Progress",selectedProject.id);  // Assuming an API function to get groups
      setGroups(groupsData);
    } catch (error) {
      console.error('Error fetching groups:', error);
    }
  };
  const handleGroupChange = async (value) => {
    setLoading(true);
    if(value)
      {
        const records =await getRecordsByGroupId(value);
        const recordIds = records.map(record => record.recordId);
        const filteredData = allData.filter(record => recordIds.includes(record.id));
        setData(filteredData);
        setCloneData(filteredData);
        setSelectedGroup(value);
      }
    else
    {
      setData(allData);
        setCloneData(allData);
        setSelectedGroup(null);
    }
          setLoading(false);
  };
  const handleAddToGroup = () => {
    setGroupModalVisible(true);
  };
  const handleGroupSubmit = async () => {
    try {
      if (newGroupName.trim() !== "") {
        
        const isUnique = await checkGroupNameUnique(newGroupName);
  
        if (isUnique) {
          message.error("A folder with this name already exists. Please choose a different name.");
          return;
        }
      }
      let groupId = selectedGroup;
      if (!selectedGroup && newGroupName) {
        // Create a new group if one doesn't exist
        groupId = await createGroup(newGroupName,currentProject,"report_Progress");
       
      }

      if (groupId) {
        const recordsToAdd = selectedRows.map(row => ({
          recordId: row.id
        }));
        // Add selected rows to the group
        await addRecordsToGroupbatch(groupId, recordsToAdd);
        message.success("Records added to folder successfully");
        setGroupModalVisible(false);
        setSelectedRows([]);
        setSelectedGroup(null);
        setNewGroupName("");
        fetchGroups(); // Refresh groups after adding records
      } else {
        message.error("Please select a folder or enter a new folder name.");
      }
    } catch (error) {
      console.error('Error adding records to folder:', error);
      message.error('Failed to add records to folder');
    }
  };
  const handleRemoveRecords = async () => {
    try {
      const recordsToremove = selectedRows.map(row => ({
        recordId: row.id
      }));
      await removeRecordsFromGroup(selectedGroup, recordsToremove);
      message.success("Records removed from folder successfully");
      setSelectedRows([]);
      fetchGroups();
      handleGroupChange(selectedGroup);
    } catch (error) {
      console.error('Error removing records from folder:', error);
      message.error('Failed to remove records from folder');
    }
  };
  const handleDeleteGroup = async () => {
    try {
      await deleteGroup(groupToDelete);
      message.success("Folder deleted successfully");
      setDeleteGroupModalVisible(false);
      fetchGroups(); // Refresh groups after deletion
    } catch (error) {
      console.error('Error deleting folder:', error);
      message.error('Failed to delete folder');
    }
  };

  const handlePreviewData = async (tableName) => {
    try {
        setLoading(true);
      const data = await getProcessedData(tableName);
      setPreviewData(data);
      setModalContent('data');
      setIsModalVisible(true);
      setLoading(false);
    } catch (error) {
        setLoading(false);
      console.error('Error fetching preview data:', error);
    }
  };
  const handleViewReport = async (record) => {
    try {
      console.log(record)
      setPreviewData([]);
        setLoading(true);
      setPreviewData(record);
      setModalContent('report');
      setIsModalVisible(true);
      setLoading(false);
    } catch (error) {
        setLoading(false);
      console.error('Error fetching preview data:', error);
    }
  };
  const handleModalClose = async () => {
    try {
      console.log("in cancel")
        setLoading(true);
      setPreviewData([]);
      setModalContent('data');
      setIsModalVisible(false);
      setLoading(false);
    } catch (error) {
        setLoading(false);
      console.error('Error Closing modal:', error);
    }
  };
  const handleViewProgress = async (key) => {
    try {
        setLoading(true);
      const reportProgress = await getReportProgressById(key);
      setPreviewData(reportProgress.progress);
      setModalContent('progress');
      setIsModalVisible(true);
      setLoading(false);
    } catch (error) {
        setLoading(false);
      console.error('Error fetching preview data:', error);
    }
  };
  const handleResume = (recordId) => {
    navigate(`/report/resume/${recordId}`); 
  };
  const handleDownload = async (tableName) => {
    try {
        setLoading(true);
      await downloadCsv(tableName);
      setLoading(false);
    } catch (error) {
        setLoading(false);
      console.error('Failed to download CSV:', error);
    }
  };
  const handleDownloadReport = async (reportId) => {
    try {
      await exportReportAsPdf(reportId);
    } catch (error) {
    
      console.error('Failed to download report:', error);
    }
  };
  const handleSearch = (data) => {
    setData(data)
  }
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
      fixed: 'left',
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        fixed: 'left',
      },
      {
        title: 'Query',
        dataIndex: 'query',
        key: 'query',
        width: 300,
        render: (text) => (
          <div
            style={{
              maxHeight: '60px',        
              overflowY: 'auto',     
              padding: '4px',
              border: '1px solid #f0f0f0',
              borderRadius: '4px',
              scrollbarWidth: 'thin',   
              scrollbarColor: '#888 #f0f0f0', 
            }}
          >
            <style jsx>{`
              /* Scrollbar styling for WebKit-based browsers (Chrome, Safari, Edge) */
              div::-webkit-scrollbar {
                width: 6px;
              }
              div::-webkit-scrollbar-thumb {
                background-color: #888;  /* Thumb color */
                border-radius: 4px;
              }
              div::-webkit-scrollbar-thumb:hover {
                background-color: #555;  /* Darker thumb on hover */
              }
              div::-webkit-scrollbar-track {
                background: #f0f0f0;     /* Track color */
                border-radius: 4px;
              }
            `}</style>
            {text}
          </div>
        ),
      },
      
      
    {
      title: 'Current Step',
      dataIndex: 'currentStep',
      key: 'currentStep',
      fixed: 'left',
      render: (text, record) => (
        <Tag color={
          record.progress === -1 ? 'red' :
            record.currentStep === 3 ? 'green' :
              record.currentStep === 2 && record.progress != 100 ? 'blue' :
                'warning'
        }>
          {record.progress === -1 ? 'Failed' :
            record.currentStep === 3 ? 'Report Generated' :
              record.currentStep === 2 && record.progress != 100 ? 'In Progress' :
                record.currentStep === 2 && record.progress == 100 ? 'Dataset Generated' :
                  record.currentStep === 1 && 'Pending'}
        </Tag>
      )
    },
    {
      title: 'Table Name',
      dataIndex: 'tableName',
      key: 'tableName',
    },
    {
      title: 'Template ID',
      dataIndex: 'templateId',
      key: 'templateId',
    },
    {
      title: 'Report ID',
      dataIndex: 'reportId',
      key: 'reportId',
    },
    {
      title: 'Dataset ID',
      dataIndex: 'datasetId',
      key: 'datasetId',
    },

    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          {
            record.progress === -1 ? null : (
              record.currentStep === 3 ? (
                <>
                  <Tooltip title="Preview Data">
                    <Button
                      type="link"
                      icon={<EyeOutlined style={{fontSize:'20px'}}/>}
                      onClick={() => handlePreviewData(record.tableName)}
                      className="custom-icon"
                    />
                  </Tooltip>
                  <Tooltip title="Export CSV">
                    <Button
                      type="link"
                      icon={<FileOutlined style={{fontSize:'18px'}}/>}
                      onClick={() => handleDownload(record.tableName)}
                      className="custom-icon"
                    />
                  </Tooltip>
                  <Tooltip title="View Report">
                    <Button
                      type="link"
                      icon={<BarChartOutlined style={{fontSize:'18px'}}/>}
                      onClick={() => handleViewReport(record)}
                      className="custom-icon"
                    />
                  </Tooltip>
                </>
              ) : (
                record.currentStep === 2 ? (
                  record.progress !== 100 ? (
                    <Tooltip title="View Progress">
                      <Button
                        type="link"
                        icon={<SyncOutlined style={{fontSize:'18px'}}/>}
                        onClick={() => handleViewProgress(record.id)}
                        className="custom-icon"
                      />
                    </Tooltip>
                  ) : (
                    <>
                      <Tooltip title="Resume">
                        <Button
                          type="link"
                          icon={<PlayCircleOutlined style={{fontSize:'18px'}}/>}
                          onClick={() => handleResume(record.id)}
                          className="custom-icon"
                        />
                      </Tooltip>
                      <Tooltip title="Preview Data">
                        <Button
                          type="link"
                          icon={<EyeOutlined style={{fontSize:'18px'}}/>}
                          onClick={() => handlePreviewData(record.tableName)}
                          className="custom-icon"
                        />
                      </Tooltip>
                      <Tooltip title="Export CSV">
                        <Button
                          type="link"
                          icon={<FileOutlined style={{fontSize:'18px'}}/>}
                          onClick={() => handleDownload(record.tableName)}
                          className="custom-icon"
                        />
                      </Tooltip>
                    </>
                  )
                ) : (
                  record.currentStep === 1 && (
                    <Tooltip title="Resume">
                      <Button
                        type="link"
                        icon={<PlayCircleOutlined style={{fontSize:'18px'}}/>}
                        onClick={() => handleResume(record.id)}
                        className="custom-icon"
                      />
                    </Tooltip>
                  )
                )
              )
            )
          }

      </Space>
      
      ),
    },
  ];

  const handleAddClick = () => {
    navigate('/report/new'); 
  };

  const renderModalContent = () => {
    if (modalContent === 'data') {
      return (
        <Table
          dataSource={previewData}
          columns={Object.keys(previewData[0] || {}).map(key => ({
            title: key,
            dataIndex: key,
            key: key,
          }))}
          rowKey="id"
          pagination={false}
          className="modal-table"
        />
      );
    } else if (modalContent === 'progress') {
      return (
        <Progress type="circle" percent={previewData} strokeColor={twoColors} />
      );
    }
    else if (modalContent === 'report') {
        return (
          <ViewReport finalTableName={previewData.tableName} reportId={previewData.reportId} />
        );
      }
  };
  const rowSelection = {
    selectedRowKeys: selectedRows.map(row => row.id),
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };
  return (
    <div>
      <div className="repo-header">
        <Title level={2}>Report Progress</Title>
        <Divider type="vertical" style={{borderLeft: '1px solid #b8b8b8', height:' 30px',marginTop:'15px',marginLeft:15}}/>
        <Select
          placeholder="Select a Folder"
          style={{ width: 200, marginLeft: 10, marginBottom:-16 }}
          onChange={handleGroupChange}
          allowClear
        >
          {/* <Option value={null}>All</Option> */}
          {groups.map(group => (
            <Option key={group.id} value={group.id}>
              {group.name}
            </Option>
          ))}
        </Select>
  
      </div>
   
         <Row>
        <Col span={8}>  
        {selectedRows.length === 0 && (
          <Button type="primary" onClick={handleAddClick} >
           New Report
          </Button>
        )}

        {selectedRows.length > 0 && selectedGroup == null && (
          <Button
            type="primary"
            onClick={handleAddToGroup}
          >
            Add to Folder
          </Button>
        )}

        {selectedRows.length > 0 && selectedGroup !== null && (
          <Button
            type="primary"
              danger
            onClick={handleRemoveRecords}
          >
            Remove from Folder
          </Button>
        )} 
       </Col>
        <Col span={8} offset={8}  >
        <div style={{ float:'right' }}>
        <SearchBar
          data={cloneData}
          handler={handleSearch}
          ref={myRef}
        ></SearchBar>
        </div>
        </Col>
      </Row>
   
      <Table style={{marginTop:'10px'}} columns={columns} dataSource={data} loading={loading} rowKey="id"   scroll={{ x: 'max-content' }} rowSelection={rowSelection}/>
      <Modal
        title="Add to Folder"
        visible={groupModalVisible}
        cancelButtonProps={{ type: 'primary' }} 
        onCancel={() => setGroupModalVisible(false)}
        onOk={handleGroupSubmit}
        okText="Add"
        cancelText="Cancel"
      >
        <Form layout="vertical">
          <Form.Item label="Select Existing Folder">
            <Select
              placeholder="Select a folder"
              onChange={setSelectedGroup}
              value={selectedGroup}
              allowClear
            >
              {groups.map(group => (
                <Option key={group.id} value={group.id}>
                  {group.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Or Create New Folder">
            <Input
              placeholder="Enter new folder name"
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Delete Folder"
        visible={deleteGroupModalVisible}
        onCancel={() => setDeleteGroupModalVisible(false)}
        onOk={handleDeleteGroup}
        okText="Delete"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this folder?</p>
      </Modal>
      <Modal
         title={
            modalContent === 'data' ? 'Preview Data' :
            modalContent === 'report' ? 'Power BI Report' : 
            'Progress'
          }
        visible={isModalVisible}
        onCancel={() => handleModalClose()}
        footer={[
          <Button key="close" type="primary" onClick={() => setIsModalVisible(false)}>
            Close
          </Button>,
        ]}
        width={1000} 
        className="report-progress-modal"
      >
        {renderModalContent()}
      </Modal>
    </div>
  );
};

export default ReportProgressList;
