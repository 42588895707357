export default function authHeader() {
    const token =localStorage.getItem('token');
  
    if (token) {
       return { Authorization: 'Bearer ' + token,
       'Content-Type': 'application/json;charset=UTF-8',
       "Access-Control-Allow-Origin": "*" }; 
     
    } else {
      return {};
    }
  }