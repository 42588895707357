import React, { useEffect, useState } from 'react';
import { Layout, Menu, Button, Dropdown,Typography, Space,Tooltip } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { MenuUnfoldOutlined, MenuFoldOutlined,ProjectOutlined, UserOutlined, BookOutlined, LogoutOutlined, BuildOutlined, ClearOutlined, MergeCellsOutlined, DatabaseOutlined, BarsOutlined, RetweetOutlined } from '@ant-design/icons';
import { useAuth } from '../../Context/AuthProvider';
import {
  getProjectsByUserId
} from '../../Service/ApiServices';
import { useNavigate } from 'react-router-dom';
import './LayoutComp.css';
import logo from '../../assets/logo.png';
import icon from '../../assets/icon_logo.jpg';

const { Text } = Typography;
const { Header, Sider, Content } = Layout;

const LayoutComp = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const { logout } = useAuth();
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentUser,setCurrentUser] = useState({});
  const [currentUserRole,setCurrentUserRole] = useState(null);
  const navigate = useNavigate();
  const toggle = () => {
    setCollapsed(!collapsed);
  };

useEffect(() => {
  const fetchUserData = async () => {
    try {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      setCurrentUser(userDetails);
      if(userDetails.roles[0])
        {
            let role = userDetails.roles[0]
            setCurrentUserRole(role.name)
        }
      const userProjects = await getProjectsByUserId(userDetails.id);
      setProjects(userProjects);

      if (userProjects.length > 0) {
        localStorage.setItem('selectedProject', JSON.stringify(userProjects[0]));
        setSelectedProject(userProjects[0]);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  fetchUserData(); // Call the async function inside useEffect
}, []);


  const handleProjectChange = (project) => {
    localStorage.setItem('selectedProject', JSON.stringify(project));
    setSelectedProject(project);
    navigate('/workspace'); 

  };
  const handleLogout =() =>{
    logout();
    navigate('/login'); 
  }
  const projectMenu = (
    <Menu>
      {projects.map((project) => (
        <Menu.Item key={project.id} onClick={() => handleProjectChange(project)}>
          {project.projectName}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider trigger={null} collapsible collapsedWidth={60} collapsed={collapsed} theme="light">
        <div>
          {collapsed ? (
            <div><img src={icon} alt="Logo" className="icon" /></div>
          ) : (
            <div>
              <img src={logo} alt="Logo" className="logo" />
            </div>
          )}
        </div>
        <Menu theme="light" mode="inline" selectedKeys={[location.pathname]}>
          <Menu.Item key="/workspace" icon={<ProjectOutlined style={{fontSize:'18px'}} />}>
            <Link to="/workspace">Workspace</Link>
          </Menu.Item>
          <Menu.Item key="/query" icon={<BuildOutlined style={{fontSize:'18px'}}/>}>
            <Link to="/query">Data setup</Link>
          </Menu.Item>
          <Menu.Item key="/data-cleansing" icon={<ClearOutlined style={{fontSize:'18px'}} />}>
            <Link to="/data-cleansing">Data Cleansing</Link>
          </Menu.Item>
          <Menu.Item key="/report" icon={<MergeCellsOutlined style={{fontSize:'18px'}} />}>
            <Link to="/report">Report Progress</Link>
          </Menu.Item>
       
         {currentUserRole == 'Admin' && <Menu.Item key="/users" icon={<UserOutlined style={{fontSize:'18px'}} />}>
            <Link to="/users">Users</Link>
          </Menu.Item>}
          <Menu.Item key="/repositories" icon={<DatabaseOutlined style={{fontSize:'18px'}} />}>
            <Link to="/repositories">Repositories</Link>
          </Menu.Item>
          <Menu.Item key="/variableManager" icon={<BarsOutlined style={{fontSize:'18px'}} />}>
            <Link to="/variableManager">Variable Manager</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-header">
          <div className="header-left">
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: toggle,
            })}
          </div>

          <div className="header-right">
            <Space>
            <Tooltip title="Workspaces">
            <ProjectOutlined style={{fontSize:'25px',marginTop:'25px'}} />
            </Tooltip>
            
          <Dropdown overlay={projectMenu}  className="project-dropdown">
              <Button className="dropdown-button" icon={<RetweetOutlined />} >
                {selectedProject ? selectedProject.projectName : 'Select Workspace'}
              </Button>
            </Dropdown>
          <Text class ="user-name" style={{cursor:'pointer'}}><UserOutlined /> {currentUser.username}</Text>
            <Button type="link" className="logout-button" icon={<LogoutOutlined />} onClick={handleLogout}>
              Logout
            </Button>
            </Space>
          </div>
        </Header>

        <Content className="site-layout-content">
          {children}
        </Content>
      </Layout>


    </Layout>
  );
};

export default LayoutComp;
