import React, { Component } from 'react';
import { getEmbedToken } from '../../Service/ApiServices';
import * as powerbi from 'powerbi-client';
import { Button, Row, Col, Space } from 'antd';
import { DownloadOutlined, RedoOutlined, SaveOutlined } from '@ant-design/icons';

class ViewReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      embedUrl: '',
      reportId: props.reportId,
      embedToken: '',
      reportName: '',
      tokenExpiry: '',
      combinedTable: props.finalTableName,
      reportObject: null,
      mode: 'view',
      isReportLoaded: false,
    };
    this.powerbiService = new powerbi.service.Service(
      powerbi.factories.hpmFactory,
      powerbi.factories.wpmpFactory,
      powerbi.factories.routerFactory
    );
    this.reportContainerRef = React.createRef();
  }

  componentDidMount() {
    this.fetchEmbedConfig();
  }

  fetchEmbedConfig() {
    const { combinedTable, reportId } = this.state;

    getEmbedToken(combinedTable, reportId)
      .then(data => {
        this.setState({
          embedUrl: data.embedReports[0].embedUrl,
          reportId: data.embedReports[0].reportId,
          reportName: data.embedReports[0].reportName,
          embedToken: data.embedToken,
          tokenExpiry: data.tokenExpiry
        }, () => {
          this.embedPowerBIReport();
        });
      })
      .catch(error => {
        console.error('Error fetching embed config:', error);
      });
  }

  embedPowerBIReport() {
    const embedConfig = {
      type: 'report',
      tokenType: powerbi.models.TokenType.Embed,
      accessToken: this.state.embedToken,
      embedUrl: this.state.embedUrl,
      id: this.state.reportId,
      permissions: powerbi.models.Permissions.All,
    //  viewMode: powerbi.models.ViewMode.Edit,
      settings: {
        filterPaneEnabled: true,
        navContentPaneEnabled: true
      },
    };

    const powerbiElement = this.reportContainerRef.current;

    this.powerbiService.reset(powerbiElement);

    const reportObject = this.powerbiService.embed(powerbiElement, embedConfig);

    reportObject.on('loaded', () => {
      console.log('Report load successful');
      this.setState({ reportObject, isReportLoaded: true });
    });

    reportObject.on('error', event => {
      console.error('Error embedding report:', event.detail);
    });

    this.setState({ reportObject });
  }

  downloadPDF = async () => {
    const { reportObject, isReportLoaded } = this.state;
    if (reportObject && isReportLoaded) {
      try {
        await reportObject.print();
      } catch (error) {
        console.error('Error while saving:', error);
      }
    }
  };

  refreshReport = async () => {
    const { reportObject, isReportLoaded } = this.state;
    if (reportObject && isReportLoaded) {
      try {
        await reportObject.refresh();
      } catch (error) {
        console.error('Error while refreshing:', error);
      }
    }
  };

  saveReport = async () => {
    const { reportObject, isReportLoaded } = this.state;
    if (reportObject && isReportLoaded) {
      try {
        await reportObject.save();
      } catch (error) {
        console.error('Error while saving:', error);
      }
    }
  };

  switchMode = async (val) => {
    const { reportObject, isReportLoaded } = this.state;
    if (reportObject && isReportLoaded) {
      try {
        await reportObject.switchMode(val);
        this.setState({ mode: val });
      } catch (error) {
        console.error('Error while switching mode:', error);
      }
    }
  };

  render() {
    const { mode, isReportLoaded } = this.state;

    return (
      <div>
        <Row gutter={16} style={{ marginBottom: '10px' }}>
          <Col className="gutter-row" span={6}>
            {/* <Space>
              <Button
                type="primary"
                shape="round"
                onClick={() => this.switchMode(mode === 'view' ? 'edit' : 'view')}
                disabled={!isReportLoaded}
                icon={<SaveOutlined />}
                size='large'
              >
                {mode === 'view' ? 'Switch to Edit' : 'Switch to View'}
              </Button>
            </Space> */}
          </Col>
          <Col className="gutter-row" span={6} />
          <Col className="gutter-row" span={6} />
          <Col className="gutter-row" span={6}>
            <Space>
              {/* <Button
                type="primary"
                shape="round"
                onClick={this.refreshReport}
                disabled={!isReportLoaded}
                icon={<RedoOutlined />}
                size='large'
              >
                Refresh
              </Button> */}
              <Button
                type="primary"
                shape="round"
                onClick={this.downloadPDF}
                disabled={!isReportLoaded}
                icon={<DownloadOutlined />}
                size='large'
              >
                Download PDF
              </Button>
            </Space>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            <div
              id="powerbi-report-container"
              ref={this.reportContainerRef}
              style={{ height: '600px' }}
            >
              Loading the report...
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ViewReport;
