import React, { useState, useEffect , useRef} from "react";
import {
    Table,
    Button,
    Form,
    Select,
    Row,
    Col,
    Typography,
    Spin,
    notification,
    Drawer,
    Card,
    Space,
    Input,
    Tooltip,
    Checkbox,
    Steps,
    Modal,
    message
} from "antd";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { getProcessedData, checkCleansingTitleUnique,getRecordsByGroupId,getGroupByProject,cleanData,getCompletedQueryProgressByUserId } from '../../Service/ApiServices';
import { SettingOutlined,QuestionCircleOutlined ,CheckCircleTwoTone, PlusOutlined} from "@ant-design/icons";
import { SearchBar } from "../../components";


const { Step } = Steps;
const { Option } = Select;
const { Title } = Typography;

const tableNameRegex = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
const appendCurrentDateTimeToTitle = (title) => {
  const now = new Date();
  const formattedDateTime = now.toISOString().replace(/[-:.TZ]/g, '').slice(0, 14);
  const newUsername = `${title}_${formattedDateTime}`;
  return newUsername;
}

const DataCleansing = () => {
    const [data, setData] = useState([]);
    const [allData, setallData] = useState([]);
    const [selectedRules, setSelectedRules] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [tableLoading, setTableLoading] = useState(false);
    const [tableList, setTableList] = useState([]);
    const [previewData, setPreviewData] = useState([]);
    const [cloneTableList, setCloneTableList] = useState([]);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedTable, setSelectedTable] = useState(null);
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [columnSpecificRules, setColumnSpecificRules] = useState({});
    const [title, setTitle] = useState('');
    const [isTitleValid, setIsTitleValid] = useState(true);
    const [isTitleUnique, setIsTitleUnique] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [currentProject, setCurrentProject] = useState('');
    const [groups, setGroups] = useState([]);  
    const [selectedGroup, setSelectedGroup] = useState(null);

    const myRef = useRef(null);
    const navigate = useNavigate();
    useEffect(() => {
        fetchDataPreview();
    }, []);

    const fetchDataPreview = async () => {
        setTableLoading(true);
        try {
            const user = localStorage.getItem('userDetails');
            let userdata = JSON.parse(user);
            const selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
            const tablesList = await getCompletedQueryProgressByUserId(userdata.id,selectedProject.id);
            const groupsData = await getGroupByProject("query_Progress",selectedProject.id); 
            setGroups(groupsData);
            setCurrentProject(selectedProject)
            setUserId(userdata.id);
            setCloneTableList(tablesList)
            setTableList(tablesList)
            setallData(tablesList)
            setTableLoading(false);
        } catch (error) {
            console.error("Error fetching data preview:", error);
            notification.error({
                message: "Error",
                description: "Failed to fetch data preview.",
            });
            setTableLoading(false);
        }
    };
    const handleGroupChange = async (value) => {
      setTableLoading(true);
      if(value)
        {
          const records =await getRecordsByGroupId(value);
          const recordIds = records.map(record => record.recordId);
          const filteredData = allData.filter(record => recordIds.includes(record.id));
          setCloneTableList(filteredData)
          setTableList(filteredData)
          setSelectedGroup(value);
        }
      else
      {
        setCloneTableList(allData)
          setTableList(allData)
          setSelectedGroup(null);
      }
      setTableLoading(false);
    };
    const handleTableSelection = (e,tableName) => {
        const { checked } = e.target;
        if (checked) {
          
          setSelectedTable(tableName);
        } else {
        
          setSelectedTable(null); 
        }
      };
    const  handleSearch = (data) => {
        setTableList(data)
       
      }
      const  handlePreviewData = async (key) => {
        try {
          const data = await getProcessedData(key);
          setPreviewData(data);
          setIsModalVisible(true);
        } catch (error) {
          console.error('Error fetching preview data:', error);
        }
      };
    const  nextStep = async () => {
        
        setTableLoading(true)
        if (currentStep == 0) {
            if (!title.trim()) {
                setIsTitleValid(false);
                setErrorMessage('Please enter a Title!');
                message.error('Please enter a Title!');
                setTableLoading(false)
                return;
              } 
            if (selectedTable == null) {
                message.error('Please select a table!');
                setTableLoading(false)
                return;
              } 
            const data = await getProcessedData(selectedTable);
            setData(data);
          setCurrentStep(1)
          setTableLoading(false)
        }
        // else if (currentStep == 1) {
            
        // }
        
    
      };
      const  handleDone = () => {
        handleApplyCleansing();
       
      };
    const handleRuleChange = (value) => {
        setSelectedRules(value);
    };
    const handleCancel = () => {
        setIsModalVisible(false)
       
      };
    

      const onTitleChange = (event) => {
        setTitle(event.target.value);
      };
    
      const onTitleBlur = async () => {
        try {
          if (!title.trim()) {
            setIsTitleValid(false);
            setErrorMessage('Please enter a Title!');
            message.error('Please enter a Title!');
            return;
          } 
          else if (!tableNameRegex.test(title)) {
            setIsTitleUnique(true);
            setIsTitleValid(false);
            setErrorMessage(
              'Title can only contain letters, digits, or underscores, and must start with a letter or underscore'
            );
            return;
          } 
          else {
            setIsTitleValid(true);
            const res = await checkCleansingTitleUnique(title);
            
            if (res) {
              setIsTitleUnique(false);
              setErrorMessage('Title already exists!');
            } else {
              setIsTitleUnique(true);
              setErrorMessage('');
            }
          }
        } catch (error) {
          console.error('Error checking title:', error);
          setIsTitleUnique(false);
          setErrorMessage('Error checking title. Please try again later.');
        }
      };
    const handleApplyCleansing = async () => {
        if (Object.keys(columnSpecificRules).length === 0) {
            notification.warning({
                message: "No Rules Selected",
                description: "Please select cleansing rules to apply to columns.",
            });
            return;
        }

        for (const column in columnSpecificRules) {
            const rules = columnSpecificRules[column]?.rules || [];
            const condition = columnSpecificRules[column]?.condition;
            const mappingKey = columnSpecificRules[column]?.mapping;
            try {
                if (condition) {
                    columnSpecificRules[column].condition = JSON.parse(condition);
                }
                if (mappingKey) {
                    columnSpecificRules[column].mapping = JSON.parse(mappingKey);
                }
            } catch (e) {
                notification.warning({
                    message: "Error parsing"
                });
                return;
            }
        }

        setTableLoading(true);
        try {
            let data =  { 
                source_table:selectedTable,
                query: "SELECT * FROM "+selectedTable,
                target_table:appendCurrentDateTimeToTitle(title),
                rules: columnSpecificRules,
                title:title,
                userId:userId,
                projectId:currentProject.id
              };
            const response = cleanData(data);
            if (response) {
                      
                notification.success({
                  message: "Success",
                  description: response.message || "Data Cleansing started successfully.",
              });
              navigate('/data-cleansing'); 
            } else {
              console.log('Data cleansing failed.');
            }
            // const response = await axios.post(
            //     "http://localhost:8084/api/data-cleansing-progress/cleanse", 
            //     { 
            //       source_table:selectedTable,
            //       query: "SELECT * FROM "+selectedTable,
            //       target_table:appendCurrentDateTimeToTitle(title),
            //       rules: columnSpecificRules,
            //       title:title,
            //       userId:userId
            //     },
            //     {
            //       headers: {
            //         "Content-Type": "application/json",
            //       }
                
            //     }
            //   );
  
           // fetchDataPreview();
        } catch (error) {
            console.error("Error applying cleansing rules:", error);
            notification.error({
                message: "Error",
                description: error.response?.message || "Failed to apply cleansing rules.",
            });
            navigate('/data-cleansing'); 
        } finally {
            setTableLoading(false);
        }
    };

    const cleansingRules = [
        { value: "TRIM_WHITESPACE", label: "Trim Whitespace" },
        { value: "REMOVE_SPECIAL_CHARACTERS", label: "Remove Special Characters" },
        { value: "CONVERT_TO_LOWERCASE", label: "Convert to Lowercase" },
        { value: "REMOVE_NULL", label: "Remove Null Values" },
        { value: "REMOVE_DUPLICATES", label: "Remove Duplicates" },
        // { value: "CUSTOM_DATE_FORMAT", label: "Standardize Date (Custom Format)" },
        // { value: "CUSTOM_PHONE_FORMAT", label: "Standardize Phone Number (Custom)" },
        // { value: "ENRICH_ADDRESS", label: "Enrich Address (Geolocation)" },
        // { value: "STANDARDIZE_UNITS", label: "Standardize Measurement Units" },
        { value: "FILL_MISSING_VALUES", label: "Fill Missing Values with Default" },
        { value: "REPLACE_NULL_WITH_DEFAULT", label: "Replace Null Values" },
        { value: "CONVERT_TO_UPPERCASE", label: "Convert to Uppercase" },
        { value: "GENERIC_CONDITIONAL_RULE", label: "Apply Conditional Value Replacement" },
        { value: "APPLY_MAPPING", label: "Apply Mapping" },
    ];

    const showDrawer = (column) => {
        setCurrentColumn(column);
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    const handleColumnRulesChange = (value) => {
        setColumnSpecificRules({
            ...columnSpecificRules,
            [currentColumn]: {
                ...columnSpecificRules[currentColumn],
                rules: value,
            },
        });
    };

    const handleCustomInputChange = (key, value) => {
        setColumnSpecificRules({
            ...columnSpecificRules,
            [currentColumn]: {
                ...columnSpecificRules[currentColumn],
                [key]: value, 
            },
        });
    };
    const steps = [
        {
          title: 'Select Tables',
          content: (
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Space>
                <Select
                placeholder="Select a Group"
                style={{ width: 200}}
                onChange={handleGroupChange}
                allowClear
              >
                {/* <Option value={null}>All</Option> */}
                {groups.map(group => (
                  <Option key={group.id} value={group.id}>
                    {group.name}
                  </Option>
                ))}
              </Select>
                  <Input
                    placeholder='Input Title'
                    style={{ width: '300px', marginRight: '16px' }}
                    value={title}
                    onChange={onTitleChange}
                    onBlur={onTitleBlur}
                    maxLength={40} minLength={10}
                  />
                  <Tooltip title={<div><p>Title can only contain letters, digits, underscores</p> <p>Must start with a letter or underscore </p><p> Lenght 10-40</p></div>}><Typography.Link href="#"><QuestionCircleOutlined /></Typography.Link></Tooltip></Space>
                {(!isTitleValid || !isTitleUnique) && (
                  <Typography.Text style={{ marginLeft: '10px' }} type="danger">
                    {errorMessage}
                  </Typography.Text>
                )}
              </div>
              <Row>
                <Col span={8} offset={16}>
                <div style={{ marginTop: '10px' ,marginBottom:'10px', float:'right'}}>
                  <SearchBar
                    data={cloneTableList}
                    handler={handleSearch}
                    ref={myRef}
                  ></SearchBar>
                </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Table
                    rowKey="tableName"
                    dataSource={tableList}
                    columns={[
                      {
                        title: 'ID',
                        dataIndex: 'id',
                        key: 'id', defaultSortOrder: 'descend',
                        sorter: (a, b) => a.id - b.id
                      },
                      {
                        title: 'Table Name',
                        dataIndex: 'tableName',
                        key: 'tableName',
                      },
                      {
                        title: 'Data Type',
                        dataIndex: 'dataType',
                        key: 'dataType',
                      },
                      {
                        title: 'Select',
                        key: 'select',
                        render: (text, record) => (
                          <Checkbox
                            checked={selectedTable === record.tableName} 
                            onChange={(e) =>handleTableSelection(e, record.tableName)}
                          />
                        ),
                      },
                      {
                        title: 'Actions',
                        key: 'actions',
                        render: (text, record) => (
                          <Button onClick={() =>handlePreviewData(record.tableName)}>Preview Data</Button>
                        ),
                      },
                    ]}
                  />
                </Col>
              </Row>
  
            </div>
          ),
        },
        {
            title: 'Add Cleansing Rules',
            content: (
                <div >
        
                <Row gutter={[16, 16]}>
                    <Col span={16}>
                        <Card bordered={false} style={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)" }}>
                            <Spin spinning={tableLoading} tip="Loading Data Preview...">
                            <Table
                              columns={Object.keys(data[0] || {}).map((key) => ({
                                title: (
                                  <Space>
                                    <span>{key}</span>
                                    <Tooltip title="Add Rules">
                                      <Button
                                        type="text"
                                        icon={<PlusOutlined />}
                                        size="small"
                                        onClick={() => showDrawer(key)}
                                        style={{
                                          color: '#1890ff',
                                          padding: 0,
                                        }}
                                      />
                                    </Tooltip>
                                  </Space>
                                ),
                                dataIndex: key,
                                key: key,
                              }))}
                              rowKey="id"
                              pagination={false}
                              className="modal-table"
                              dataSource={data}
                            />
                            </Spin>
                        </Card>
                    </Col>
    
                    <Col span={8}>
                        <Card
                            title="Applied Cleansing Rules"
                            bordered={false}
                            style={{ height: "100%", boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)" }}
                        >
                            <Table
                                columns={[
                                    {
                                        title: "Column",
                                        dataIndex: "column",
                                        key: "column",
                                    },
                                    {
                                        title: "Applied Rules",
                                        dataIndex: "rules",
                                        key: "rules",
                                        render: (rules) => rules.join(", "),
                                    },
                                ]}
                                rowKey="column"
                                dataSource={Object.keys(columnSpecificRules).map((column) => ({
                                    column,
                                    rules: columnSpecificRules[column]?.rules || [],
                                }))}
                                pagination={false}
                            />
                        </Card>
                    </Col>
                </Row>
    
                {/* <Row style={{ marginTop: "30px" }}>
                    <Col >
                        <Button
                            type="primary"
                            size="large"
                            onClick={handleApplyCleansing}
                            loading={loading}
                            block
                            style={{
                                borderRadius: "8px",
                                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            Apply Cleansing Rules
                        </Button>
                    </Col>
                </Row> */}
    
                <Drawer
                    title={`Cleansing Rules for Column: ${currentColumn}`}
                    placement="right"
                    onClose={closeDrawer}
                    visible={drawerVisible}
                    width={350}
                    bodyStyle={{ paddingBottom: 80 }}
                >
                    <Form layout="vertical">
                        <Form.Item label={`Select rules for ${currentColumn}`}>
                            <Select
                                mode="multiple"
                                placeholder="Select rules to apply"
                                value={columnSpecificRules[currentColumn]?.rules || []}
                                onChange={handleColumnRulesChange}
                                allowClear
                            >
                                {cleansingRules.map((rule) => (
                                    <Option key={rule.value} value={rule.value}>
                                        {rule.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
    
                
                        {columnSpecificRules[currentColumn]?.rules?.includes("CUSTOM_DATE_FORMAT") && (
                          <Form.Item label="Select Date and Time Format">
                          <Select
                            value={columnSpecificRules[currentColumn]?.dateFormat || "%Y-%m-%d"}
                            onChange={(value) => handleCustomInputChange("dateFormat", value)}
                            placeholder="Choose Date and Time Format"
                          >
                            <Option value="%Y-%m-%d">YYYY-MM-DD</Option>
                            <Option value="%d-%m-%Y">DD-MM-YYYY</Option>
                            <Option value="%m-%d-%Y">MM-DD-YYYY</Option>
                            <Option value="%d/%m/%Y">DD/MM/YYYY</Option>
                            <Option value="%m/%d/%Y">MM/DD/YYYY</Option>
                            <Option value="%Y/%m/%d">YYYY/MM/DD</Option>
                            <Option value="%Y-%m-%d %H:%M:%S">YYYY-MM-DD HH:MM:SS (24-hour)</Option>
                            <Option value="%d-%m-%Y %H:%M:%S">DD-MM-YYYY HH:MM:SS (24-hour)</Option>
                            <Option value="%m-%d-%Y %H:%M:%S">MM-DD-YYYY HH:MM:SS (24-hour)</Option>
                            <Option value="%Y/%m/%d %H:%M">YYYY/MM/DD HH:MM (24-hour)</Option>
                            <Option value="%Y-%m-%d %I:%M %p">YYYY-MM-DD HH:MM AM/PM (12-hour)</Option>
                            <Option value="%d-%m-%Y %I:%M %p">DD-MM-YYYY HH:MM AM/PM (12-hour)</Option>
                            <Option value="%m-%d-%Y %I:%M %p">MM-DD-YYYY HH:MM AM/PM (12-hour)</Option>
                            <Option value="%Y-%m-%d %H:%M:%S %Z">YYYY-MM-DD HH:MM:SS Timezone</Option>
                          </Select>
                        </Form.Item>
                        
                        )}
    
                        {columnSpecificRules[currentColumn]?.rules?.includes("CUSTOM_PHONE_FORMAT") && (
                          <Form.Item label="Select Phone Number Format">
                          <Select
                            value={columnSpecificRules[currentColumn]?.phoneFormat || "+1 (###) ###-####"}
                            onChange={(value) => handleCustomInputChange("phoneFormat", value)}
                            placeholder="Choose Phone Number Format"
                          >
                            <Option value="+1 (###) ###-####">+1 (###) ###-#### (US)</Option>
                            <Option value="+44 ### #### ####">+44 ### #### #### (UK)</Option>
                            <Option value="(###) ###-####">(###) ###-#### (Local)</Option>
                            <Option value="###-###-####">###-###-####</Option>
                            <Option value="##########">########## (Digits Only)</Option>
                            <Option value="+## ###########">+## ########### (With Country Code)</Option>
                          </Select>
                        </Form.Item>
                        
                        )}
    
                        {columnSpecificRules[currentColumn]?.rules?.includes("FILL_MISSING_VALUES") && (
                            <Form.Item label="Default Value for Missing Data">
                                <Input
                                    value={columnSpecificRules[currentColumn]?.defaultValue || ""}
                                    onChange={(e) => handleCustomInputChange("defaultValue", e.target.value)}
                                    placeholder="Enter value to fill missing data"
                                />
                            </Form.Item>
                        )}
    
                     
                        {columnSpecificRules[currentColumn]?.rules?.includes("REPLACE_NULL_WITH_DEFAULT") && (
                            <Form.Item label="Replacement Value for Nulls">
                                <Input
                                    value={columnSpecificRules[currentColumn]?.replacementValue || ""}
                                    onChange={(e) => handleCustomInputChange("replacementValue", e.target.value)}
                                    placeholder="Enter value to replace nulls"
                                />
                            </Form.Item>
                        )}
    
                        {columnSpecificRules[currentColumn]?.rules?.includes("STANDARDIZE_UNITS") && (
                            <Form.Item label="Target Unit (e.g., kg, lb)">
                                <Input
                                    value={columnSpecificRules[currentColumn]?.targetUnit || ""}
                                    onChange={(e) => handleCustomInputChange("targetUnit", e.target.value)}
                                    placeholder="Enter target unit for standardization"
                                />
                            </Form.Item>
                        )}
                        {/* For Conditional Rules */}
                        {columnSpecificRules[currentColumn]?.rules?.includes("GENERIC_CONDITIONAL_RULE") && (
                            <Form.Item label="Conditional Rules (Array of JSON)">
                                <Input.TextArea
                                    value={columnSpecificRules[currentColumn]?.condition || ""}
                                    onChange={(e) => handleCustomInputChange("condition", e.target.value)}
                                    placeholder={`Enter conditions and values in Array of JSON format, e.g.:\n[\n    {\n      "operator": "equals",\n      "values": ["NA"],\n      "replacement": "Not Applicable"\n    },\n    {\n      "operator": "not in",\n      "values": ["M", "F"],\n      "replacement": "U"\n    }\n  ]`}
    
                                    rows={10}
                                />
                            </Form.Item>
                        )}
    
                        {/* For Mapping Rules */}
                        {columnSpecificRules[currentColumn]?.rules?.includes("APPLY_MAPPING") && (
                            <Form.Item label="Mapping Rules (JSON)">
                                <Input.TextArea
                                    value={columnSpecificRules[currentColumn]?.mapping || ""}
                                    onChange={(e) => handleCustomInputChange("mapping", e.target.value)}
                                    placeholder={`Enter mappings in JSON format, e.g.\n{\n  "M": "Male",\n  "F": "Female"\n}`}
                                    rows={10}
                                />
                            </Form.Item>
                        )}
                        {/* {columnSpecificRules[currentColumn]?.rules?.includes("GENERIC_CONDITIONAL_RULE") && (
                            <>
                                <Form.Item label="Condition (e.g., value == 'NA' or value != 'M' && value != 'F')">
                                    <Input
                                        value={columnSpecificRules[currentColumn]?.condition || ""}
                                        onChange={(e) => handleCustomInputChange("condition", e.target.value)}
                                        placeholder="Enter condition to match"
                                    />
                                </Form.Item>
    
                                <Form.Item label="Replacement Value">
                                    <Input
                                        value={columnSpecificRules[currentColumn]?.replacementValue || ""}
                                        onChange={(e) => handleCustomInputChange("replacementValue", e.target.value)}
                                        placeholder="Enter value to replace when condition is true"
                                    />
                                </Form.Item>
                            </>
                        )}
                        {columnSpecificRules[currentColumn]?.rules?.includes("APPLY_MAPPING") && (
                            <Form.Item label={`Select mapping for ${currentColumn}`}>
                                <Select
                                    placeholder="Select a mapping"
                                    onChange={(value) => handleCustomInputChange("mapping", value)}
                                    value={columnSpecificRules[currentColumn]?.mapping || ""}
                                >
                                    {variables.map(variable => (
                                        <Option key={variable.id} value={variable.name}>{variable.name}</Option>
                                    ))}
                                
                                </Select>
                            </Form.Item>
                        )} */}
                        <Form.Item>
                            <Button
                                type="primary"
                                size="large"
                                onClick={closeDrawer}
                                block
                                style={{
                                    borderRadius: "8px",
                                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                Save Rules for Column
                            </Button>
                        </Form.Item>
                    </Form>
                </Drawer>
    
            </div>
            ),
          }
      ];
    return (
        <div>
        <Spin size="large" spinning={tableLoading}>
          <Title level={3}>Data Cleansing</Title>
          <Steps current={currentStep} style={{ marginBottom: '40px' }}>
            {steps.map((step, index) => (
              <Step key={index} title={step.title}  icon={currentStep > index ? <CheckCircleTwoTone  style={{ fontSize: '28px' }} twoToneColor="#66b443" /> : null}/>
            ))}
          </Steps>
          <div className="steps-content">{steps[currentStep].content}</div>
          <div className="steps-action">
            {currentStep < steps.length - 1 && (
              <Button type="primary" onClick={nextStep}>
                Next
              </Button>
            )}
            {/* {currentStep > 0 && (
                    <Button style={{ margin: '0 8px' }} onClick={this.prevStep}>
                        Previous
                    </Button>
                )} */}
            {(currentStep === steps.length - 1) && (
              <Button type="primary" style={{ margin: '0 8px' }} onClick={handleDone}>
                Apply Cleansing Rules
              </Button>
            )}
          </div>
          <Modal
            title="Data Preview"
            visible={isModalVisible}
            onCancel={() => handleCancel()}
            footer={[
              <Button key="close" type="primary" onClick={handleCancel}>
                Close
              </Button>,
            ]}
            width={1000}
          >
            <Table
              dataSource={previewData}
              columns={Object.keys(previewData[0] || {}).map(key => ({
                title: key,
                dataIndex: key,
                key: key,
              }))}
              rowKey="id"
              pagination={false}
              className="modal-table"

            />
          </Modal>
        </Spin>
      </div>
    );
};

export default DataCleansing;
