import React, { useEffect, useState, useRef } from 'react';
import { Table, Button, Modal, Tag,Select,Row,Col,Divider, Space, Typography, Progress, Input, Form, Tooltip, message } from 'antd';
import { EyeOutlined, FileOutlined, EditOutlined, SyncOutlined, ScheduleOutlined } from '@ant-design/icons';
import { getDataCleansingProgressByUserId, getProcessedData, getDataCleansingProgressById, downloadCsv,getRecordsByGroupId,checkGroupNameUnique,getGroupByProject,createGroup,addRecordsToGroupbatch,removeRecordsFromGroup,deleteGroup} from '../../Service/ApiServices';
import { SearchBar } from '../../components';
import './DataCleansingProgressList.css';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;
const { Option } = Select;
const twoColors = {
    '0%': '#108ee9',
    '100%': '#87d068',
};

const DataCleansingProgressList = () => {
    const [data, setData] = useState([]);
    const [cloneData, setCloneData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(null);
    const [previewData, setPreviewData] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [groupModalVisible, setGroupModalVisible] = useState(false);
    const [groups, setGroups] = useState([]);  
    const [newGroupName, setNewGroupName] = useState("");
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [currentProject, setCurrentProject] = useState(null);
    const [deleteGroupModalVisible, setDeleteGroupModalVisible] = useState(false);
    const [groupToDelete, setGroupToDelete] = useState(null);
    const navigate = useNavigate();
    const myRef = useRef(null);

    useEffect(() => {
        fetchData();
        fetchGroups();
    }, []);

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('userDetails'));
            const selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
            setUserId(user.id);
            const res = await getDataCleansingProgressByUserId(user.id,selectedProject.id);
            setData(res);
            setAllData(res);
            setCloneData(res);
            setLoading(false);
            setCurrentProject(selectedProject.id)
        } catch (error) {
            console.error('Error fetching data cleansing progress:', error);
            setLoading(false);
        }
    };
    const fetchGroups = async () => {
        try {
          const selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
          const groupsData = await getGroupByProject("data_cleansing_Progress",selectedProject.id);  
          setGroups(groupsData);
        } catch (error) {
          console.error('Error fetching folders:', error);
        }
      };
      const handleGroupChange = async (value) => {
        setLoading(true);
       
        if(value)
          {
            const records =await getRecordsByGroupId(value);
            const recordIds = records.map(record => record.recordId);
            const filteredData = allData.filter(record => recordIds.includes(record.id));
            setData(filteredData);
            setCloneData(filteredData);
            setSelectedGroup(value);
          }
        else
        {
          setData(allData);
            setCloneData(allData);
            setSelectedGroup(null);
        }
              setLoading(false);
      };
      const handleAddToGroup = () => {
        setGroupModalVisible(true);
      };
      const handleGroupSubmit = async () => {
        try {
            if (newGroupName.trim() !== "") {
        
                const isUnique = await checkGroupNameUnique(newGroupName);
          
                if (isUnique) {
                  message.error("A folder with this name already exists. Please choose a different name.");
                  return;
                }
              }

          let groupId = selectedGroup;
          if (!selectedGroup && newGroupName) {
            
            groupId = await createGroup(newGroupName,currentProject,"data_cleansing_Progress");
           
          }
    
          if (groupId) {
            const recordsToAdd = selectedRows.map(row => ({
              recordId: row.id
            }));
          
            await addRecordsToGroupbatch(groupId, recordsToAdd);
            message.success("Records added to folder successfully");
            setGroupModalVisible(false);
            setSelectedRows([]);
            setSelectedGroup(null);
            setNewGroupName("");
            fetchGroups();
          } else {
            message.error("Please select a folder or enter a new folder name.");
          }
        } catch (error) {
          console.error('Error adding records to folder:', error);
          message.error('Failed to add records to folder');
        }
      };
      const handleRemoveRecords = async () => {
        try {
          const recordsToremove = selectedRows.map(row => ({
            recordId: row.id
          }));
          await removeRecordsFromGroup(selectedGroup, recordsToremove);
          message.success("Records removed from folder successfully");
          setSelectedRows([]);
          fetchGroups();
          handleGroupChange(selectedGroup);
        } catch (error) {
          console.error('Error removing records from folder:', error);
          message.error('Failed to remove records from folder');
        }
      };
      const handleDeleteGroup = async () => {
        try {
          await deleteGroup(groupToDelete);
          message.success("Folder deleted successfully");
          setDeleteGroupModalVisible(false);
          fetchGroups();
        } catch (error) {
          console.error('Error deleting folder:', error);
          message.error('Failed to delete folder');
        }
      };
    
    const handlePreviewData = async (key) => {
        try {
            setLoading(true);
            const data = await getProcessedData(key);
            setPreviewData(data);
            setModalContent('data');
            setIsModalVisible(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching preview data:', error);
        }
    };
    const handleViewRules = (data) => {
        setLoading(true);
        setModalContent('rules');
        setPreviewData(JSON.parse(data));
        setIsModalVisible(true);
        setLoading(false);
      };
    const handleViewProgress = async (key) => {
        try {
            setLoading(true);
            const cleansingProgress = await getDataCleansingProgressById(key);
            setPreviewData(cleansingProgress.progress);
            setModalContent('progress');
            setIsModalVisible(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching cleansing progress:', error);
        }
    };


    const handleDownload = async (tableName) => {
        try {
            setLoading(true);
            await downloadCsv(tableName);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Failed to download CSV:', error);
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text,record) => (
              <Tag color={
                  record.status === 'FAILED' ? 'red' : 
                  record.status == 'COMPLETED' ? 'green' : 
                  'yellow'
                }>
                  {record.status === 'FAILED'  ? 'Failed' : 
                  record.status == 'COMPLETED' ? 'Completed' : 
                 'In Progress' }
                </Tag>
            ),
          },
      
        {
            title: 'Source Table ',
            dataIndex: 'sourceTableName',
            key: 'sourceTableName',
        },
        {
            title: 'Cleansed Table ',
            dataIndex: 'targetTableName',
            key: 'targetTableName',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (created_at) => new Date(created_at).toLocaleString(),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space >
                    {record.progress === -1 ? null : record.status == 'COMPLETED' ? (
                        <>
                            <Tooltip title="Preview Data">
                                <Button
                                    type="link"
                                    icon={<EyeOutlined style={{ fontSize: '20px' }} />}
                                    onClick={() => handlePreviewData(record.targetTableName)}
                                    className="custom-icon"
                                />
                            </Tooltip>
                            <Tooltip title="Export CSV">
                                <Button
                                    type="link"
                                    icon={<FileOutlined style={{ fontSize: '18px' }} />}
                                    onClick={() => handleDownload(record.targetTableName)}
                                    className="custom-icon"
                                />
                            </Tooltip>
                            <Tooltip title="View Rules">
                                <Button
                                    type="link"
                                    icon={<ScheduleOutlined style={{ fontSize: '18px' }} />}
                                    onClick={() => handleViewRules(record.rules)}
                                    className="custom-icon"
                                />
                            </Tooltip>
                        </>
                    ) : (
                        <Tooltip title="View Progress">
                            <Button
                                    type="link"
                                    icon={<SyncOutlined style={{ fontSize: '18px' }} />}
                                    onClick={() => handleViewProgress(record.id)}
                                    className="custom-icon"
                                />
                        </Tooltip>
                    )}
                </Space>
            ),
        },
    ];

    const handleAddClick = () => {
        navigate('/data-cleansing/new');
    };

    const handleSearch = (data) => {
        setData(data);
    };

    const renderModalContent = () => {
        if (modalContent === 'data') {
            return (
                <Table
                    dataSource={previewData}
                    columns={Object.keys(previewData[0] || {}).map((key) => ({
                        title: key,
                        dataIndex: key,
                        key: key,
                    }))}
                    rowKey="id"
                    pagination={false}
                    className="modal-table"
                />
            );
        }
        else if (modalContent === 'rules') {
            return (
              <pre className="modal-json">{JSON.stringify(previewData, null, 2)}</pre>
            );
          }
        else if (modalContent === 'progress') {
            return <Progress type="circle" percent={previewData} strokeColor={twoColors} />;
        }
    };
    const rowSelection = {
        selectedRowKeys: selectedRows.map(row => row.id),
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRows(selectedRows);
        },
      };
    return (
        <div>
            <div className="repo-header">
                <Title level={2}>Data Cleansing </Title>
                <Divider type="vertical" style={{borderLeft: '1px solid #b8b8b8', height:' 30px',marginTop:'15px',marginLeft:15}}/>
                <Select
          placeholder="Select a Folder"
          style={{ width: 200, marginLeft: 10, marginBottom:-16 }}
          onChange={handleGroupChange}
          allowClear
        >
          {/* <Option value={null}>All</Option> */}
          {groups.map(group => (
            <Option key={group.id} value={group.id}>
              {group.name}
            </Option>
          ))}
        </Select>
   
            </div>

                       <Row>
        <Col span={8}>   
        {selectedRows.length === 0 && (
            <Button type="primary" onClick={handleAddClick} >
                Add Cleansing Task
            </Button>
        )}
        {selectedRows.length > 0 && selectedGroup == null && (
            <Button
                type="primary"
                onClick={handleAddToGroup}
              
            >
                Add to Folder
            </Button>
        )}

        {selectedRows.length > 0 && selectedGroup !== null && (
            <Button
                type="primary"
                danger
                onClick={handleRemoveRecords}
               
            >
                Remove from Folder
            </Button>
        )}
       
        </Col>
        <Col span={8} offset={8}  >
        <div style={{ float:'right' }}>
        <SearchBar data={cloneData} handler={handleSearch} ref={myRef} />
        </div>
        </Col>
      </Row>
   
            <Table style={{marginTop:'10px'}} columns={columns} dataSource={data} loading={loading} rowKey="id" scroll={{ x: 'max-content' }}  rowSelection={rowSelection}  />
            <Modal
        title="Add to Folder"
        visible={groupModalVisible}
        cancelButtonProps={{ type: 'primary' }} 
        onCancel={() => setGroupModalVisible(false)}
        onOk={handleGroupSubmit}
        okText="Add"
        cancelText="Cancel"
      >
        <Form layout="vertical">
          <Form.Item label="Select Existing Folder">
            <Select
              placeholder="Select a folder"
              onChange={setSelectedGroup}
              value={selectedGroup}
              allowClear
            >
              {groups.map(group => (
                <Option key={group.id} value={group.id}>
                  {group.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Or Create New Folder">
            <Input
              placeholder="Enter new folder name"
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Delete Folder"
        visible={deleteGroupModalVisible}
        onCancel={() => setDeleteGroupModalVisible(false)}
        onOk={handleDeleteGroup}
        okText="Delete"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this folder?</p>
      </Modal>
            <Modal
                title={
                    modalContent === 'data' ? 'Preview Data' :
                        modalContent === 'rules' ? 'Rules' :
                            'Progress'
                }
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    // modalContent === 'columnMapping' && (
                    //     <Button type='primary' key="copy" onClick={handleCopy} className="copy-button">
                    //         Copy
                    //     </Button>
                    // ),
                    <Button key="close" type="primary" onClick={() => setIsModalVisible(false)}>
                        Close
                    </Button>,
                ]}
                width={1000}
                className="query-progress-modal"
            >
                {renderModalContent()}
            </Modal>
        </div>
    );
};

export default DataCleansingProgressList;
