import React, { Component } from 'react';
import { Checkbox, Input, Button, message, Row, Col, Select, Tree, Typography, Modal } from 'antd';
import { transferData } from './Service/ApiServices';

const { Option } = Select;
const { TreeNode } = Tree;
const { Title } = Typography;
const { TextArea } = Input;

const parseNestedJSON = (obj, path = '') => {
  let fields = [];
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      fields = fields.concat(parseNestedJSON(obj[key], `${path}${key}.`));
    } else {
      fields.push({ key: `${path}${key}`, title: key, type: typeof obj[key] });
    }
  }
  return fields;
};

const buildTreeData = (obj, path = '') => {
  let treeData = [];
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      treeData.push({
        title: key,
        key: `${path}${key}`,
        children: buildTreeData(obj[key], `${path}${key}.`),
      });
    } else {
      treeData.push({
        title: key,
        key: `${path}${key}`,
      });
    }
  }
  return treeData;
};

class ColumnMapping extends Component {
  state = {
    drillColumns: [],
    selectedColumns: [],
    columnMappings: {},
    formData: {},
    isModalVisible: false,
    jsonInput: '',
    tableName:'default_table'
  };

  componentDidMount() {
    const { data, formData,tableName } = this.props;
    if (data.length > 0) {
      const parsedData = this.parseData(data[0]);
      const drillColumns = buildTreeData(parsedData);
      this.setState({ drillColumns, formData ,tableName});
    }
  }

  parseData = (data) => {
    const parsedData = { ...data };
    for (const key in parsedData) {
      try {
        const value = parsedData[key];
        if (typeof value === 'string' && value.trim().startsWith('{') && value.trim().endsWith('}')) {
          parsedData[key] = JSON.parse(value);
        }
      } catch (e) {
        console.warn(`Failed to parse JSON for key: ${key}`, e);
      }
    }
    return parsedData;
  };

  handleCheckboxChange = (checkedKeys, { checked, node }) => {
    const { key: drillKey } = node;
    this.setState((prevState) => {
      const selectedColumns = checked
        ? [...prevState.selectedColumns, drillKey]
        : prevState.selectedColumns.filter((key) => key !== drillKey);

      const columnMappings = {
        ...prevState.columnMappings,
        [drillKey]: prevState.columnMappings[drillKey] || { name: drillKey, type: 'TEXT' },
      };

      return { selectedColumns, columnMappings };
    });
  };

  handleColumnNameChange = (drillKey, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      columnMappings: {
        ...prevState.columnMappings,
        [drillKey]: {
          ...prevState.columnMappings[drillKey],
          name: value,
        },
      },
    }));
  };

  handleDataTypeChange = (drillKey, value) => {
    this.setState((prevState) => ({
      columnMappings: {
        ...prevState.columnMappings,
        [drillKey]: {
          ...prevState.columnMappings[drillKey],
          type: value,
        },
      },
    }));
  };

  handleSubmit = async () => {
    const { columnMappings, selectedColumns, formData,tableName } = this.state;

    const mappings = selectedColumns.map((key) => ({
      name: columnMappings[key].name || key,
      type: columnMappings[key].type || 'TEXT',
      source: key,
    }));

    const config = {
      tableName: tableName,
      columns: mappings,
      queryTable: formData.table,
      query: formData.query,
      columnMapping: selectedColumns.reduce((acc, key) => {
        acc[key] = columnMappings[key].name || key;
        return acc;
      }, {}),
    };

    console.log(config);

    try {
      await transferData(config);

      message.success('Mappings submitted successfully');
      this.props.onMappingSubmit(config.tableName);
    } catch (error) {
      message.error('Error submitting mappings: ' + error.message);
      console.error('Error submitting mappings:', error);
    }
  };

  handleCopyJSON = () => {
    const { columnMappings, selectedColumns } = this.state;

    const mappings = selectedColumns.map((key) => ({
      name: columnMappings[key].name || key,
      type: columnMappings[key].type || 'TEXT',
      source: key,
    }));

    const json = JSON.stringify(mappings, null, 2);
    navigator.clipboard.writeText(json)
      .then(() => message.success('JSON copied to clipboard'))
      .catch((err) => message.error('Failed to copy JSON: ' + err.message));
  };

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleOk = () => {
    const { jsonInput } = this.state;
    try {
      const parsedMappings = JSON.parse(jsonInput);
      const columnMappings = {};
      const selectedColumns = parsedMappings.map((mapping) => {
        const { source, name, type } = mapping;
        columnMappings[source] = { name, type };
        return source;
      });

      this.setState({ columnMappings, selectedColumns, isModalVisible: false });
    } catch (err) {
      message.error('Invalid JSON input');
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleJsonInputChange = (e) => {
    this.setState({ jsonInput: e.target.value });
  };

  renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode {...item} />;
    });

  render() {
    const { drillColumns, selectedColumns, columnMappings, isModalVisible, jsonInput } = this.state;

    return (
      <div>
        <Title level={3}>Column Mapping</Title>
        <Row>
          <Col span={8}>
            <Tree checkable onCheck={this.handleCheckboxChange}>
              {this.renderTreeNodes(drillColumns)}
            </Tree>
          </Col>
          <Col span={16}>
            {selectedColumns.length > 0 && (
              <Row gutter={16} style={{ marginBottom: '10px' }}>
                <Col span={8}>
                  <label style={{ fontWeight: 'bold' }}>Column Path</label>
                </Col>
                <Col span={8}>
                  <label style={{ fontWeight: 'bold' }}>Destination Column Name</label>
                </Col>
                <Col span={8}>
                  <label style={{ fontWeight: 'bold' }}>Data Type</label>
                </Col>
              </Row>
            )}
            {selectedColumns.map((columnKey, index) => {
              const fullPath = columnKey.split('.');

              const disabledInput = (
                <Input
                  key={`disabledInput-${index}`}
                  disabled
                  value={columnKey}
                  style={{ width: '100%' }}
                />
              );

              const lastKey = fullPath[fullPath.length - 1];

              return (
                <Row key={`row-${index}`} gutter={16} style={{ marginBottom: '10px' }}>
                  <Col span={8}>{disabledInput}</Col>
                  <Col span={8}>
                    <Input
                      key={`columnNameInput-${index}`}
                      placeholder="Destination Column Name"
                      onChange={(e) => this.handleColumnNameChange(columnKey, e)}
                      value={columnMappings[columnKey]?.name || lastKey}
                    />
                  </Col>
                  <Col span={8}>
                    <Select
                      key={`dataTypeSelect-${index}`}
                      placeholder="Data Type"
                      style={{ width: '100%' }}
                      onChange={(value) => this.handleDataTypeChange(columnKey, value)}
                      value={columnMappings[columnKey]?.type || 'TEXT'}
                    >
                      <Option value="UUID">UUID</Option>
                      <Option value="TEXT">TEXT</Option>
                      <Option value="INT">INT</Option>
                      <Option value="BOOLEAN">BOOLEAN</Option>
                      <Option value="FLOAT">FLOAT</Option>
                    </Select>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
        <Button type="primary" onClick={this.handleSubmit} style={{ marginTop: '20px' }}>
          Submit Mapping
        </Button>
        <Button onClick={this.showModal} style={{ marginTop: '20px', marginLeft: '10px' }}>
          Add Mapping
        </Button>
        <Button onClick={this.handleCopyJSON} style={{ marginTop: '20px', marginLeft: '10px' }}>
          Copy Mapping
        </Button>
        <Modal
          title="Apply Mapping"
          visible={isModalVisible}
          cancelButtonProps={{ type: 'primary' }} 
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <TextArea
            rows={10}
            value={jsonInput}
            onChange={this.handleJsonInputChange}
            placeholder='[{ "source": "path.to.field", "name": "fieldName", "type": "TEXT" }, ...]'
          />
        </Modal>
      </div>
    );
  }
}

export default ColumnMapping;
