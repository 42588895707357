import React, { useEffect } from 'react';
import { Form, Input, Button, Select } from 'antd';
import { checkUserNameUnique } from '../../Service/authService';

const { Option } = Select;

const SignupForm = ({ onSubmit, form, editingUser }) => {
  // Populate form fields when editingUser is set
  useEffect(() => {
    if (editingUser) {
      form.setFieldsValue({
        username: editingUser.username,
        // Typically, you don't show the password field in edit mode for security reasons
        firstname: editingUser.firstname,
        lastname: editingUser.lastname,
        email: editingUser.email,
        roleIds: editingUser.roles[0]?.id, // Assuming roles is an array and we want the first role's ID
      });
    } else {
      form.resetFields(); // Reset the form when switching back to add user
    }
  }, [editingUser, form]);

  const onFinish = async (values) => {
    // Check if the password is required only for new users
    console.log(values)
    if (!editingUser) {
      values.roleIds = values.roleIds; // Ensure roleIds is an array for submission
    }
    onSubmit(values);
  };

  const validateUsername = async (rule, value) => {
    if (!value) {
      return Promise.reject("Please input your username!");
    }
    if(!editingUser)
      {
        const isUnique = await checkUserNameUnique(value);
        if (isUnique) {
          return Promise.reject("Username already exists!");
        }
        return Promise.resolve();
      }
    else
    {
      return Promise.resolve();
    }
   
  };

  return (
    <Form
      form={form}
      name="signup"
      onFinish={onFinish}
      layout="vertical"
    >
      <Form.Item
        name="username"
        label="Username"
        rules={[
          { required: true, message: 'Please input your username!' },
          { validator: validateUsername } // Use the async validator for uniqueness
        ]}
      >
        <Input disabled={!!editingUser} />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        style={{ display: !editingUser ? '' : 'none' }}
        rules={[{ required: !editingUser, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="firstname"
        label="First Name"
        rules={[{ required: true, message: 'Please input your first name!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="lastname"
        label="Last Name"
        rules={[{ required: true, message: 'Please input your last name!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Please input your email!' },
          { type: 'email', message: 'The input is not a valid email!' } // Additional validation for email format
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="roleIds"
        label="Roles"
        rules={[{ required: true, message: 'Please select your role!' }]}
      >
        <Select placeholder="Select roles">
          <Option value={1}>Admin</Option>
          <Option value={2}>User</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {editingUser ? 'Update User' : 'Sign Up'} {/* Change button text based on mode */}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SignupForm;
