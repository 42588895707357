import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Modal, Typography, Tag, Row, Space,Col, Tooltip, Form ,message,Popconfirm} from 'antd';
import { EditOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons';
import SignupForm from './SignUpForm';
import { signup, getAllUsers, updateUser ,deleteUser} from '../../Service/authService'; // Assuming updateUser is a service to update users
import { SearchBar } from "../../components";
import {
  addProject
} from  '../../Service/ApiServices';
const { Title } = Typography;

const Users = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [users, setUsers] = useState([]);
  const [cloneUsers, setCloneUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null); // Track user for editing
  const [form] = Form.useForm(); // Form instance for SignupForm
  const myRef = useRef(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const user = localStorage.getItem('userDetails');
      let userdata = JSON.parse(user);
      let role = userdata.roles[0].name;
      if (role === 'Admin') setIsAdmin(true);
      const res = await getAllUsers();
      setUsers(res.data);
      setCloneUsers(res.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleAddUser = async (user) => {
    try {
      const dataToSubmit = { ...user, roleIds: [user.roleIds] };
      if (editingUser) {
        // If editingUser is not null, we're updating an existing user
        await updateUser(editingUser.id, dataToSubmit); // Send updated user to the server
        message.success('User updated successfully');
      } else {
        // Adding a new user
        let id = await signup(dataToSubmit);
        let projectData = {
          "projectName": dataToSubmit.username+"_workspace",
          "description": "Default Workspace",
          "userId": id
        }
        await addProject(projectData);
        message.success('User updated successfully');
      }
      fetchUsers();
      setIsModalVisible(false);
      setEditingUser(null); // Reset the editing user after submission
      form.resetFields(); // Reset the form fields
    } catch (error) {
      message.success('Error adding/updating user');
      console.error('Error adding/updating user:', error);
      setIsModalVisible(false);
      setEditingUser(null); // Reset the editing user after submission
      form.resetFields(); 
    }
  };

  const handleEditClick = (user) => {
    setEditingUser(user); // Set the selected user for editing
    setIsModalVisible(true); // Open the modal

    // Set the form fields with the selected user data
    form.setFieldsValue({
      username: user.username,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      roleIds: user.roles[0]?.id, // Assuming roles is an array and you want the first role's ID
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingUser(null); // Reset the editing user when modal is closed
    form.resetFields(); // Reset the form fields when modal is closed
  };
  const handleDeleteClick = async (userId) => {
    try {
      await deleteUser(userId);
      message.success('User deleted successfully');
      fetchUsers();
    } catch (error) {
      message.error('Error deleting user');
    }
  };
  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id', defaultSortOrder: 'descend', sorter: (a, b) => a.id - b.id },
    { title: 'Username', dataIndex: 'username', key: 'username' },
    { title: 'First Name', dataIndex: 'firstname', key: 'firstname' },
    { title: 'Last Name', dataIndex: 'lastname', key: 'lastname' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Roles', dataIndex: 'roles', key: 'roles', render: roles => renderTag(roles[0].name) },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <Button type="link" disabled={!isAdmin} icon={<EditOutlined />} onClick={() => handleEditClick(record)} />
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this user?"
              onConfirm={() => { handleDeleteClick(record.id) }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" danger disabled={!isAdmin} icon={<DeleteOutlined />} />

            </Popconfirm>
            {/* <Button type="link" danger disabled={!isAdmin} icon={<DeleteOutlined />} onClick={() => handleDeleteClick(record.id)} /> */}
          </Tooltip>
        </Space>
      ),
    },
  ];

  const renderTag = (tag) => {
    return <Tag color="blue" icon={<UserOutlined />}>{tag}</Tag>;
  };
  const handleSearch=(data) =>{
    setUsers(data)
  }
  return (
    <div>
      <div className="repo-header">
        <Title level={2}>Users</Title>
       
      </div>
  
      <Row>
        <Col span={8} >
        <Button type="primary" disabled={!isAdmin} onClick={() => { setEditingUser(null); setIsModalVisible(true); }}>
          Add User
        </Button>
        
        </Col>
        <Col span={8} offset={8}>
        <div style={{ float:'right' }}>
        <SearchBar data={cloneUsers} handler={handleSearch} ref={myRef} />
        </div>
        </Col>

      </Row>
      <Table dataSource={users} columns={columns} rowKey="username" style={{ marginTop: '10px' }}/>
      <Modal
        title={editingUser ? "Edit User" : "Add User"} // Change title based on whether editing or adding
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <SignupForm onSubmit={handleAddUser} form={form} editingUser={editingUser} /> {/* Pass form instance and editingUser */}
      </Modal>
    </div>
  );
};

export default Users;
