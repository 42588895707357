import React, { Component } from 'react';
import { Checkbox, Input, Button, message, Row, Col, Select, Tree, Typography, Spin ,Modal} from 'antd';
import { transferData,transferFHIRData } from '../../Service/ApiServices';

const { Option } = Select;
const { TreeNode } = Tree;
const { Title } = Typography;
const { TextArea } = Input;
const parseNestedJSON = (obj, path = '') => {
  let fields = [];
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      fields = fields.concat(parseNestedJSON(obj[key], `${path}${key}.`));
    } else {
      fields.push({ key: `${path}${key}`, title: key, type: typeof obj[key] });
    }
  }
  return fields;
};

const buildTreeData = (obj, path = '') => {
  let treeData = [];
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      treeData.push({
        title: key,
        key: `${path}${key}`,
        children: buildTreeData(obj[key], `${path}${key}.`),
      });
    } else {
      treeData.push({
        title: key,
        key: `${path}${key}`,
      });
    }
  }
  return treeData;
};

class ColumnMapping extends Component {
  state = {
    drillColumns: [],
    selectedColumns: [],
    columnMappings: {},
    formData: {},
    isModalVisible: false,
    jsonInput: '',
    queryProgressId: '',
    tableName: 'default_table',
    loading: false,
    useCondition: false,
    conditionKey: '',
    conditionValue: '',
    arrayPath: ''
  };

  componentDidMount() {
    const { data, formData, tableName, queryProgressId } = this.props;
    if (data.length > 0) {
      const parsedData = this.parseData(data[0]);
      const drillColumns = buildTreeData(parsedData);
      this.setState({ drillColumns, formData, tableName, queryProgressId });
    }
  }

  parseData = (data) => {
    const parsedData = { ...data };
    for (const key in parsedData) {
      try {
        const value = parsedData[key];
        if (typeof value === 'string' && value.trim().startsWith('{') && value.trim().endsWith('}')) {
          parsedData[key] = JSON.parse(value);
        }
      } catch (e) {
        console.warn(`Failed to parse JSON for key: ${key}`, e);
      }
    }
    return parsedData;
  };

  handleCheckboxChange = (checkedKeys, { checked, node }) => {
    const { key: drillKey } = node;
    this.setState((prevState) => {
      const selectedColumns = checked
        ? [...prevState.selectedColumns, drillKey]
        : prevState.selectedColumns.filter((key) => key !== drillKey);

      const columnMappings = {
        ...prevState.columnMappings,
        [drillKey]: prevState.columnMappings[drillKey] || { name: drillKey, type: 'TEXT', parseArray: false, arrPath: '', arrKeyPath: '' },
      };

      return { selectedColumns, columnMappings };
    });
  };

  handleColumnNameChange = (drillKey, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      columnMappings: {
        ...prevState.columnMappings,
        [drillKey]: {
          ...prevState.columnMappings[drillKey],
          name: value,
        },
      },
    }));
  };

  handleDataTypeChange = (drillKey, value) => {
    this.setState((prevState) => ({
      columnMappings: {
        ...prevState.columnMappings,
        [drillKey]: {
          ...prevState.columnMappings[drillKey],
          type: value,
        },
      },
    }));
  };

  handleParseArrayChange = (drillKey, e) => {
    const { checked } = e.target;
    this.setState((prevState) => ({
      columnMappings: {
        ...prevState.columnMappings,
        [drillKey]: {
          ...prevState.columnMappings[drillKey],
          parseArray: checked,
        },
      },
    }));
  };

  handleArrayPathChange = (drillKey, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      columnMappings: {
        ...prevState.columnMappings,
        [drillKey]: {
          ...prevState.columnMappings[drillKey],
          arrPath: value,
        },
      },
    }));
  };

  handleArrayKeyPathChange = (drillKey, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      columnMappings: {
        ...prevState.columnMappings,
        [drillKey]: {
          ...prevState.columnMappings[drillKey],
          arrKeyPath: value,
        },
      },
    }));
  };

  handleSubmit = async () => {
    const {
      columnMappings,
      selectedColumns,
      formData,
      tableName,
      queryProgressId,
      useCondition,
      conditionKey,
      conditionValue,
      arrayPath,
    } = this.state;

    this.setState({ loading: true });

    const mappings = selectedColumns.map((key) => {
      const mapping = {
        name: columnMappings[key].name || key,
        type: columnMappings[key].type || 'TEXT',
        source: key,
        parseArray: columnMappings[key].parseArray || false,
      };

      // Only include arrPath and arrKeyPath if parseArray is true
      if (mapping.parseArray) {
        mapping.arrPath = columnMappings[key].arrPath || '';
        mapping.arrKeyPath = columnMappings[key].arrKeyPath || '';
      } else {
        mapping.arrPath = null;
        mapping.arrKeyPath = null;
      }

      return mapping;
    });

    const config = {
      tableName: tableName,
      columns: mappings,
      queryTable: formData.table,
      repository: formData.repository,
      query: formData.query,
      queryProgressId: queryProgressId,
      columnMapping: selectedColumns.reduce((acc, key) => {
        acc[key] = columnMappings[key].name || key;
        return acc;
      }, {}),
      useCondition,
      conditionKey,
      conditionValue,
      arrayPath,
    };

    try {
      if(formData.dataType == 'FHIR')
        {
          await transferFHIRData(config);
          this.setState({ loading: false });
          message.success('Mappings submitted successfully');
          this.props.onMappingSubmit(config.tableName);
        }
      else{
        await transferData(config);
        this.setState({ loading: false });
        message.success('Mappings submitted successfully');
        this.props.onMappingSubmit(config.tableName);
      }
     
    } catch (error) {
      message.error('Error submitting mappings: ' + error.message);
      console.error('Error submitting mappings:', error);
    }
  };

  handleCopyJSON = () => {
    const { columnMappings, selectedColumns } = this.state;

    const mappings = selectedColumns.map((key) => {
      const mapping = {
        name: columnMappings[key].name || key,
        type: columnMappings[key].type || 'TEXT',
        source: key,
        parseArray: columnMappings[key].parseArray || false,
      };

      // Only include arrPath and arrKeyPath if parseArray is true
      if (mapping.parseArray) {
        mapping.arrPath = columnMappings[key].arrPath || '';
        mapping.arrKeyPath = columnMappings[key].arrKeyPath || '';
      } else {
        mapping.arrPath = null;
        mapping.arrKeyPath = null;
      }

      return mapping;
    });

    const json = JSON.stringify(mappings, null, 2);
    if (navigator.clipboard && navigator.clipboard.writeText) {

      navigator.clipboard.writeText(json)
        .then(() => {
          message.info('Copied to clipboard!');
        })
        .catch((err) => {
          message.error('Failed to copy: ', err);
        });
    } else {
    
      const textArea = document.createElement('textarea');
      textArea.value = json;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        message.info('Copied to clipboard!');
      } catch (err) {
        message.error('Failed to copy: ', err);
      }
      document.body.removeChild(textArea);
    }
    // navigator.clipboard.writeText(json)
    //   .then(() => message.success('JSON copied to clipboard'))
    //   .catch((err) => message.error('Failed to copy JSON: ' + err.message));
  };

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleOk = () => {
    const { jsonInput } = this.state;
    try {
      const parsedMappings = JSON.parse(jsonInput);
      const columnMappings = {};
      const selectedColumns = parsedMappings.map((mapping) => {
        const { source, name, type, parseArray = false, arrPath = '', arrKeyPath = '' } = mapping;
  
        columnMappings[source] = {
          name,
          type,
          parseArray,
          arrPath: parseArray ? arrPath : null,
          arrKeyPath: parseArray ? arrKeyPath : null,
        };
  
        return source;
      });
  
      this.setState({ columnMappings, selectedColumns, isModalVisible: false });
    } catch (err) {
      message.error('Invalid JSON input');
    }
  };
  

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleJsonInputChange = (e) => {
    this.setState({ jsonInput: e.target.value });
  };
  renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode {...item} />;
    });

  render() {
    const { drillColumns, selectedColumns, columnMappings,isModalVisible, jsonInput , loading } = this.state;

    return (
      <div>
        <Spin size="large" spinning={loading}>
          <Title level={3}>Column Mapping</Title>
          <Row>
            <Col span={8}>
              <Tree checkable onCheck={this.handleCheckboxChange}>
                {this.renderTreeNodes(drillColumns)}
              </Tree>
            </Col>
            <Col span={16}>
              {selectedColumns.map((columnKey, index) => (
                <Row key={`row-${index}`} gutter={16} style={{ marginBottom: '10px' }}>
                   {/* Headings only for the first row */}
                {index === 0 && (
                  <>
                    <Col span={4}>
                      <Typography.Text strong>Source Column</Typography.Text>
                    </Col>
                    <Col span={4}>
                      <Typography.Text strong>Target Column</Typography.Text>
                    </Col>
                    <Col span={4}>
                      <Typography.Text strong>Data Type</Typography.Text>
                    </Col>
                    <Col span={4}>
                      <Typography.Text strong>Parse Array</Typography.Text>
                    </Col>

                    <Col span={4}>
                      <Typography.Text strong>Array Path</Typography.Text>
                    </Col>
                    <Col span={4}>
                      <Typography.Text strong>Array Key Path</Typography.Text>
                    </Col>
                   
                  </>
                )}
                  <Col span={4}>
                    <Input disabled value={columnKey} style={{ width: '100%' }} />
                  </Col>
                  <Col span={4}>
                    <Input
                      key={`columnNameInput-${index}`}
                      placeholder="Destination Column Name"
                      onChange={(e) => this.handleColumnNameChange(columnKey, e)}
                      value={columnMappings[columnKey]?.name || columnKey}
                    />
                  </Col>
                  <Col span={4}>
                    <Select
                     key={`dataTypeSelect-${index}`}
                      placeholder="Data Type"
                      style={{ width: '100%' }}
                      onChange={(value) => this.handleDataTypeChange(columnKey, value)}
                      value={columnMappings[columnKey]?.type || 'TEXT'}
                    >
                    <Option value="TEXT">TEXT</Option>
                    <Option value="VARCHAR">VARCHAR</Option>
                    <Option value="INTEGER">INT</Option>
                    <Option value="BOOLEAN">BOOLEAN</Option>
                    <Option value="FLOAT">FLOAT</Option>
                    <Option value="DOUBLE PRECISION">DOUBLE PRECISION</Option>
                    <Option value="DATE">DATE</Option>
                    <Option value="TIMESTAMP">TIMESTAMP</Option>
                    </Select>
                  </Col>
                  <Col span={4}>
                    <Checkbox
                      onChange={(e) => this.handleParseArrayChange(columnKey, e)}
                      checked={columnMappings[columnKey]?.parseArray || false}
                    >
                      Parse Array
                    </Checkbox>
                  </Col>
                  {columnMappings[columnKey]?.parseArray && (
                    <>
                      <Col span={4}>
                        <Input
                          placeholder="Array Path"
                          onChange={(e) => this.handleArrayPathChange(columnKey, e)}
                          value={columnMappings[columnKey]?.arrPath || ''}
                        />
                      </Col>
                      <Col span={4}>
                        <Input
                          placeholder="Array Key Path"
                          onChange={(e) => this.handleArrayKeyPathChange(columnKey, e)}
                          value={columnMappings[columnKey]?.arrKeyPath || ''}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              ))}
            </Col>
          </Row>
          <Button type="primary" onClick={this.handleSubmit} style={{ marginTop: '20px' }}>
            Submit Mapping
          </Button>
          <Button onClick={this.showModal} style={{ marginTop: '20px', marginLeft: '10px' }}>
          Add Mapping
        </Button>
        <Button onClick={this.handleCopyJSON} style={{ marginTop: '20px', marginLeft: '10px' }}>
          Copy Mapping
        </Button>
        <Modal
          title="Apply Mapping"
          visible={isModalVisible}
          cancelButtonProps={{ type: 'primary' }} 
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <TextArea
            rows={10}
            value={jsonInput}
            onChange={this.handleJsonInputChange}
            placeholder='[{ "source": "path.to.field", "name": "fieldName", "type": "TEXT" }, ...]'
          />
        </Modal>
        </Spin>
      </div>
    );
  }
}

export default ColumnMapping;
