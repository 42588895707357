import React, { useEffect, useState ,useRef} from "react";
import { Typography,Table, Button, Modal, Form,Row,Col, Input, message, Spin,Tooltip,Popconfirm } from "antd";
import {
  getProjectsByUserId,
  addProject,
  checkProjectNameUnique,
  deleteProject,
} from  '../../Service/ApiServices';
import { SearchBar } from '../../components';
import { EditOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons';
const { Title } = Typography;

const ProjectsList = () => {
  const [projects, setProjects] = useState([]);
  const [cloneProjects, setCloneProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const myRef = useRef(null);
  const fetchProjects = async () => {
    setLoading(true);
    try {
        const user = localStorage.getItem('userDetails');
        let userdata = JSON.parse(user);
        setUserId(userdata.id);
        setUserName(userdata.username)
      const data = await getProjectsByUserId(userdata.id);
      setProjects(data);
      setCloneProjects(data);
    } catch (error) {
      message.error("Failed to fetch Workspace.");
    }
    setLoading(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleSearch = (data) => {
    setProjects(data);
};

  const handleOk = async () => {
    try {
        setLoading(true);
      const values = await form.validateFields();
      
      const isUnique = await checkProjectNameUnique(values.projectName);
      if (isUnique) {
        message.error("Workspace name already exists. Please choose a different name.");
        setLoading(false);
        return;
      }

      const response = await addProject({ ...values, userId });
      message.success("Workspace added successfully!");
      setIsModalOpen(false);
      form.resetFields();
      setLoading(false);
      fetchProjects();
    } catch (error) {
        setLoading(false);
      message.error("Failed to add Workspace.");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id
    },
    {
      title: "Workspace Name",
      dataIndex: "projectName",
      key: "projectName",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => new Date(createdAt).toLocaleDateString(),
    },
    {
        title: "Actions",
        key: "actions",
        render: (_, record) => {
        const isRestrictedProject = record.projectName === userName + "_workspace";
        return (
          <>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure you want to delete this workspace? All reports and generated data will be removed."
              onConfirm={async () => {
                if (isRestrictedProject) {
                  message.error("This workspace cannot be deleted as it is the default workspace.");
                } else {
                  try {
                    await deleteProject(record.id);
                    message.success("Workspace deleted successfully!");
                    fetchProjects();
                  } catch (error) {
                    message.error("Failed to delete workspace.");
                  }
                }
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="link"
                danger
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div className="repo-header">
                <Title level={2}>Workspaces</Title>
              
        </div>
      <Row>
        <Col span={8}>
        <Button type="primary" onClick={showModal} >
                Add Workspace
                </Button>
        </Col>
        <Col span={8} offset={8}  >
        <div style={{ float:'right' }}>
        <SearchBar data={cloneProjects} handler={handleSearch} ref={myRef} />
        </div>
        </Col>
      </Row>
       
        <Table
         style={{marginTop:'10px'}} 
          columns={columns}
          dataSource={projects}
          loading={loading}
          rowKey="id"
          pagination={{ pageSize: 5 }}
        />
      <Modal
        title="Add New Workspace"
        open={isModalOpen}
        cancelButtonProps={{ type: 'primary' }} 
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Add"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Workspace Name"
            name="projectName"
            rules={[{ required: true, message: "Please input the Workspace name!" }]}
          >
            <Input placeholder="Enter Workspace name" />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Please input the description!" }]}
          >
            <Input.TextArea rows={4} placeholder="Enter Workspace description" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ProjectsList;
