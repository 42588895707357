import React, { Component } from 'react';
import { getEmbedToken,createDataset } from './Service/ApiServices';
import * as powerbi from 'powerbi-client';

class PowerBIReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      embedUrl: '',
      reportId: '',
      embedToken: '',
      reportName: '',
      tokenExpiry: '',
      username: props.username 
    };
    this.powerbiService = new powerbi.service.Service(
      powerbi.factories.hpmFactory,
      powerbi.factories.wpmpFactory,
      powerbi.factories.routerFactory
    );
  }

  componentDidMount() {
    this.fetchEmbedConfig();
  }


  fetchEmbedConfig() {
    const {  username } = this.state;
    const parameterValue = `${username}`;
    let reportId="3c3a566f-757d-4c4a-9378-3ea71fbe77a4";
    createDataset(username)
    .then(datasetResponse => {
      const reportId = datasetResponse;
      this.setState({ reportId });
      return getEmbedToken(username,reportId); 
    })
    .then(data => {
      this.setState({
        embedUrl: data.embedReports[0].embedUrl,
        embedToken: data.embedToken,
        tokenExpiry: data.tokenExpiry
      }, () => {
        this.embedPowerBIReport();
      });
    })
    .catch(error => {
      console.error('Error creating dataset or fetching embed config:', error);
    });
    // getEmbedToken(parameterValue,reportId)
    //   .then(data => {
    //     this.setState({
    //       embedUrl: data.embedReports[0].embedUrl,
    //       reportId: data.embedReports[0].reportId,
    //       reportName: data.embedReports[0].reportName,
    //       embedToken: data.embedToken,
    //       tokenExpiry: data.tokenExpiry
    //     }, () => {
    //       this.embedPowerBIReport();
    //     });
    //   })
    //   .catch(error => {
    //     console.error('Error fetching embed config:', error);
    //   });
  }

  embedPowerBIReport() {
    const embedConfig = {
      type: 'report',
      tokenType: powerbi.models.TokenType.Embed,
      accessToken: this.state.embedToken,
      embedUrl: this.state.embedUrl,
      id: this.state.reportId,
	  permissions: powerbi.models.Permissions.All, 
      viewMode: powerbi.models.ViewMode.Edit,
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: true
      },
    };

    const powerbiElement = document.getElementById('powerbi-report-container');
    
    this.powerbiService.reset(powerbiElement);

    this.powerbiService.embed(powerbiElement, embedConfig);
  }

  render() {
    return (
      <div>
        <h1>Power BI Report: {this.state.reportName}</h1>
        <div id="powerbi-report-container" style={{ height: '600px' }}></div>
      </div>
    );
  }
}

export default PowerBIReport;
