import React from 'react';
import { useParams } from 'react-router-dom';
import TableMapping from './TableMapping'; 

const TableMappingWrapper = () => {
  const { id } = useParams(); 
  return <TableMapping id={id} />; 
};

export default TableMappingWrapper;
