import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Pages/Login/Login';
import QueryData from './Pages/QueryModule/QueryData';
import ReportList from './Pages/QueryModule/ReportList';
import DataLoader from './DataLoader';
import ProtectedRoute from './Context/ProtectedRoute';
import LayoutComp from './Pages/LayoutComp/LayoutComp';
import Users from './Pages/Users/Users';
import Stepper from './Pages/QueryModule/Stepper';
import Repositories from './Pages/Repositories/Repositories';
import QueryProgressList from './Pages/QueryModule/QueryProgressList';
import ReportProgressList from './Pages/QueryModule/ReportProgressList';
import TableMapping from './Pages/QueryModule/TableMapping';
import TableMappingWrapper from './Pages/QueryModule/TableMappingWrapper';
import ViewReport from './Pages/QueryModule/ViewReport';
import VariableManager from './Pages/VariableManager/VariableManager';
import DataCleansing from './Pages/DataCleansing/DataCleansing';
import DataCleansingProgressList from './Pages/DataCleansing/DataCleansingProgressList';
import ProjectsList from './Pages/Projects/ProjectsList';

const routes = [
  { path: '/login', element: <Login />, isLayout: false },
  { path: '/query', element: <ProtectedRoute element={<QueryProgressList />} />, isLayout: true },
  { path: '/query/new', element: <ProtectedRoute element={<Stepper />} />, isLayout: true },
  { path: '/query/resume/:id', element: <ProtectedRoute element={<Stepper />} />, isLayout: true },
  { path: '/table-mapping', element: <ProtectedRoute element={<TableMapping />} />, isLayout: true },
  { path: '/viewReport', element: <ProtectedRoute element={<ViewReport />} />, isLayout: true },
  { path: '/report', element: <ProtectedRoute element={<ReportProgressList />} />, isLayout: true },
  { path: '/report/new', element: <ProtectedRoute element={<TableMapping />} />, isLayout: true },
  { path: '/report/resume/:id', element: <ProtectedRoute element={<TableMappingWrapper />} />, isLayout: true },
  { path: '/data-cleansing', element: <ProtectedRoute element={<DataCleansingProgressList />} />, isLayout: true },
  { path: '/data-cleansing/new', element: <ProtectedRoute element={<DataCleansing />} />, isLayout: true },
  { path: '/workspace', element: <ProtectedRoute element={<ProjectsList />} />, isLayout: true },
  { path: '/users', element: <ProtectedRoute element={<Users />} />, isLayout: true },
  { path: '/repositories', element: <ProtectedRoute element={<Repositories />} />, isLayout: true },
  { path: '/variableManager', element: <ProtectedRoute element={<VariableManager />} />, isLayout: true },
];

function App() {
  useEffect(() => {
    if (window.location.pathname === '/') {
      window.location.replace('/data-analytics-platform');
    }
  }, []);

  return (
    <Router basename="/data-analytics-platform">
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />

        {routes.map(({ path, element, isLayout }, index) => (
          <Route
            exact
            key={index}
            path={path}
            element={isLayout ? <LayoutComp>{element}</LayoutComp> : element}
          />
        ))}
      </Routes>
    </Router>
  );
}

export default App;
