import React, { Component } from 'react';
import { Select, Form, Input, Button, Table, message, Steps } from 'antd';
import { queryRawData, getProcessedData } from '../../Service/ApiServices';
import ColumnMapping from './ColumnMapping';
import PowerBIReport from './PowerBIReport';
import QueryBuilder from './QueryBuilder';

const { Option } = Select;
const { Step } = Steps;

class QueryData extends Component {
  state = {
    databases: ['bfjm', 'student'],
    selectedDatabase: null,
    loading: false,
    data: [],
    currentStep: 0,
    formData: {},
    columns: [],
    isRawData: false,
    tableName: 'bmi_test_data',
  };

  generateColumns = (data) => {
    if (data.length === 0) return [];
    return Object.keys(data[0]).map((key) => ({
      title: key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()),
      dataIndex: key,
      key,
      ellipsis: true,
    }));
  };

  handleDatabaseChange = (value) => {
    this.setState({ selectedDatabase: value });
  };

  handleNext = () => {
    this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }));
  };

  handlePrev = () => {
    this.setState((prevState) => ({ currentStep: prevState.currentStep - 1 }));
  };

  handleFormSubmit = async (values) => {
    this.setState({ loading: true });
    try {
      // Fetch raw data
      const result = await queryRawData(values);

      // Prepare the data to send
      const formattedData = JSON.stringify(result);
      const formattedColumns = JSON.stringify(this.generateColumns(result));
      const formattedFormData = JSON.stringify(values);

      const payload = {
        userId: 1, // You may need to fetch this dynamically
        reportName: values.reportName,
        currentStep: this.state.currentStep,
        formData: formattedFormData,
        data: formattedData,
        columns: formattedColumns,
        isRawData: true,
        tableName: values.tableName,
      };

      // Send data to the backend
      await this.saveReportProgress(payload);
      
      message.success('Data fetched and saved successfully');
      this.handleNext();
    } catch (error) {
      message.error('Error fetching data: ' + error.message);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleMappingSubmit = async (key) => {
    try {
      const data = await getProcessedData(key);
      const columns = this.generateColumns(data);
      const formattedColumns = JSON.stringify(columns);
      const formattedData = JSON.stringify(data);

      this.setState({ data, columns, isRawData: false });

      const payload = {
        userId: 1, // You may need to fetch this dynamically
        reportName: this.state.tableName,
        currentStep: this.state.currentStep,
        formData: JSON.stringify(this.state.formData),
        data: formattedData,
        columns: formattedColumns,
        isRawData: false,
        tableName: this.state.tableName,
      };

      await this.saveReportProgress(payload);

      message.success('Data processed and saved successfully');
      this.handleNext();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  handleFilterSubmit = (key) => {
    this.setState({ tableName: key + '_filtered' });
    this.handleNext();
  };

  saveReportProgress = async (payload) => {
    try {
      await fetch('http://localhost:8086/api/reports/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
    } catch (error) {
      console.error('Error saving report progress:', error);
    }
  };

  render() {
    const { databases, selectedDatabase, data, loading, currentStep, formData, columns, isRawData, tableName } = this.state;

    const steps = [
      {
        title: 'Select Database',
        content: (
          <Form layout="vertical" onFinish={this.handleFormSubmit}>
            <Form.Item label="Select Repository" name="database" rules={[{ required: true, message: 'Please select a database' }]}>
              <Select placeholder="Select a database" onChange={this.handleDatabaseChange}>
                {databases.map((db) => (
                  <Option key={db} value={db}>
                    {db}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {selectedDatabase && (
              <Form.Item label="Query" name="query" rules={[{ required: true, message: 'Please enter query' }]}>
                <Input placeholder="Enter query" />
              </Form.Item>
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        ),
      },
      {
        title: 'View Data',
        content: (
          <>
            <Table dataSource={data} columns={columns} />
            {isRawData ? (
              <Button type="primary" onClick={this.handleNext}>
                Proceed to Mapping
              </Button>
            ) : (
              <Button type="primary" onClick={this.handleNext}>
                Filter Data
              </Button>
            )}
          </>
        ),
      },
      {
        title: 'Column Mapping',
        content: <ColumnMapping data={data} formData={formData} tableName={tableName} onMappingSubmit={this.handleMappingSubmit} />,
      },
      {
        title: 'Filter Data',
        content: <QueryBuilder data={data} tableName={tableName} onFilterSubmit={this.handleFilterSubmit} />,
      },
      {
        title: 'View Report',
        content: <PowerBIReport username={tableName} />,
      },
    ];

    return (
      <div>
        <Steps current={currentStep}>
          {steps.map((step, index) => (
            <Step key={index} title={step.title} />
          ))}
        </Steps>
        <div className="steps-content" style={{marginTop:'30px'}}>{steps[currentStep].content}</div>
        <div className="steps-action">
          {currentStep > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={this.handlePrev}>
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 && currentStep !== 1 && (
            <Button type="primary" onClick={this.handleNext}>
              Next
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default QueryData;
