import axios from 'axios';
import authHeader from './auth-header';

let SERVICE_URL= process.env.REACT_APP_AUTH_SERVER_URL;
const dataapi = axios.create({
  baseURL: SERVICE_URL
});
axios.defaults.baseURL = process.env.REACT_APP_AUTH_SERVER_URL; 

export const login = async (data) => {
  try {
    const response = await dataapi.post('/api/auth/login', data);
    localStorage.setItem('token', response.data.token);
    let user = JSON.stringify(response.data.userDetails);
    localStorage.setItem('userDetails', user);
    return response.data;
  } catch (error) {
    throw new Error('Login failed. Please check your credentials and try again.');
  }
};
export const signup = async (data) => {
    try {  
      const response = await dataapi.post('/api/auth/signup', data, {
        headers: authHeader()
      });
      return response.data;
    } catch (error) {
      throw new Error('Signup failed. Please try again.');
    }
};
  

  export const getAllUsers = async () => {
    try {
      const response = await dataapi.get('/api/users/getAllUsers', {
        headers: authHeader()
      });
      return response;
    } catch (error) {
      throw new Error('Failed to fetch users. Please try again.');
    }
  };

  export const updateUser = async (id, data) => {
    try {
      const response = await dataapi.put(`/api/users/edit/${id}`, data, {
        headers: authHeader()
      });
      return response.data;
    } catch (error) {
      throw new Error('User updation Failed');
    }
  };
  
  export const deleteUser = async (id) => {
    try {
      const response = await dataapi.delete(`/api/users/delete/${id}`, {
        headers: authHeader()
      });
      return response.data;
    } catch (error) {
      throw new Error('User updation Failed');
    }
  };
  export const checkUserNameUnique = async (name) => {
    try {
      const response = await dataapi.get(`/api/users/check-if-exists?name=${name}`, {
        headers: authHeader()
      });
      return response.data;
    } catch (error) {
      console.error('Error checking name uniqueness:', error);
      return false;
    }
  };