import React, { useState, useEffect ,useRef} from 'react';
import { Table, Button, Modal, Form,Typography, Input,Col, Select, message,Checkbox ,Descriptions,Row, Space,Tooltip,Popconfirm} from 'antd';
import { getRepositoriesByUserId, addRepository, updateRepository, deleteRepository ,checkRepoNameUnique} from '../../Service/ApiServices';
import {  EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { SearchBar } from "../../components";
import './Repositories.css';

const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;

const Repositories = () => {
  const [repositories, setRepositories] = useState([]);
  const [cloneRepositories, setCloneRepositories] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDBRepo, setIsDBRepo] = useState(false);
  const [isFHIRRepo, setIsFHIRRepo] = useState(false);
  const [isJWTToken, setIsJWTToken] = useState(false);
  const [isKeycloakToken, setIsKeycloakToken] = useState(false);
  const [isAuthEnabled, setIsAuthEnabled] = useState(false);
  const [form] = Form.useForm();
  const [editingRepo, setEditingRepo] = useState(null);
  const [userId, setUserId] = useState(null);
  const [tokenType, setTokenType] = useState("jwtToken");
  const myRef = useRef(null);

  useEffect(() => {
    fetchRepositories();
  }, []);

  const fetchRepositories = async () => {
    try {
      const user = localStorage.getItem('userDetails');
      let userdata = JSON.parse(user);
      setUserId(userdata.id);
      const data = await getRepositoriesByUserId(userdata.id);
      setRepositories(data);
      setCloneRepositories(data)
    } catch (error) {
      message.error('Failed to fetch repositories');
    }
  };
  const getConnectionUrl = (dbType, host, port, databaseName) => {
    switch (dbType) {
      case 'mysql':
        return `jdbc:mysql://${host}:${port}/${databaseName}`;
      case 'postgresql':
        return `jdbc:postgresql://${host}:${port}/${databaseName}`;
      case 'mongodb':
        return `mongodb://${host}:${port}/${databaseName}`;
      default:
        throw new Error('Unsupported database type');
    }
  };
  
  const handleAddClick = () => {
    setEditingRepo(null);
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleEditClick = (repo) => {
    
    setEditingRepo(repo);
    if(repo.repoType == 'Database')
      {
        const { dbType, host, port, databaseName } = extractUrlComponents(repo.url);
        setIsDBRepo(true)
        setIsFHIRRepo(false)
  
        form.setFieldsValue({
          name: repo.name,
          host: host,
          port: port,
          repoType:repo.repoType,
          database: databaseName,
          username: repo.username,
          password: repo.password,
          dbType: repo.dbType,
        });
      
        setIsModalVisible(true);
      }
      else if(repo.repoType == 'FHIRServer')
      {
        setIsDBRepo(false)
        setIsFHIRRepo(true)
        if(repo.tokenType == 'jwtToken')
          {
            setIsJWTToken(true)
            setIsKeycloakToken(false)
            form.setFieldsValue({
              name: repo.name,
              repoType:repo.repoType,
              fhirUrl:repo.fhirUrl,
              tokenType: repo.tokenType,
              jwtToken: repo.jwtToken
            });
            setIsModalVisible(true);
          }
          else
          {
            setIsJWTToken(false)
            setIsKeycloakToken(true)
            form.setFieldsValue({
              name: repo.name,
              repoType:repo.repoType,
              url: repo.url,
              fhirUrl:repo.fhirUrl,
              username: repo.username,
              password: repo.password,
              tokenType: repo.tokenType
            });
            setIsModalVisible(true);
          }
      }
  
  };

  const handleDeleteClick = async (id) => {
    try {
      await deleteRepository(id);
      message.success('Repository deleted successfully');
      fetchRepositories();
    } catch (error) {
      message.error('Failed to delete repository');
    }
  };
  const handleTokenTypeChange = async (val)=> {


    if (val == "keycloakToken") {
      setIsJWTToken(false);
      setTokenType(val);
      setIsKeycloakToken(true);
    }
    else if (val == "jwtToken") {
      setIsJWTToken(true);
      setTokenType(val);
      setIsKeycloakToken(false);
    }
   
  }
  const handleRepoTypeChange = async (val)=> {

    if (val == "FHIRServer") {
       setIsDBRepo(false);
       setIsFHIRRepo(true);
    }
    else if(val == "Database"){
      setIsDBRepo(true);
      setIsFHIRRepo(false);
    }
   
  }
  const handleEnableAuth = async (val)=> {

   setIsAuthEnabled(val)
   
  }
  const handleFormSubmit = async (values) => {
    console.log("inSubmit")
    try {
      let dataToSubmit = {};
      if(isDBRepo)
        {
          const connectionUrl = getConnectionUrl(values.dbType, values.host, values.port, values.database);
         dataToSubmit = { ...values, userId: userId ,url:connectionUrl};
          console.log(dataToSubmit)
         
        }
      else if(isFHIRRepo)
        {
          dataToSubmit = { ...values, userId: userId };
          console.log(dataToSubmit)
        }
        if (editingRepo) {
          await updateRepository(editingRepo.id, dataToSubmit);
          message.success('Repository updated successfully');
        } else {
   
          await addRepository(dataToSubmit);
          message.success('Repository added successfully');
        }
      handleCancel();
      fetchRepositories();
    } catch (error) {
      message.error('Failed to save repository');
    }
  };
  const extractUrlComponents = (url) => {
    const urlPattern = /jdbc:(\w+):\/\/([^:]+):(\d+)\/(\w+)/;
    const match = url.match(urlPattern);
    
    if (!match) {
      throw new Error('Invalid URL format');
    }
  
    const [, dbType, host, port, databaseName] = match;
    return { dbType, host, port, databaseName };
  };
  const handleCancel =()=>{
    setIsModalVisible(false)
    setIsDBRepo(false)
    setIsFHIRRepo(false)
    setIsJWTToken(false)
    setIsKeycloakToken(false)
  }
  const handleSearch=(data) =>{
    setRepositories(data)
  }
  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id',defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Type', dataIndex: 'repoType', key: 'repoType' },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space>
          <Tooltip title="Edit">
              <Button type="link" icon={<EditOutlined />} onClick={() => handleEditClick(record)} />
            </Tooltip>
            <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this Repository? Any running queries using this repo will be disrupted."
              onConfirm={() => { handleDeleteClick(record.id) }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" danger icon={<DeleteOutlined />} />

            </Popconfirm>
              {/* <Button type="link" danger icon={<DeleteOutlined />} onClick={() => handleDeleteClick(record.id)} /> */}
            </Tooltip>
        </Space>
      ),
    },
  ];
  const expandedRowRender = (record) => {
    if (record.repoType === 'Database') {
      return (
        <Descriptions title="DB Info">
        <Descriptions.Item label="UserName">{record.username}</Descriptions.Item>
        <Descriptions.Item label="Host">
        {(() => {
          const { host } = extractUrlComponents(record.url);
          return host;
        })()}
        </Descriptions.Item>
        <Descriptions.Item label="Port">
        {(() => {
          const { port } = extractUrlComponents(record.url);
          return port;
        })()}
        </Descriptions.Item>
        <Descriptions.Item label="Database">
         {(() => {
          const { databaseName } = extractUrlComponents(record.url);
          return databaseName;
        })()}
        </Descriptions.Item>
        <Descriptions.Item label="DB Type">
        {record.dbType}
        </Descriptions.Item>
      </Descriptions>
      );
    } else if (record.repoType === 'FHIRServer') {
      return (
        <Descriptions title="FHIR Server Info">
        <Descriptions.Item label="FHIR URL">{record.fhirUrl}</Descriptions.Item>
        <Descriptions.Item label="Token Type">{record.tokenType}</Descriptions.Item>
        {record.tokenType == "jwtToken" ?
        <>
         <Descriptions.Item label="JWT Token">{record.jwtToken}</Descriptions.Item>
         </>:
         <>
          <Descriptions.Item label="Keycloak URL">{record.url}</Descriptions.Item>
          <Descriptions.Item label="UserName">{record.username}</Descriptions.Item>
         </>

        }
       
        </Descriptions>
      
      );
    }
    return null;
  };
  
  return (
      <div>
          <div className="repo-header">
              <Title level={2}>Repositories</Title>
              
          </div>
      <Row>
        <Col span={8} >
        <Button type="primary" onClick={handleAddClick} >
            Add Repository
          </Button>
        
        </Col>
        <Col span={8} offset={8}>
        <div style={{ float:'right' }}>
        <SearchBar
            data={cloneRepositories}
            handler={handleSearch}
            ref={myRef}
          ></SearchBar>
          </div>
        </Col>

      </Row>
          <Table columns={columns} 
            expandable={{
              expandedRowRender,
              rowExpandable: record => record.repoType !== null,  
            }}
            style={{ marginTop: '10px' }}
          dataSource={repositories} rowKey="id" />

          <Modal
              title={editingRepo ? 'Edit Repository' : 'Add Repository'}
              visible={isModalVisible}
              onCancel={handleCancel}
              footer={null}
          >
              <Form form={form} onFinish={handleFormSubmit} layout="vertical">

                  <Form.Item
                      name="name"
                      label="Name"
                      rules={[
                        { required: true, message: 'Please enter the repository name' }
                        ,
                        ({
                            
                            getFieldValue
                        }) => ({
                           
                            validator(rule, value) {
                              
                                    return new Promise((resolve, reject) => {
                                       
                                      checkRepoNameUnique(value).then((res)=>{
                                            if(res)
                                            {
                                                reject("Repo name already exist!");
                                            }
                                            else
                                            {
                                                resolve();
                                            }
                                           })
                                    
                                     });
                           
                            }

                        })]}
                  >
                      <Input />
                  </Form.Item>
                  <Form.Item
                      name="repoType"
                      label="Repo Type"
                      rules={[{ required: true, message: 'Please select the repo type' }]}
                  >
                      <Select placeholder="Select a repo type"  onChange={handleRepoTypeChange}>
                          <Option value="Database">Database</Option>
                          <Option value="FHIRServer">FHIR Server</Option>
                      </Select>
                  </Form.Item>
                  <Form.Item
                      name="host"
                      label="Host"
                      style={{ display: isDBRepo ? '' : 'none' }}
                      rules={[{ required: isDBRepo, message: 'Please enter the host' }]}
                  >
                      <Input />
                  </Form.Item>
                  <Form.Item
                      name="port"
                      label="Port"
                      style={{ display: isDBRepo ? '' : 'none' }}
                      rules={[{ required: isDBRepo, message: 'Please enter the port' }]}
                  >
                      <Input type="number" />
                  </Form.Item>
                  <Form.Item
                      name="database"
                      label="Database"
                      style={{ display: isDBRepo ? '' : 'none' }}
                      rules={[{ required: isDBRepo, message: 'Please enter the Database' }]}
                  >
                      <Input />
                  </Form.Item>
                  {/* <Form.Item
                      name="username"
                      label="Username"
                      style={{ display: isDBRepo ? '' : 'none' }}
                      rules={[{ required: isDBRepo, message: 'Please enter the username' }]}
                  >
                      <Input />
                  </Form.Item>
                  <Form.Item
                      name="password"
                      label="Password"
                      style={{ display: isDBRepo ? '' : 'none' }}
                      rules={[{ required: isDBRepo, message: 'Please enter the password' }]}
                  >
                      <Input.Password />
                  </Form.Item> */}
                  <Form.Item
                      name="dbType"
                      label="Database Type"
                      style={{ display: isDBRepo ? '' : 'none' }}
                      rules={[{ required: isDBRepo, message: 'Please select the database type' }]}
                  >
                      <Select placeholder="Select a database type">
                          <Option value="postgresql">PostgreSQL</Option>
                      </Select>
                  </Form.Item>
                  {/* <Form.Item
                    name="enableAuth"
                    valuePropName="checked"
                    style={{ display: isFHIRRepo ? '' : 'none' }}
                  >
                    <Checkbox onChange={handleEnableAuth}>Enable Authentication</Checkbox>
                  </Form.Item> */}
                  <Form.Item
                                name="fhirUrl"
                                label="Server URL"
                                style={{ display: isFHIRRepo ? '' : 'none' }}
                                rules={[
                                    {
                                        required: isFHIRRepo,
                                        message: 'Please Input URL!',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Enter Server URL"
                                />
                            </Form.Item>
                            
                  <Form.Item
                    name="tokenType"
                    label="Token Type"
                    style={{ display: isFHIRRepo  ? '' : 'none' }}
                    rules={[{ required: isFHIRRepo, message: 'Please select the token type' }]}
                  >
                    <Select placeholder="Select token type"  onChange={handleTokenTypeChange}>

                      <Option key="jwtToken" value="jwtToken">JWT Token</Option>
                      <Option key="keycloakToken" value="keycloakToken">Keycloak Token</Option>

                    </Select>

                  </Form.Item>
                  <Form.Item
                    name="url"
                    label="Keyclock server Url"
                    style={{ display: (isFHIRRepo && isKeycloakToken) ?   '' :'none'}}
                    rules={[
                      {
                        required:isKeycloakToken,
                        message: 'Please Input URL!',
                      },
                    ]}
                  >
                    <Input 
                      placeholder="Enter Keyclock Server URL"
                    />
                  </Form.Item>
                  <Form.Item
                    name="username"
                    label="Username"
                    style={{ display: isDBRepo || (isFHIRRepo && isKeycloakToken) ?   '' :'none'}}
                    rules={[
                      {
                        required: isDBRepo || isKeycloakToken,
                        message: 'Please Input Username',
                      },
                    ]}
                  >
                    <Input 
                      placeholder="Enter Username"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    style={{ display: isDBRepo || (isFHIRRepo && isKeycloakToken) ?   '' :'none'}}
                    rules={[
                      {
                        required: isDBRepo || isKeycloakToken,
                        message: 'Please input Password!',
                      },
                    ]}
                  >
                    <Input.Password  />
                  </Form.Item>
                  <Form.Item
                    name="jwtToken"
                    label="JWT Token"
                    style={{ display: (isFHIRRepo && isJWTToken) ?   '' :'none'}}
                    rules={[
                      {
                        required: isJWTToken,
                        message: 'Please input JWT token!',
                      },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                  <Form.Item>
                      <Button type="primary" htmlType="submit" style={{float:'right'}}> 
                          {editingRepo ? 'Update' : 'Add'}
                      </Button>
                  </Form.Item>
              </Form>
          </Modal>
      </div>
  );
};

export default Repositories;
