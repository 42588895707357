import React, { useEffect, useState,useRef } from 'react';
import { Table, Button, Modal, Tag, Space, Typography,Progress,Input,Form,Card ,Divider,Row,Col,Tooltip,Select, message,Descriptions} from 'antd';
import axios from 'axios';
import { getQueryProgressByUserId, getProcessedData ,getQueryProgressById,downloadCsv,renameColumns,getRecordsByGroupId,getGroupByProject,createGroup,checkGroupNameUnique,addRecordsToGroupbatch,removeRecordsFromGroup,deleteGroup, getJobsByProject,executeJobAction} from '../../Service/ApiServices';
import { EyeOutlined, FileOutlined, EditOutlined, SyncOutlined, PlayCircleOutlined,ScheduleOutlined,LoadingOutlined,PauseOutlined,CheckOutlined,CloseCircleOutlined } from '@ant-design/icons';
import { SearchBar } from "../../components";
import './QueryProgressList.css'; 
import { useNavigate } from 'react-router-dom';

const { Option } = Select;
const { Title } = Typography;
const twoColors = {
    '0%': '#108ee9',
    '100%': '#87d068',
  };

const QueryProgressList = () => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [cloneData, setCloneData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [previewData, setPreviewData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [columnMapping, setColumnMapping] = useState([]);
  const [renameModalVisible, setRenameModalVisible] = useState(false);
  const [renameForm] = Form.useForm();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [groupModalVisible, setGroupModalVisible] = useState(false);
  const [groups, setGroups] = useState([]);  
  const [newGroupName, setNewGroupName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);
  const [deleteGroupModalVisible, setDeleteGroupModalVisible] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const navigate = useNavigate();
  const myRef = useRef(null);
  useEffect(() => {

    fetchData();
    fetchGroups();
      }, []);
  const fetchData = async (groupId = null) => {
    try {
      const user = JSON.parse(localStorage.getItem('userDetails'));
      const selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
      const res = await getQueryProgressByUserId(user.id, selectedProject.id);
      const jobs = await getJobsByProject( selectedProject.projectName);
     
      const jobMap = new Map();
      jobs.forEach(jobObj => {
          jobMap.set(jobObj.jobName, jobObj);
      });
      
      const combinedData = res.map(titleObj => {
          const title = titleObj.title;
          const matchingJob = Array.from(jobMap.values()).find(jobObj => jobObj.jobName.includes(title));
          return matchingJob ? { ...titleObj, ...matchingJob } : titleObj;
      });
 
      setData(combinedData);
      setCloneData(combinedData);
      setAllData(combinedData);
      setCurrentProject(selectedProject.id)
      setLoading(false);
    } catch (error) {
      console.error('Error fetching query progress data:', error);
      setLoading(false);
    }
  };
  
  const fetchGroups = async () => {
    // Fetch the list of existing groups from the backend API
    try {
      const selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
      const groupsData = await getGroupByProject("query_Progress",selectedProject.id);  // Assuming an API function to get groups
      setGroups(groupsData);
    } catch (error) {
      console.error('Error fetching groups:', error);
    }
  };
  const handleGroupChange = async (value) => {
    setLoading(true);
    if(value)
      {
        const records =await getRecordsByGroupId(value);
        const recordIds = records.map(record => record.recordId);
        const filteredData = allData.filter(record => recordIds.includes(record.id));
        setData(filteredData);
        setCloneData(filteredData);
        setSelectedGroup(value);
      }
    else
    {
      setData(allData);
        setCloneData(allData);
        setSelectedGroup(null);
    }
          setLoading(false);
  };
  const handleAddToGroup = () => {
    setGroupModalVisible(true);
  };
  const handleGroupSubmit = async () => {
    try {
      if (newGroupName.trim() !== "") {
        
        const isUnique = await checkGroupNameUnique(newGroupName);
  
        if (isUnique) {
          message.error("A folder with this name already exists. Please choose a different name.");
          return;
        }
      }

      let groupId = selectedGroup;
      if (!selectedGroup && newGroupName) {
        // Create a new group if one doesn't exist
        groupId = await createGroup(newGroupName,currentProject,"query_Progress");
       
      }

      if (groupId) {
        const recordsToAdd = selectedRows.map(row => ({
          recordId: row.id
        }));
        // Add selected rows to the group
        await addRecordsToGroupbatch(groupId, recordsToAdd);
        message.success("Records added to Folder successfully");
        setGroupModalVisible(false);
        setSelectedRows([]);
        setSelectedGroup(null);
        setNewGroupName("");
        fetchGroups(); // Refresh groups after adding records
      } else {
        message.error("Please select a folder or enter a new folder name.");
      }
    } catch (error) {
      console.error('Error adding records to Folder:', error);
      message.error('Failed to add records to Folder');
    }
  };
  const handleRemoveRecords = async () => {
    try {
      const recordsToremove = selectedRows.map(row => ({
        recordId: row.id
      }));
      await removeRecordsFromGroup(selectedGroup, recordsToremove);
      message.success("Records removed from folder successfully");
      setSelectedRows([]);
      fetchGroups();
      handleGroupChange(selectedGroup);
    } catch (error) {
      console.error('Error removing records from folder:', error);
      message.error('Failed to remove records from folder');
    }
  };
  const handleDeleteGroup = async () => {
    try {
      await deleteGroup(groupToDelete);
      message.success("Folder deleted successfully");
      setDeleteGroupModalVisible(false);
      fetchGroups(); // Refresh groups after deletion
    } catch (error) {
      console.error('Error deleting folder:', error);
      message.error('Failed to delete folder');
    }
  };

  const handlePreviewData = async (key) => {
    try {
      setLoading(true);
      const data = await getProcessedData(key);
      setPreviewData(data);
      setModalContent('data');
      setIsModalVisible(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching preview data:', error);
    }
  };
  const handleViewProgress = async (key) => {
    try {
        setLoading(true);
      const queryProgress = await getQueryProgressById(key);
      setPreviewData(queryProgress.progress);
      setModalContent('progress');
      setIsModalVisible(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching preview data:', error);
    }
  };
  const handleViewColumnMapping = (columnMapping) => {
    setLoading(true);
    setModalContent('columnMapping');
    setPreviewData(JSON.parse(columnMapping));
    setColumnMapping(JSON.parse(columnMapping));
    setIsModalVisible(true);
    setLoading(false);
  };
  const handleRenameColumns = (record) => {

    setSelectedRow(record);
    setColumnMapping(JSON.parse(record.columnMapping));
    setRenameModalVisible(true);
  };
  const handleRenameSubmit = async (values) => {
  setLoading(true);
    const updatedColumns = columnMapping.reduce((acc, column) => {
      const newName = values[column.name];
      if (newName && newName !== column.name) {
        acc.push({ oldName: column.name, newName });
      }
      return acc;
    }, []);
    const updatedColumnMapping = columnMapping.map(column => {
        const newName = values[column.name];
        if (newName && newName !== column.name) {
          return { ...column, name: newName };
        }
        return column;
      });
    const requestPayload = {
      queryProgressId:selectedRow.id,
      tableName: selectedRow.tableName,
      columns: updatedColumns,
      columnMapping:updatedColumnMapping
    };
  
    console.log('Request Payload:', requestPayload);
    try {
   
       await renameColumns(requestPayload);
       setLoading(false);
       fetchData();
      setRenameModalVisible(false);
    } catch (error) {
      setLoading(false);
      console.error('Error renaming column:', error);
    }
  };
  const handleResume = (recordId) => {
    navigate(`/query/resume/${recordId}`); 
  };
  const handleJobAction =  async (jobName,groupName, action) => {
   const res = await executeJobAction(jobName,groupName, action)
    message.info(res)
    fetchData();
  }
  const handleDownload = async (tableName) => {
    try {
      setLoading(true);
      await downloadCsv(tableName);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Failed to download CSV:', error);
          }
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
      fixed: 'left',
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        fixed: 'left',
      },
    {
      title: 'Query',
      dataIndex: 'query',
      key: 'query',
    },
    {
      title: 'Data Type',
      dataIndex: 'dataType',
      key: 'dataType',
    },
    {
      title: 'Repository Name',
      dataIndex: 'repositoryName',
      key: 'repositoryName',
    },
    {
      title: 'Query Type',
      dataIndex: 'queryType',
      key: 'queryType',
      render: (queryType) => {
        const style = {
          liveQuery: { color: '#f71e29' },
          oneTimeQuery: { color: '#1890ff' },
        };
    
        return (
          <span style={queryType === 'liveQuery' ? style.liveQuery : style.oneTimeQuery}>
            {queryType === 'liveQuery' ? 'Live Query' : 'One-Time Query'}
          </span>
        );
      },
    },
    
    {
      title: 'Status',
      dataIndex: 'currentStep',
      key: 'currentStep',
      fixed: 'right',
      render: (text,record) => (
        <Tag color={
            record.progress === -1 ? 'red' : 
            record.currentStep === 4 ? 'green' : 
            record.currentStep === 3 ? 'blue' : 
            'warning'
          }>
            {record.progress === -1 ? 'Failed' : 
            record.currentStep === 4 ? 'Complete' : 
            record.currentStep === 3 ? 'In Progress' : 
            'Pending'}
          </Tag>
      ),
    },
    {
      title: 'Table Name',
      dataIndex: 'tableName',
      key: 'tableName',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: created_at => new Date(created_at).toLocaleString(),
    },
    {
      title: 'Column Mapping',
      dataIndex: 'columnMapping',
      key: 'columnMapping',
      fixed: 'right',
      render: (text,record) => (
        <Button
        type="primary"
        shape="round"
        icon={<EyeOutlined />}
        disabled={record.currentStep < 4}
        onClick={() => handleViewColumnMapping(record.columnMapping)}
        style={{
          backgroundColor: record.currentStep >= 4 ? '#1890ff' : '#d9d9d9', 
          borderColor: record.currentStep >= 4 ? '#1890ff' : '#d9d9d9',
          color: record.currentStep >= 4 ? '#fff' : '#a6a6a6',
          boxShadow: record.currentStep >= 4 ? '0 2px 8px rgba(0, 0, 0, 0.15)' : 'none',
        }}
      >
        <Tooltip title="View Column Mapping">
          View
        </Tooltip>
      </Button>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (text, record) => (
          <Space >
              {record.progress === -1 ? null : record.currentStep === 4 ? (
                  <>
                   <Tooltip title="Preview Data">
                    <Button
                      type="link"
                      icon={<EyeOutlined style={{fontSize:'20px'}}/>}
                      onClick={() => handlePreviewData(record.tableName)}
                      className="custom-icon"
                    />
                  </Tooltip>
                  <Tooltip title="Export CSV">
                    <Button
                      type="link"
                      icon={<FileOutlined style={{fontSize:'18px'}}/>}
                      onClick={() => handleDownload(record.tableName)}
                      className="custom-icon"
                    />
                  </Tooltip>
                  <Tooltip title="Rename Columns">
                    <Button
                      type="link"
                      icon={<EditOutlined style={{fontSize:'18px'}}/>}
                      onClick={() => handleRenameColumns(record)}
                      className="custom-icon"
                    />
                  </Tooltip>
                  </>
              ) : record.currentStep === 3 ? (
                  <Tooltip title="View Progress">
                      <Button
                      type="link"
                      icon={<SyncOutlined style={{fontSize:'18px'}}/>}
                      onClick={() => handleViewProgress(record.id)}
                      className="custom-icon"
                    />
                  </Tooltip>
              ) : (
                  <Tooltip title="Resume">
                     <Button
                      type="link"
                      icon={<PlayCircleOutlined style={{fontSize:'18px'}}/>}
                      onClick={() => handleResume(record.id)}
                      className="custom-icon"
                    />
                  </Tooltip>
              )}
          </Space>
      ),
    },
  ];

  const expandableContent = (record) => (
    <>
    
      <h3 style={{ marginBottom: '12px', fontWeight: 'bold' }}>Job Details</h3>
  
      <Descriptions
        bordered
        column={5}
        size="small"
        style={{
          flex: 1,
          marginRight: '16px'
        }}
        labelStyle={{ fontWeight: 'bold', color: '#595959', width: '120px' }}
        contentStyle={{ color: '#000' }}
      >
        <Descriptions.Item label="Job Name">{record.jobName}</Descriptions.Item>
        <Descriptions.Item label="Status">{renderTag(record.jobStatus)}</Descriptions.Item>
        <Descriptions.Item label="Next Fire Time">
          {record.nextFireTime ? new Date(record.nextFireTime).toLocaleString() : '-'}
        </Descriptions.Item>
        <Descriptions.Item label="Last Fire Time">
          {record.lastFiredTime ? new Date(record.lastFiredTime).toLocaleString() : '-'}
        </Descriptions.Item>
        <Descriptions.Item label="Actions">
          <Space>
            <Button
              type="primary"
              shape="round"
              onClick={() => handleJobAction(record.jobName, record.groupName, 'pause')}
              disabled={record.jobStatus !== 'SCHEDULED'}
              size="small"
            >
              Pause
            </Button>
            <Button
              type="primary"
              shape="round"
              onClick={() => handleJobAction(record.jobName, record.groupName, 'resume')}
              disabled={record.jobStatus !== 'PAUSED'}
              size="small"
            >
              Resume
            </Button>
          </Space>
        </Descriptions.Item>
      </Descriptions>
    </>
  );
  
  
  const renderTag = (tag) => {

    if (tag == 'SCHEDULED')
        return <Tag icon={<ScheduleOutlined />} color="orange">
            {tag}
        </Tag>;
    else if (tag == 'RUNNING')
        return <Tag color="warning" icon={<LoadingOutlined />}>{tag}</Tag>;
    else if (tag == 'PAUSED')
        return <Tag color="processing" icon={<PauseOutlined />}>{tag}</Tag>;
    else if (tag == 'COMPLETE')
        return <Tag color="success" icon={<CheckOutlined />}>{tag}</Tag>;
    else
        return <Tag color="error" icon={<CloseCircleOutlined />}>{tag}</Tag>;


}
  
  const handleAddClick = () => {
    navigate('/query/new'); 
  };
  const handleSearch = (data) => {
    setData(data)
  }
  const renderModalContent = () => {
    if (modalContent === 'data') {
      return (
        <Table
          dataSource={previewData}
          columns={Object.keys(previewData[0] || {}).map(key => ({
            title: key,
            dataIndex: key,
            key: key,
          }))}
          rowKey="id"
          pagination={false}
          className="modal-table"
        />
      );
    } else if (modalContent === 'columnMapping') {
      return (
        <pre className="modal-json">{JSON.stringify(previewData, null, 2)}</pre>
      );
    }
    else if (modalContent === 'progress') {
        return (
            <Progress type="circle" percent={previewData} strokeColor={twoColors} />
        );
      }
  };
  const handleCopy = () => {
    const jsonString = JSON.stringify(previewData, null, 2);
  
    if (navigator.clipboard && navigator.clipboard.writeText) {

      navigator.clipboard.writeText(jsonString)
        .then(() => {
          message.info('Copied to clipboard!');
        })
        .catch((err) => {
          message.error('Failed to copy: ', err);
        });
    } else {
    
      const textArea = document.createElement('textarea');
      textArea.value = jsonString;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        message.info('Copied to clipboard!');
      } catch (err) {
        message.error('Failed to copy: ', err);
      }
      document.body.removeChild(textArea);
    }
  };
  
  const rowSelection = {
    selectedRowKeys: selectedRows.map(row => row.id),
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };
  return (
    <div>
      <div className="repo-header">
        <Title level={2}>Data Setup</Title>
        <Divider type="vertical" style={{borderLeft: '1px solid #b8b8b8', height:' 30px',marginTop:'15px',marginLeft:15}}/>
        <Select
          placeholder="Select a Folder"
          style={{ width: 200, marginLeft: 10, marginBottom:-16 }}
          onChange={handleGroupChange}
          allowClear
        >
          {/* <Option value={null}>All</Option> */}
          {groups.map(group => (
            <Option key={group.id} value={group.id}>
              {group.name}
            </Option>
          ))}
        </Select>
        
              </div>

      <Row>
        <Col span={8}>   
        {selectedRows.length === 0 && (
            <Button type="primary" onClick={handleAddClick} >
              Add Data
            </Button>
          )}

          {selectedRows.length > 0 && selectedGroup == null && (
            <Button
              type="primary"
              onClick={handleAddToGroup}
            >
              Add to Folder
            </Button>
          )}

          {selectedRows.length > 0 && selectedGroup !== null && (
            <Button
              type="primary"
              danger
              onClick={handleRemoveRecords}
            >
              Remove from Folder
            </Button>
          )}
        </Col>
        <Col span={8} offset={8}  >
        <div style={{ float:'right' }}>
        <SearchBar
          data={cloneData}
          handler={handleSearch}
          ref={myRef}
        ></SearchBar>
        </div>
        </Col>
      </Row>
   

      <Table columns={columns} dataSource={data} loading={loading} scroll={{ x: 'max-content' }}  style={{ marginTop: '10px' }} rowKey="id"  
       expandable={{
        expandedRowRender: record => expandableContent(record),
        rowExpandable: record => record.queryType === 'liveQuery', 
      }}
       rowSelection={rowSelection} />
      <Modal
        title="Add to Folder"
        visible={groupModalVisible}
        cancelButtonProps={{ type: 'primary' }} 
        onCancel={() => setGroupModalVisible(false)}
        onOk={handleGroupSubmit}
        okText="Add"
        cancelText="Cancel"
      >
        <Form layout="vertical">
          <Form.Item label="Select Existing Folder">
            <Select
              placeholder="Select a Folder"
              onChange={setSelectedGroup}
              value={selectedGroup}
              allowClear
            >
              {groups.map(group => (
                <Option key={group.id} value={group.id}>
                  {group.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Or Create New Folder">
            <Input
              placeholder="Enter new Folder name"
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Delete Folder"
        visible={deleteGroupModalVisible}
        onCancel={() => setDeleteGroupModalVisible(false)}
        onOk={handleDeleteGroup}
        okText="Delete"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this Folder?</p>
      </Modal>
      <Modal
        title={
        modalContent === 'data' ? 'Preview Data' :
        modalContent === 'columnMapping' ? 'Column Mapping' : 
        'Progress'
      }
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          modalContent === 'columnMapping' && (
            <Button type="primary" key="copy" onClick={handleCopy} className="copy-button">
              Copy
            </Button>
          ),
          <Button key="close" type="primary" onClick={() => setIsModalVisible(false)}>
            Close
          </Button>,
        ]}
        width={1000} 
        className="query-progress-modal"
      >
        {renderModalContent()}
      </Modal>
      <Modal
        title="Rename Columns"
        visible={renameModalVisible}
        onCancel={() => setRenameModalVisible(false)}
        footer={null}
      >
        <Form form={renameForm} onFinish={handleRenameSubmit}>
          {columnMapping.map(column => (
                <Form.Item
              key={column.name}
              name={column.name}
              label={`Rename ${column.name}`}
            >
              <Input defaultValue={column.name} />
          </Form.Item>
          ))}
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{float:'right'}}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default QueryProgressList;
